import { Divider, ListItemButton, ListItemText } from "@mui/material";
import React from "react";
import { shortAddress } from "../../utils/shortAddress";


export const Item = ({ name, walletAddr, isActive, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{
          px: 3,
          py: 1,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemText
          primary={name}
          secondary={shortAddress(walletAddr)}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#241F21",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#4B4749",
            textAlign: "left",
            maxWidth: "24ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          sx={{
            display: "block",

            width: "100%",
          }}
        />
        <ListItemText
          primary={isActive ? "Active" : ""}
          secondary={""}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "15px",
            lineHeight: "18px",
            textAlign: "right",
            color: "#4B4749",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "right",
            color: "#4B4749",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
      </ListItemButton>
      <Divider />
    </>
  );
};
