import { Close } from "@mui/icons-material";
import { DialogContent, DialogTitle, Divider, IconButton } from "@mui/material";
import React, { useState } from "react";
import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import { shortAddress } from "../../utils/shortAddress";
import { ModalHead } from "../AppBar";
import DetailDialog, { WEModal } from "./DetailDialog";
import Info from "./Info";
import InfoAddr from "./InfoAddr";
import TradeShareModal from "./TradeShareModal";
import Transition from "./Transition";

const ShareholderDetailTradeModal = ({
  open,
  onClose,
  firstName,
  lastName,
  numOfShares,
  walletAddr,
  update,
}) => {
  const { account } = useAutoConnect()
  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);

  const [tradeOpen, setTradeOpen] = useState(false);
  const [tradeType] = useState("BUY");

  const tradeClose = () => {
    setTradeOpen(false);
  };

  const order = (amount, price) => {
    setTradeOpen(false);
    const updated = {
      firstName: firstName,
      lastName: lastName,
      walletAddr: walletAddr,
      numOfShare: numOfShares - amount,
    };
    const orderTx = {
      id: Date.now(),
      orderType: tradeType,
      amount: amount,
      price: price,
      date: new Date(),
      maker: account || "",
      taker: walletAddr,
    };
    updateEnterprises((prev) => {
      prev.tempEnterprise.orders = [...prev.tempEnterprise.orders, orderTx];
      return prev;
    });
    update(updated);
  };

  // const openTradeDlg = (type) => () => {
  //   setTradeType(type);
  //   setTradeOpen(true);
  // };

  return (
    <>
      <WEModal
        open={open}
        onClose={() => onClose()}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle sx={{ p: "0px !important" }} id="alert-dialog-title">
          <ModalHead
            title={"Shareholder"}
            customClose={() => onClose()}
            theme='light'
            color='#FFDB0A'
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: "0 16px !important" }}>
          <InfoAddr label="Wallet" value={shortAddress(walletAddr)} />
          <Info label="Number of Shares" labelColor="grayLabel" multiline value={(numOfShares + 0).toString()} />
          <Info label="Name" labelColor="grayLabel" multiline value={`${firstName} ${lastName}`} />
        </DialogContent>
      </WEModal>
      <TradeShareModal
        open={tradeOpen}
        type={tradeType}
        onClose={tradeClose}
        order={order}
      />
    </>
  );
};

export default ShareholderDetailTradeModal;
