import { Avatar, Box, Container, Typography } from "@mui/material";
import React from "react";

const Index = ({ Icon, title, content, sx, color }) => {
  return (
    <Box
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Avatar sx={{ border: "2px solid", borderColor: color }}>
        <Icon fontSize="medium" htmlColor={color} />
      </Avatar>
      <Container
        sx={{
          flex: 1,
          minWidth: "54px",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          verticalAlign: "middle",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" sx={{ fontSize: { md: "18px"}, lineHeight: { md: "21.29px"}, pt: "15px"}}>{title}</Typography>
        <Typography variant="body1" sx={{ pt: "15px", fontSize: { md: "15px"}, lineHeight: { md: "18.63px"} }}>
          {content}
        </Typography>
      </Container>
    </Box>
  );
};

export default Index;
