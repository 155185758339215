import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { AppBar } from "../components/AppBar";
import Header from "../components/Header";
import { Chip } from "../components/Input";
import { useAutoConnect } from "../utils/AutoConnect";

const dashboardTheme = {
  primary: "#FFDB0A",
  secondary: "#3D61B0",
  black: "#241F21",
  black1: "#4B4749",
  success: "#42B03D",
  danger: "#FF6142",
};

const TotalChart = () => {
  const navigation = useNavigate();
  const { account } = useAutoConnect()

  const [period, setPeriod] = useState("Day");

  const changePeriod = (p) => () => {
    if (
      p === "Day" ||
      p === "Week" ||
      p === "Month" ||
      p === "Year" ||
      p === "All"
    ) {
      setPeriod(p);
    }
  };

  // data
  const data = [
    {
      time: new Date(2022, 1, 1, 0, 0),
      price: 1.14,
      amt: 2400,
    },
    {
      time: new Date(2022, 1, 1, 1, 12),
      price: 1.2,
      amt: 2210,
    },
    {
      time: new Date(2022, 1, 1, 1, 17),
      price: 1.35,
      amt: 2290,
    },
    {
      time: new Date(2022, 1, 1, 2, 12),
      price: 1.3,
      amt: 2000,
    },
    {
      time: new Date(2022, 1, 1, 2, 25),
      price: 1.14,
      amt: 2181,
    },
    {
      time: new Date(2022, 1, 1, 3, 47),
      price: 1.32,
      amt: 2500,
    },
    {
      time: new Date(2022, 1, 1, 4, 12),
      price: 1.25,
      amt: 2100,
    },
  ];

  const back = () => {
    navigation(`/user/${account}`);
  };

  return (
    <>
      <Header pageTitle="Chart" />
      <AppBar
        title={"ABC Shares"}
        back={back}
        bgColor={dashboardTheme.primary}
      />
      <Box component="main" mt={16}>
        <Box
          sx={{
            mt: "37px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {["Day", "Week", "Month", "Year", "All"].map((val, idx) => {
            return (
              <Chip
                key={idx}
                label={val}
                sx={(() => {
                  if (val === period)
                    return {
                      background: dashboardTheme.secondary,
                      color: "#fff",
                    };
                  else return {};
                })()}
                onClick={() => {
                  changePeriod(val);
                }}
              />
            );
          })}
        </Box>
        <Box
          height={"550px"}
          sx={{
            px: 2,
          }}
        >
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart
              data={data}
              margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={dashboardTheme.secondary}
                    stopOpacity={0.13}
                  />
                  <stop
                    offset="95%"
                    stopColor={dashboardTheme.secondary}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="time"
                tickFormatter={(time) => time.toLocaleTimeString()}
                style={{
                  color: dashboardTheme.black1,
                  fontWeight: "500",
                }}
              />
              <YAxis
                id="price"
                orientation="right"
                tickFormatter={(price) => (price === 0 ? "" : `$${price}`)}
                style={{
                  paddingRight: "20px",
                  backgroundColor: "#FF00FF",
                  color: dashboardTheme.black1,
                  fontWeight: "500",
                }}
              />
              <CartesianGrid strokeDasharray="3 3" />
              {/* <Tooltip /> */}
              <Area
                type="monotone"
                dataKey="price"
                stroke={dashboardTheme.secondary}
                strokeWidth={2}
                fillOpacity={1}
                fill="url(#colorPrice) "
              />
              <ReferenceLine
                y={data[data.length - 1].price}
                strokeDasharray="3 3"
              >
                <Label
                  value={`$${data[data.length - 1].price}`}
                  position="right"
                  fill="#000"
                  content={({ value, viewBox }) => {
                    const { x, y, width } = viewBox;
                    return (
                      <foreignObject
                        {...viewBox}
                        width={100}
                        height={50}
                        x={(width || 0) + (x || 0)}
                        y={(y || 0) - 13}
                      >
                        <p
                          style={{
                            display: "inline-block",
                            color: "#fff",
                            backgroundColor: dashboardTheme.secondary,
                            margin: "0px",
                            padding: "3px 5px",
                            width: "50%",
                            borderRadius: "15px",
                          }}
                        >
                          {value}
                        </p>
                      </foreignObject>
                    );
                  }}
                >
                  {`$${data[data.length - 1].price}`}
                </Label>
              </ReferenceLine>
            </AreaChart>
          </ResponsiveContainer>
        </Box>
        <Box
          sx={{
            my: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Info title="High Price(wc)" value="$1.35" bg="success" />
          <Info title="Low Price(wc)" value="$1.14" bg="danger" />
        </Box>
      </Box>
    </>
  );
};

const Info = ({ title, value, bg }) => {
  return (
    <Typography
      variant={"h3"}
      sx={{
        p: "11px 19px",
        borderRadius: "19px",
        backgroundColor: dashboardTheme[bg],
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "500",
        lineHeight: "18px",
        letterSpacing: "0px",
        textAlign: "center",
        maxWidth: "800px",
      }}
    >
      <span
        style={{
          display: "block",
          color: "#fff",
        }}
      >
        {title}
      </span>
      <span
        style={{
          marginTop: "5px",
          display: "block",
          color: "#fff",
          fontSize: "18px",
          fontWeight: "600",
        }}
      >
        {value}
      </span>
    </Typography>
  );
};

export default TotalChart;
