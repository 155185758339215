import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Stack } from "@mui/system";
import React from "react";
import { OrderCategory } from "../../constants/store/constant";
import {WEButton} from "../Button";

const MyShares = ({ myShare, enterprise, trade }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isSmallMobile = useMediaQuery('(max-width:350px)');
    console.log(isMobile)

    return (
        <>
            <Grid2 display="flex" justifyContent="space-between">
                <Grid2 display="flex" justifyContent="space-around" sx={{ columnGap: 3, width: "100%" }}>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
                        <Typography variant="secondary">My Shares</Typography>
                        <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "20px", color: "#28282B" }}>
                            {Math.trunc(myShare)}
                        </Typography>
                    </Grid2>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
                        <Typography variant="secondary">Price Per Share</Typography>
                        <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "20px", color: "#5CDD5C" }}>
                            {"$" + (enterprise?.info?.price ? enterprise?.info?.price.toLocaleString(undefined, { minimumFractionDigits: 2, }) : "0.00")}
                        </Typography>
                    </Grid2>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
                        <Typography variant="secondary">Shares Value</Typography>
                        <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "20px", color: "#28282B" }}>
                            {"$" + parseFloat(enterprise?.info?.price) > 0 ?
                                (parseFloat(enterprise.info.price) * myShare).toLocaleString(undefined, { minimumFractionDigits: 2, }) : "0.00"}
                        </Typography>
                    </Grid2>
                </Grid2>
            </Grid2>

            <Stack direction={isSmallMobile? "column" : "row"} justifyContent="space-between" gap={1}>

                <WEButton
                    onClick={() => trade(OrderCategory.BUY)}
                    rounded = "xl"
                    type = "success"
                    startIcon={<Box sx={{ width: "18px", height: "18px" }} component="img" src="/images/pie_chart_icon.svg"></Box>}
                    size="small"
                    sx={{
                        fontSize: {xs: '13px', sm: '16px'},
                        px: {xs: 0, sm: 2},
                        width: {xs: '240px', sm: '350px'}
                    }}
                >
                    Buy
                </WEButton>
                <WEButton
                    onClick={() => trade(OrderCategory.SELL)}
                    rounded = "xl"
                    type = "error"
                    startIcon={<Box sx={{ width: "18px", height: "18px" }} component="img" src="/images/chart_icon.svg"></Box>}
                    size="small"
                    sx={{
                        fontSize: {xs: '13px', sm: '16px'},
                        px: {xs: 0, sm: 2},
                        width: {xs: '240px', sm: '350px'}
                    }}
                >
                    Sell
                </WEButton>
                <WEButton
                    onClick={() => trade('Orders')}
                    rounded = "xl"
                    type = "primary"
                    startIcon={<Box sx={{ height: "18px", width: "18px" }} component="img" src="/images/Mask group.svg"></Box>}
                    size="small"
                    sx={{
                        fontSize: {xs: '13px', sm: '16px'},
                        px: {xs: 0, sm: 2},
                        width: {xs: '320px', sm: '350px'}
                    }}
                >
                    Order Book
                </WEButton>
            </Stack>

        </>
    )
}

export default MyShares;