import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { AppBar } from "../AppBar";
import BsUpdatesList from "./BsUpdatesList";
import { ModalHead } from "../AppBar";
import HomeAppBar from "../AppBar/HomeAppBar";
import { useAutoConnect } from "../../utils/AutoConnect";


const BsUpdatesView = () => {
  const navigation = useNavigate();
  const { account } = useAutoConnect()
  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const { state } = useLocation();
  const { wepID, weBack, pTab } = state;
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const bsUpdates = enterprises.tempEnterprise.businessUpdates;
  const admins = enterprises.tempEnterprise.admins;

  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const addHandler = () => {
    setStep(STEP.DASHBOARD_BSUPDATE_ADD);
  };

  return (
    <>
      {/* <HomeAppBar />
      <ModalHead color="#FFDB0B" title={enterprises.tempEnterprise.info.name} /> */}
      <AppBar
        position="absolute"
        title="Business Updates"
        back={backHandler}
        type={admins.filter(admin => admin.walletAddr.toUpperCase() === account.toUpperCase()).length > 0 ? "add" : "none"}
        handle={admins.filter(admin => admin.walletAddr.toUpperCase() === account.toUpperCase()).length > 0 ? addHandler : undefined}
        toolTip="Business Updates"
      />
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          pt: bsUpdates.length === 0 ? 0 : 9, // remain for further update
        }}
      >
        {bsUpdates.length === 0 && admins.filter(admin => admin.walletAddr.toUpperCase() === account.toUpperCase()).length > 0 ? (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              m: 0,
              px: 3,
            }}
          >
            <Container
              sx={{
                display: "flex",
                position: "absolute",
                bottom: "49vh",
                px: 3,
              }}
            >
              <Button
                onClick={addHandler}
                sx={{
                  borderRadius: 24,
                  width: '100%',
                  bgcolor: '#28282B',
                  '&:hover': {
                    bgcolor: '#28282B',
                  }
                }}
              >
                Add a new business update
              </Button>
            </Container>
          </Container>
        ) : (
          <Container sx={{ mt: 2, px: 3 }}>
            <BsUpdatesList />
          </Container>
        )}
      </Grid>
    </>
  );
};

export default BsUpdatesView;
