export const downloadFile = ({ data, fileName, fileType }) => {
  // // Create a blob with the data we want to download as a file
  // const blob = new Blob([data], { type: fileType });
  // // Create an anchor element and dispatch a click event on it
  // // to trigger a download
  // const a = document.createElement("a");
  // a.download = fileName;
  // a.href = window.URL.createObjectURL(blob);
  // const clickEvt = new MouseEvent("click", {
  //   view: window,
  //   bubbles: true,
  //   cancelable: true,
  // });
  // a.dispatchEvent(clickEvt);
  // a.remove();


  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
  element.setAttribute('download', fileName);
  
  element.style.display = 'none';
  document.body.appendChild(element);
  
  element.click();
  
  document.body.removeChild(element);
};
