import { ChevronRight } from "@mui/icons-material";
import { Box, Divider, Unstable_Grid2 as Grid2, Typography } from "@mui/material";
import { default as React, useMemo, useState } from "react";
import { useNotification } from "../../components/Notification/hook";
import EnterpriseABI from "../../constants/abi/enterprise.json";
import { getDaysAndHours } from "../../utils/date";
import {WEButton} from "../Button";
import { useWeb3 } from "../../hook/web3";
import { useAutoConnect } from "../../utils/AutoConnect";
import { getEtherFromWei } from "../../utils/unit";
import { useTheme } from '@mui/material/styles';
import { DonutChart } from "../Chart";
import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";
import { useEnterprise } from "../../hook/weEnterprise";

const mockData = [
    { name: 'Abstain', value: 12 },
    { name: 'No', value: 27 },
    { name: 'Yes', value: 53 },
];

const COLORS = ["#28282b", "#FF0000", "#5CDD5C"];

const ProposalCard = ({ proposal, totalMembers }) => {
  	const theme = useTheme();
    const web3 = useWeb3();
    const [activity, setActivity] = useState(proposal);
    const { account } = useAutoConnect();
    const { displaySuccess, displayError } = useNotification();
    const [enterprises, ,] = store.useState(
        STORE_KEYS.id.enterprises
    );
    const enterprise = enterprises.tempEnterprise;
    const enterpriseHandler = useEnterprise(enterprise.info.address);

    const remainigTime = useMemo(() => {
        if (activity) {
            return getDaysAndHours(activity?.endTime);
        }
    }, [activity])

    const valuesInPercent = useMemo(() => {
        let total = activity?.votesYes + activity?.votesNo;
        return {
            yes: activity?.votesYes === 0 ? 0 : Math.round((activity?.votesYes / total) * 100),
            no: activity?.votesNo === 0 ? 0 : Math.round((activity?.votesNo / total) * 100),
            abstain: 0,
            notVoted: Math.round(((totalMembers - (activity?.votesNo + activity?.votesYes)) / totalMembers) * 100),
        }

    }, [activity]);

    const isChartDataExist = useMemo(() => {
        return !isNaN(valuesInPercent.yes) && !isNaN(valuesInPercent.no) && !isNaN(valuesInPercent.abstain);
    }, [valuesInPercent])

		const getSeries = (no, yes) => {
			if (isNaN(Number(no)) || isNaN(Number(yes))) {
				return [0, 100]
			}
			if (yes + no === 0) {
				return [0, 100]
			} else {
				return [yes, no]
			}
		}

    const proposalReject = async () => {
      
        try {
            await enterpriseHandler.methods
                .vote(parseInt(activity.idx), false)
                .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
            setActivity({ ...activity, isApproved: true, votesNo: ( activity.votesNo + 1 )})
            displaySuccess({ message: "Proposal rejected", timeout: 5000 });
        } catch (e) {
            displayError({ message: "There is an error to reject", timeout: 5000 });
        }
    };

    const proposalAgree = async () => {
        
        try {
            await enterpriseHandler.methods
                .vote(parseInt(activity.idx), true)
                .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
                setActivity({ ...activity, isApproved: true, votesYes: ( activity.votesYes + 1 )})
            displaySuccess({ message: "Proposal accepted", timeout: 5000 });
        } catch (e) {
            displayError({ message: "There is an error to agree", timeout: 5000 });
        }
    };
    return (
        <Grid2 sx={{ borderRadius: "8px", background: "#FFDB0A", padding: "12px" }}>
            <Grid2 display="flex" sx={{ columnGap: "25px" }}>
                <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                    <Typography variant="secondary" sx={{ color: "#28282B", opacity: "40%" }}>Status</Typography>
                    <Typography variant="secondary" sx={{ fontWeight: 600, color: "#28282B" }}>Vote Now!</Typography>
                </Box>
                <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                    <Typography variant="secondary" sx={{ color: "#28282B", opacity: "40%" }}>Ends</Typography>
                    <Typography variant="secondary" sx={{ fontWeight: 600, color: "#28282B" }}>{remainigTime}</Typography>
                </Box>
                <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                    <Typography variant="secondary" sx={{ color: "#28282B", opacity: "40%" }}>Members Voted</Typography>
                    <Typography variant="secondary" sx={{ fontWeight: 600, color: "#28282B" }}>{proposal?.votesYes + proposal?.votesNo}</Typography>
                </Box>
            </Grid2>
            <Grid2 display="flex" alignItems="center" justifyContent="center" sx={{ py: "12px", px: 0 }}>
                {
                    isChartDataExist ?
                        <Grid2 position="relative">
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <DonutChart
                                    width={125}
                                    height={125}
                                    chartSeries={getSeries(valuesInPercent.no, valuesInPercent.yes)}
                                    colors={[theme.palette.info.main, theme.palette.error.main]}
                                />
                                <Box sx={{ position: 'absolute', top: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Box>
                                        <Typography variant="secondary" sx={{ color: "#193065" }}>Yes</Typography>
                                        <Typography variant="secondary" sx={{ fontWeight: 600, fontSize: "18px", lineHeight: "22px", color: "#193065" }}>{valuesInPercent.yes}%</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid2> : null
                }
                <Grid2 display="flex" sx={{ columnGap: "18px", my: "20px", ml: 2 }}>
                    <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                        <Typography variant="secondary" sx={{ lineHeight: "15px", fontSize: 12, fontWeight: 500, color: "#5CDD5C" }}>Yes</Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", color: "#5CDD5C" }}>{valuesInPercent.yes}%</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                        <Typography variant="secondary" sx={{ lineHeight: "15px", fontSize: 12, color: "#FF0000" }}>No</Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", color: "#FF0000" }}>{valuesInPercent.no}%</Typography>
                    </Box>
                    {/* <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                        <Typography variant="secondary" sx={{ lineHeight: "15px", color: "#020433" }}>Abstain</Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", color: "#020433" }}>{valuesInPercent.abstain}%</Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" sx={{ rowGap: "6px" }}>
                        <Typography variant="secondary" sx={{ lineHeight: "15px", color: "#FFFFFF" }}>Did Not Vote</Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", color: "#FFFFFF" }}>{valuesInPercent.notVoted}%</Typography>
                    </Box> */}
                </Grid2>
            </Grid2>
            <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
                <Typography sx={{ textAlign: "center", fontWeight: 500, fontSize: "16px", lineHeight: "20px", color: "#28282B" }}>Your Vote</Typography>
                {
                    activity.isApproved ?
                        <Typography sx={{ fontWeight: 500, fontSize: "12px", lineHeight: "20px", color: "#18282B" }}>You already voted!</Typography>
                        :
                        <Grid2 display="flex" justifyContent="center" >
                            <Grid2 display="flex" justifyContent="center" sx={{ columnGap: "10px" }}>
                                <WEButton
                                    type = "success"
                                    rounded = "xl"
                                    width = {80}
                                    onClick={proposalAgree}
                                >
                                    Yes
                                </WEButton>
                                <WEButton
                                    type = "error"
                                    rounded = "xl"
                                    width = {80}
                                    onClick={proposalReject}
                                >
                                    No
                                </WEButton>
                            </Grid2>
                        </Grid2>

                }

            </Grid2>
        </Grid2>
    )
}

const ProposalContent = ({ proposals, totalMembers }) => {
    const adminProposals = proposals.filter(proposal => proposal.type === "admin");
    const shareholderProposals = proposals.filter(proposal => proposal.type === "shareholder");
    const item = shareholderProposals.length
    

    return (
        <Grid2 display="flex" flexDirection="column" sx={{ width: "100%", backgroundColor: "white", py: 2 }}>
            {adminProposals.length > 0 && adminProposals.map((proposal, index) => (
                <Grid2 display="flex" flexDirection="column" sx={{ width: "100%", rowGap: "12px", mb: 3}} key={`adminproposal_${index}`}>
                    <Grid2 display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }} >
                        <Typography variant="secondary" sx={{ fontWeight: 600, color: "#28282B" }}>New Admin Proposed</Typography>
                        <ChevronRight htmlColor="#000000" />
                    </Grid2>
                    <Typography variant="secondary" sx={{ fontWeight: 400, color: "#6F7287" }}>
                        {proposal?.name} has been proposed as new admin.
                    </Typography>
                    <ProposalCard proposal={proposal} totalMembers={totalMembers} />
                    {index === item-1 ?
                        null: <Divider sx={{mt:"24px"}}/>    
                    }
                </Grid2>
            ))}
            {adminProposals.length && shareholderProposals.length ? <Divider sx={{ mt: "14px", mb: "8px" }} /> : null}
            {shareholderProposals.length > 0 && shareholderProposals.map((proposal, index) => (
                <Grid2 display="flex" flexDirection="column" sx={{ width: "100%", rowGap: "12px", mb: 3, }} key={`adminproposal_${index}`}>
                    <Grid2 display="flex" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }} >
                        <Typography variant="secondary" sx={{ fontWeight: 600, fontSize: "16px", color: "#28282B" }}>New Shareholder Proposed</Typography>
                    </Grid2>
                    <Typography variant="secondary" sx={{ fontWeight: 400, color: "#6F7287" }}>
                        {proposal.name} has been proposed as shareholder
                    </Typography>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px", mt: "4px" }}>
                        <Typography variant="secondary">Number of shares</Typography>
                        <Typography variant="primary">{getEtherFromWei(proposal.amount)}</Typography>
                    </Grid2>
                    <ProposalCard proposal={proposal} totalMembers={totalMembers} />
                    {index === item-1 ?
                        null: <Divider sx={{mt:"24px"}}/>    
                    }
                </Grid2>
                
            ))}
            
        </Grid2>
    )
}

export default ProposalContent;