import { ArrowBack } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import Header from "../../components/Header";
import { Chip } from "../../components/Input";
import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";


const Chart = () => {
  const location = useLocation();
  const state = location.state;
  const navigation = useNavigate();
  const [period, setPeriod] = useState("Day");
  const [enterprises, , ] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;
  const { wepID, weBack } = state;

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
  };

  const changePeriod = (p) => {
    if (
      p === "Day" ||
      p === "Week" ||
      p === "Month" ||
      p === "Year" ||
      p === "All"
    ) {
      setPeriod(p);
    }
  };

  // data
  const [data, setData ] = useState([])
  const [lowestPrice, setLowestPrice] = useState(0);
  const [highestPrice, setHighestPrice] = useState(0);
  useEffect(() => {
    let corders = enterprise.orders.filter(order => { 
      if ( order.status !== "CLOSE" ) return false;
      if (period === "Day" && ( Number(new Date()) - 24 * 60 * 60 * 10 ** 3 ) > order.updated_at * 10 ** 3) { 
        return false;
      }
      if (period === "Week" && ( Number(new Date()) - 7 * 24 * 60 * 60 * 10 ** 3 ) > order.updated_at * 10 ** 3) { 
        return false;
      }
      return true;
    })
    let data = corders.map(order => {
      let temp = {};
      temp.time = new Date(order.updated_at * 10 ** 3);
      temp.price = order.price;
      return temp;
    })

    if (data.length > 0) {
      let sortedData = data.sort((a, b) => Number(a.price) > Number(b.price));
      setHighestPrice(sortedData[sortedData.length - 1].price);
      setLowestPrice(sortedData[0].price);
    }
    
    setData(data);
  }, [enterprise.orders, period])

  return (
    <>
      <Header pageTitle={"Chart"} />
      <AppBar
        position="static"
        sx={{ boxShadow: "none", borderBottom: "2px solid #E3E8EB" }}
      >
        <Toolbar sx={{ px: 3, pt: 3 }}>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            onClick={() => {
              back();
            }}
          >
            <ArrowBack fontSize="medium" htmlColor="#4B4749" />
          </IconButton>
          <Typography variant="h2" component="div" sx={{ flexGrow: 1 }}>
            USD&nbsp;/&nbsp;
            <span style={{ color: "#00C9F2" }}> {enterprise.info.tokenName}</span>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="main">
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "40px",
          }}
        >
          <Info title="High Price(wc)" value={`$${highestPrice}`} />
          <Info title="Low Price(wc)" value={`$${lowestPrice}`} />
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          {["Day", "Week"].map((val, idx) => {
            return (
              <Chip
                key={idx}
                label={val}
                sx={(() => {
                  if (val === period)
                    return {
                      background: "#00C9F2",
                      color: "#fff",
                    };
                  else return {};
                })()}
                onClick={() => {
                  changePeriod(val);
                }}
              />
            );
          })}
        </Box>
        <Box height={"600px"}>
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart
              data={data}
              margin={{ top: 10, right: 0, left: 30, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorPrice" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#00C9F2" stopOpacity={0.13} />
                  <stop offset="95%" stopColor="#00C9F2" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="time"
                tickFormatter={(time) => time.toLocaleString()}
              />
              <YAxis
                id="price"
                orientation="right"
                tickFormatter={(price) => (price === 0 ? "" : `$${price}`)}
                style={{
                  paddingRight: "20px",
                  backgroundColor: "#FF00FF",
                }}
              />
              <CartesianGrid strokeDasharray="3 3" />
              {/* <Tooltip /> */}
              <Area
                type="monotone"
                dataKey="price"
                stroke="#00C9F2"
                fillOpacity={1}
                fill="url(#colorPrice) "
              />
              {
                data.length > 0 &&
                <ReferenceLine
                  y={data[data.length - 1].price}
                  strokeWidth={3}
                  stroke="#00C9F2"
                  strokeDasharray="3 3"
                >
                  <Label
                    value={`$${data[data.length - 1].price}`}
                    position="right"
                    fill="#000"
                    content={({ value, viewBox }) => {
                      const { x, y, width } = viewBox;
                      return (
                        <foreignObject
                          {...viewBox}
                          width={100}
                          height={50}
                          x={(width || 0) + (x || 0)}
                          y={(y || 0) - 13}
                        >
                          <p
                            style={{
                              display: "inline-block",
                              color: "#fff",
                              backgroundColor: "#00C9F2",
                              margin: "0px",
                              padding: "3px 5px",
                              width: "100%",
                              borderRadius: "15px",
                            }}
                          >
                            {value}
                          </p>
                        </foreignObject>
                      );
                    }}
                  >
                    {`$${data[data.length - 1].price}`}
                  </Label>
                </ReferenceLine>
              }
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </>
  );
};

const Info = ({ title, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <Typography
        sx={{
          color: "#A5A5A5",
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          color: "#28282B",
          fontSize: "20px",
          fontWeight: 700,
        }}
      >
        {value.toLocaleString()}
      </Typography>
    </Box>
  );
};

export default Chart;
