import { ChevronRight, Help } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

const DashboardCard = (props) => {
    const { children, title, seeFlag = false, seeAll, tooltip = "" } = props;
    const [showTooltip, setShowTooltip] = React.useState(false);

    const handleClick = () => {
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap={2}
            sx={{
                background: "#ffffff",
                borderRadius: "8px",
                px: 2,
                py: "13px",
                boxShadow: "0px 4px 60px rgba(0, 0, 0, 0.12)"
            }}
        >
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{ width: "100%" }}
            >
                <Typography
                    sx={{
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color: "#28282B"
                    }}
                >{title}
                    {tooltip !== "" && <Tooltip title={tooltip} open={showTooltip}>
                        <IconButton
                            size="small"
                            color="primary"
                            onClick={handleClick}
                        >
                            <Help
                                fontSize="small"
                                htmlColor="#020433"
                            />
                        </IconButton>
                    </Tooltip>}
                </Typography>
                {seeFlag &&
                    <Button
                        variant="outlined"
                        endIcon={<ChevronRight htmlColor="#28282B" />}
                        onClick={() => seeAll("shareholders")}
                        sx={{
                            backgroundColor: "#FFFFFFFF",
                            border: "0",
                            ":hover": {
                                backgroundColor: "#FFFFFFFF",
                                border: "0",
                            },
                            color: "#28282B",
                            p: 0,
                            float: "right",
                            fontFamily: "Montserrat",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "14px",
                            lineHeight: "17px",
                        }}
                    >
                        See All
                    </Button>
                }
            </Stack>
            {children}
        </Box>
    )
}

export default DashboardCard;