import React, { useEffect, useState } from "react";

import {
  Box,
  Container,
  Grid,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import Web3 from "web3";
import EnterpriseABI from "../../constants/abi/enterprise.json";
import store from "../../constants/store";

import {
  EmptyOrder,
  EmptyTransaction,
  OrderCategory,
  OrderStatus,
  STORE_KEYS,
  TXTYPE,
} from "../../constants/store/constant";

import { useWeb3 } from "../../hook/web3";
import { convertMaticToUSD, getEtherFromWei } from "../../utils/unit";

import { HomeAppBar, ModalHead } from "../AppBar";
import { CustomButton } from "../Button";
import { Input, InputLabel } from "../Input";
import { useNotification } from "../Notification/hook";
import OrderBook from "./OrderBook";
import {WELoadingButton} from "../Button";
import { useAutoConnect } from "../../utils/AutoConnect";

const CATEGORIES = {
  BUY: OrderCategory.BUY,
  SELL: OrderCategory.SELL,
  OrderList: "Orders",
};

const NewOrder = ({ wepID, weBack }) => {
  const navigation = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { tabId } = state;
  const web3 = useWeb3();

  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;
  const orders = enterprise.orders;
  const { account } = useAutoConnect()
  const { displaySuccess, displayError } = useNotification();

  // price in USD (token to USD)
  const [price, setPrice] = useState("");
  const [amount, setAmount] = useState("");
  const [usdValue, setUsdValue] = useState("0");
  const [balance] = useState(
    getEtherFromWei(enterprises.tempEnterprise.numOfShare)
  );
  // const [type, setType] = useState("BUY");
  const [category, setCategory] = useState(tabId);
  const [buttonStr, setButtonStr] = useState("Submit Order");
  const [orderbookCategory, setOrderBookCategory] = useState("BUY")

  const switchHandler = (categori) => () => {
    switch (categori) {
      case CATEGORIES.BUY:
        setCategory(CATEGORIES.BUY);
        break;
      case CATEGORIES.SELL:
        setCategory(CATEGORIES.SELL);
        break;
      case CATEGORIES.OrderList:
        setCategory(CATEGORIES.OrderList);
        break;
      default:
        break;
    }
  };

  const handleInputChange = (type) => (evt) => {
    let value = evt.currentTarget.value;
    let regExp = /[0-9]+/;
    switch (type) {
      case "price":
        regExp = /[0-9]*([.])?[0-9]*/;
        if (regExp.exec(value)?.[0] === value || value === "") {
          if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;
          setPrice(value);
        }
        break;
      case "amount":
        regExp = /[0-9]+/;
        if (regExp.exec(value)?.[0] === value || value === "") {
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);

          setAmount(value);
        }
        break;
      default:
        break;
    }
  };

  const closeHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
  };

  const newOrderHandler = async () => {
    if (category !== CATEGORIES.BUY && category !== CATEGORIES.SELL) return;
    if (buttonStr !== "Submit Order") return;

    try {
      const enterpriseHandler = new web3.eth.Contract(
        EnterpriseABI,
        enterprise.info.address
      );

      if (!(parseFloat(amount) > 0)) {
        return displayError({
          message: "Amount error",
          timeout: 5000,
        });
      }

      if (!(parseFloat(price) > 0)) {
        return displayError({
          message: "Price error",
          timeout: 5000,
        });
      }

      let priceAmount = parseFloat(amount) * parseFloat(price);
      priceAmount = Web3.utils.toWei(priceAmount.toString());
      const _amount = Web3.utils.toWei(amount);
      let _price = Web3.utils.toWei(price);

      if (category === CATEGORIES.BUY) {
        let _balance = await web3.eth.getBalance(account);
        if (parseFloat(priceAmount) > parseFloat(_balance)) {
          return displayError({
            message: "There is not enough native token in your account",
            timeout: 5000,
          });
        }
        setButtonStr("Confirming");
        await enterpriseHandler.methods
          .createBuyOrder(amount, _price)
          .send({ from: account, value: priceAmount, gasLimit: web3.eth.getBlock("latest").gasLimit });

        setButtonStr("Submit Order");
      } else if (category === CATEGORIES.SELL) {
        if (!(parseFloat(amount) <= balance)) {
          return displayError({
            message: "Amount exceed balance error",
            timeout: 5000,
          });
        }

        setButtonStr("Approving");

        await enterpriseHandler.methods
          .approve(enterprise.info.address.toLowerCase(), _amount)
          .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
        displaySuccess({
          message: "Approve success",
          timeout: 5000,
        });

        setButtonStr("Confirming");

        await enterpriseHandler.methods
          .createSellOrder(amount, _price)
          .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });

        setButtonStr("Submit Order");
      }

      const type = category;

      const order = EmptyOrder();
      order.id = Date.now();
      order.type = type;
      order.amount = getEtherFromWei(_amount);
      order.price = getEtherFromWei(_price);
      order.date = new Date();
      order.owner_address = account || "";
      order.created_at = Date.now();
      order.status = OrderStatus.ACTIVE;
      order.enterprise_address = enterprise.info.address;
      order.order_id = orders.length;

      if (type === CATEGORIES.SELL) {
        let newTrx = EmptyTransaction()
        newTrx.amount = getEtherFromWei(_amount)
        newTrx.asset = enterprise.info.tokenName
        newTrx.category = ""
        newTrx.created_at = Date.now()
        newTrx.enterprise_address = enterprise.info.address
        newTrx.from = account.toUpperCase()
        newTrx.to = enterprise.info.address
        newTrx.id = Date.now()
        newTrx.note = ""
        newTrx.token = enterprise.info.address
        newTrx.type = TXTYPE.SHARE_OUT
        updateEnterprises((prev) => {
          prev.tempEnterprise.transactions = [newTrx, ...prev.tempEnterprise.transactions];
          prev.tempEnterprise.numOfShare -= parseInt(_amount)
          return prev;
        });
      }
      updateEnterprises((prev) => {
        prev.tempEnterprise.orders = [...prev.tempEnterprise.orders, order];
        return prev;
      });

      setPrice("");
      setAmount("");
      if (category === CATEGORIES.BUY) setOrderBookCategory("BUY")
      else if (category === CATEGORIES.SELL) setOrderBookCategory("SELL")
      setCategory(CATEGORIES.OrderList);
      displaySuccess({ message: "Trade submitted!", timeout: 5000 });
    } catch (e) {
      setButtonStr("Submit Order");
      console.log(e)
      displayError({ message: "Failed to create order", timeout: 5000 });
    }
  };

  useEffect(() => {
    if (amount === "" || price === "") return;
    convertMaticToUSD(Number(amount) * Number(price)).then(setUsdValue);
  }, [amount, price]);
  return (
    <>
      <HomeAppBar position="absolute" />
      <Box
        component={"main"}
        sx={{ bgcolor: '#E5E5E5', minHeight: "calc(100vh - 66px)" }}
      >
        <ModalHead color="#FF6142" close={closeHandler} title={'Trade Shares'} theme='dark' />
        <Box sx={{ px: '27px' }}>
          <Container sx={{ position: "relative", py: 1, px: 0 }}>
            <Grid
              component="h2"
              container
              sx={{
                width: "100%",
                lineHeight: "40px",
                py: 0,
                my: 0,
                mr: 2,
                ml: 0,
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "15px",
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid #E3E8EB",
                cursor: "pointer",
                bgcolor: "#DDDDDD"
              }}
            >
              <Grid
                item
                xs={4}
                component="div"
                style={{
                  display: "inline-block",
                  borderRadius: "6px",
                  backgroundColor:
                    category === CATEGORIES.BUY ? "#28282B" : "#DDDDDD",
                  color: category === CATEGORIES.BUY ? "#ffffff" : "#28282B",
                }}
                onClick={switchHandler(CATEGORIES.BUY)}
              >
                Buy
              </Grid>
              <Grid
                item
                xs={4}
                component="div"
                style={{
                  display: "inline-block",
                  borderRadius: "6px",
                  borderColor: "#E3E8EB",
                  backgroundColor:
                    category === CATEGORIES.SELL ? "#28282B" : "#DDDDDD",
                  color: category === CATEGORIES.SELL ? "#ffffff" : "#28282B",
                }}
                onClick={switchHandler(CATEGORIES.SELL)}
              >
                Sell
              </Grid>
              <Grid
                item
                xs={4}
                component="div"
                style={{
                  display: "inline-block",
                  borderRadius: "6px",
                  backgroundColor:
                    category === CATEGORIES.OrderList ? "#28282B" : "#DDDDDD",
                  color:
                    category === CATEGORIES.OrderList ? "#ffffff" : "#28282B",
                }}
                onClick={switchHandler(CATEGORIES.OrderList)}
              >
                Order Book
              </Grid>
            </Grid>
          </Container>
          {(category === CATEGORIES.BUY || category === CATEGORIES.SELL) && (
            <>
              <Grid item xs={12} mt={2}>
                <InputLabel shrink htmlFor="amount" sx={{ color: '#6F7287', lineHeight: 17 }}>
                  Amount of Shares to{" "}
                  {category === CATEGORIES.BUY ? "BUY" : "SELL"}
                </InputLabel>
                <Input
                  id="amount"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={handleInputChange("amount")}
                  value={amount}
                  placeholder="0"
                  sx={{
                    "& input": {
                      py: 1,
                      my: 1.2,
                      fontSize: '24px !important',
                      color: '#A5A5A5',
                      bgColor: "#ffffff !important",
                      borderRadius: '6px !important',
                      borderColor: '#E8E8EA !important'
                    },
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: '#28282B'
                  }}
                >
                  My Shares:{" "}
                  {category === CATEGORIES.SELL
                    ? balance - Number(amount)
                    : balance}
                </Typography>
              </Grid>
              <Grid item xs={12} mt={3.25}>
                <InputLabel shrink htmlFor="amount" sx={{ color: '#6F7287', lineHeight: 17 }}>
                  Price in Matic
                </InputLabel>
                <Input
                  id="amount"
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                  }}
                  onChange={handleInputChange("price")}
                  value={price}
                  placeholder="0.00"
                  sx={{
                    "& input": {
                      py: 1,
                      my: 1.2,
                      fontSize: '24px !important',
                      color: '#A5A5A5',
                      bgcolor: "#ffffff",
                      borderRadius: '6px !important',
                      borderColor: '#E8E8EA !important'
                    },
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    fontSize: "14px",
                    color: '#28282B'
                  }}
                >
                  {category === CATEGORIES.BUY
                    ? "Purchase Amount"
                    : "Total to Receive"}
                  : {Number(amount) * Number(price)} MATIC{" or "}${usdValue}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  mt: 7,
                  mb: 3,
                }}
              >
                <Box sx={{ width: "100%", textAlign:"center" }}>
                  
                  <WELoadingButton
                    loading = {buttonStr !== "Submit Order"}
                    onClick={newOrderHandler}
                    type = "primary"
                    width = {320}
                    rounded = "xl"
                  >
                    {buttonStr}
                  </WELoadingButton>
                </Box>
              </Grid>
            </>
          )}
          {category === CATEGORIES.OrderList && <OrderBook category={orderbookCategory} />}
        </Box>
      </Box >
    </>
  );
};

export default NewOrder;