import { ArrowForward } from "@mui/icons-material";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getProposalsForEnterprise } from "../../apis";
import { AdminAdd } from "../../components/Admins";
import { HomeAppBar, ModalHead } from "../../components/AppBar";
import {
  BtnCancel,
  BtnOption,
  WEModal,
  ProposalDetailModal,
  Transition
} from "../../components/Dialog";
import CommentModal from "../../components/Dialog/CommentModal";
import Header from "../../components/Header";
import { useNotification } from "../../components/Notification/hook";
import ScrollToTop from "../../components/ScrollToTop";
import { ShareholderAdd } from "../../components/Shareholders";
import { ProposalItem } from "../../components/UserDashboard";
import EnterpriseABI from "../../constants/abi/enterprise.json";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useWeb3 } from "../../hook/web3";
import { useAutoConnect } from "../../utils/AutoConnect";
import { simpleErrorMessage } from "../../utils/errorMessage";
import { ERRORS } from "../../utils/errors";

const Proposals = () => {
  const { displaySuccess, displayError } = useNotification();
  const { account } = useAutoConnect();
  const location = useLocation();
  const state = location.state;
  const navigation = useNavigate();
  const { wepID, weBack } = state;
  const web3 = useWeb3();

  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;

  const [proposals, setProposals] = useState([]);
  const [newOpen, setNewOpen] = useState(false);
  const [idx, setIdx] = useState(-1);
  const [detailOpen, setDetailOpen] = useState(false);
  const [commentDlgOpen, setCommentDlgOpen] = useState(false);

  const backHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
  };
  // add dialog
  const addHandler = () => {
    setNewOpen(true);
  };

  const newOpenClose = () => {
    setNewOpen(false);
  };

  const adminHandler = () => {
    setNewOpen(false);
    setStep(STEP.PROPOSAL_ADMIN);
  };
  const shareholderHandler = () => {
    setNewOpen(false);
    setStep(STEP.PROPOSAL_SHAREHOLDER);
  };

  // Detail Dialog
  const onClickItem = (one) => {
    const index = enterprise?.proposals.findIndex(e => e.id === one.id);
    if (index !== -1) {
      setDetailOpen(true);
      setIdx(index);
    }
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  const handleReject = async () => {
    if (enterprise?.proposals[idx]?.isApproved) {
      return handleDetailClose();
    }

    try {
      const enterpriseHandler = new web3.eth.Contract(
        EnterpriseABI,
        enterprise.info.address
      );
      await enterpriseHandler.methods
        .vote(parseInt(enterprise.proposals[idx].idx), false)
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
      handleDetailClose();
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.info.address === enterprise.info.address
        );
        prev.enterprises[index].proposals[idx].isApproved = true;
        prev.enterprises[index].proposals[idx].votesNo += 1;
        prev.tempEnterprise.proposals[idx].isApproved = true;
        prev.tempEnterprise.proposals[idx].votesNo += 1;
        return prev;
      });
      displaySuccess({ message: "Proposal rejected", timeout: 5000 });
    } catch (e) {
      displayError({
        message: ERRORS.TO_REJECT,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  const handleAgree = async () => {
    if (enterprise?.proposals[idx]?.isApproved) {
      return;
    }
    try {
      const enterpriseHandler = new web3.eth.Contract(
        EnterpriseABI,
        enterprise.info.address
      );
      await enterpriseHandler.methods
        .vote(parseInt(enterprise.proposals[idx].idx), true)
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
      handleDetailClose();
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.info.address === enterprise.info.address
        );
        prev.enterprises[index].proposals[idx].isApproved = true;
        prev.enterprises[index].proposals[idx].votesYes += 1;
        prev.tempEnterprise.proposals[idx].isApproved = true;
        prev.tempEnterprise.proposals[idx].votesYes += 1;
        return prev;
      });
      displaySuccess({ message: "Proposal accepted", timeout: 5000 });
    } catch (e) {
      displayError({
        message: ERRORS.TO_AGREE,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  const handleExecute = async () => {
    try {
      const enterpriseHandler = new web3.eth.Contract(
        EnterpriseABI,
        enterprise.info.address
      );
      await enterpriseHandler.methods
        .execute(parseInt(enterprise.proposals[idx].idx))
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
      handleDetailClose();
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.info.address === enterprise.info.address
        );
        prev.enterprises[index].proposals[idx].status = "EXECUTED";
        return prev;
      });
      displaySuccess({ message: "Proposal executed", timeout: 5000 });
    } catch (e) {
      displayError({
        message: ERRORS.TO_EXECUTE,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  useEffect(() => {


    if (!enterprise.info.address) {
      return;
    }
    // proposals fetched from join-world-enterprise

    getProposalsForEnterprise(enterprise.info.address)
      .then((res) => {
        let proposals = [];
        let origins = res.data?.exists ? res.data?.data : [];
        origins.forEach((proposal) => {

          let votesYes = 0;
          let votesNo = 0;
          if (proposal.votes) {
            let votes = JSON.parse(proposal.votes);
            let vote_addresses = Object.keys(votes)
            vote_addresses.forEach((address) => { // eslint-disable-line no-loop-func
              let content = votes[address];
              if (content.approve === "YES") {
                votesYes++;
              }

              if (content.approve === "NO") {
                votesNo++;
              }

            })
          }
          proposals = [
            ...proposals,
            {
              type: proposal.type ? proposal.type : "join",
              walletAddr: proposal.proposer,
              enterpriseAddr: enterprise.info.address,
              state: proposal.isApproved ? proposal.isApproved : false,
              votesYes: votesYes,
              votesNo: votesNo,
              amount: proposal.amount,
              commentUrl: proposal?.commentUrl,
              status: proposal?.status,
              endTime: proposal?.endTime,
              id: proposal?.id

            },
          ];
        });
        setProposals(proposals);
      })
      .catch((reason) => {
        setProposals([]);
        console.error(
          `Failed to load proposals for account: ${enterprise.info.address}`,
          reason
        );
      });
  }, [enterprise]);

  useEffect(() => {
    setStep(STEP.INDEX)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <ScrollToTop />
      <Header pageTitle={"Proposals"} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: "#E5E5E5", minHeight: "calc(100vh - 66px)" }}>
        <ModalHead
          title={enterprise.info.name}
          close={() => backHandler()}
          color='#FFDB0B'
        />
        {stepState === STEP.PROPOSAL_ADMIN && <AdminAdd />}
        {stepState === STEP.PROPOSAL_SHAREHOLDER && <ShareholderAdd />}
        {stepState === STEP.INDEX && (
          <>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                position: "relative",
                justifyContent: "center",
                textAlign: "center",
                padding: "40px 0",

              }}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600
                }}>
                Proposal
              </Typography>
            </Box>
            <Box sx={{ padding: "5px 16px" }}>
              <Divider />
              <Grid
                container
                component="main"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                  px: 0,
                  mt: proposals.length === 0 ? 0 : 0, // remain for further update
                }}
              >
                <List dense={true} sx={{ width: "100%", pt: 2, px: 0 }}>
                  {proposals.map((proposal, index) => {
                    return (
                      <ProposalItem key={index} proposal={proposal} index={index} openModal={onClickItem} />
                    );
                  })}
                </List>
              </Grid>
            </Box>
            <CommentModal
              open={commentDlgOpen}
              onClose={() => setCommentDlgOpen(false)}
              id={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].id.toString()) ||
                ""
              }
              idx={idx}
              comments={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].comments) ||
                []
              }

            />
            <ProposalDetailModal
              open={detailOpen}
              handleClose={handleDetailClose}
              handleCommentOpen={() => setCommentDlgOpen(true)}
              reject={handleReject}
              agree={handleAgree}
              execute={handleExecute}
              name={
                (enterprise.proposals[idx] && enterprise.proposals[idx].name) ||
                ""
              }
              walletAddr={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].walletAddr) ||
                ""
              }
              idx={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].idx.toString()) ||
                ""
              }
              id={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].id.toString()) ||
                ""
              }
              yesNum={
                enterprise.proposals[idx] && enterprise.proposals[idx].votesYes
              }
              noNum={
                enterprise.proposals[idx] && enterprise.proposals[idx].votesNo
              }
              commentUrl={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].commentUrl) ||
                ""
              }
              comments={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].comments) ||
                []
              }
              isApproved={
                (enterprise.proposals[idx] &&
                  enterprise.proposals[idx].isApproved) ||
                ""
              }
              status={enterprise?.proposals[idx]?.status || ""}
              endTime={enterprise?.proposals[idx]?.endTime || ""}
            />
            <WEModal
              open={newOpen}
              onClose={newOpenClose}
              TransitionComponent={Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="xl"
            >
              <DialogTitle id="alert-dialog-title">
                <span>{"New Proposal"}</span>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <BtnOption onClick={adminHandler}>
                  <span>Propose Admin</span>
                  <ArrowForward htmlColor="#BCC0C4" />
                </BtnOption>
                <BtnOption onClick={shareholderHandler}>
                  <span>Propose Shareholder</span>
                  <ArrowForward htmlColor="#BCC0C4" />
                </BtnOption>
              </DialogContent>
              <Divider />
              <DialogActions>
                <BtnCancel onClick={newOpenClose}>Cancel</BtnCancel>
              </DialogActions>
            </WEModal>
          </>
        )}
      </Box>
    </>
  );
};

export const Item = ({ type, walletAddr, state, percent, onClick }) => {
  return (
    <>
      {/* <Box sx={{ bgcolor: '#ffffff', py: 2.5, px: 2 }}>
        <Stack direction='row' alignItems='center'>
          <Box component='img' src='/images/proposal.svg' alt='account' sx={{ borderRadius: '6px', width: 68, height: 68, mr: 1.5 }} />
          <Box>
            <Typography sx={{ fontSize: 12, fontWeight: 400, color: '#9B9B9B' }}>Garden Arhitects Chicago</Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 600, color: '#193065' }}>New shareholder was proposed</Typography>
          </Box>
        </Stack>
        <Typography sx={{ fontSize: 13, fontWeight: 400, color: '#6F7287', my: 2 }}>Michal Slobada has been proposed as a new admin.</Typography>
        <Stack direction='row' alignItems='center'>
          <Stack>
          </Stack>
        </Stack>
      </Box> */}
      {/* <ListItemButton
        component="div"
        sx={{
          px: 3,
          py: 1,
        }}
        onClick={() => {
          onClick();
        }}
      >
        <ListItemText
          primary={(() => {
            switch (type) {
              case "admin":
                return "Add Admin";
              case "shareholder":
                return "Add Member";
              case "join":
                return "Join";
              case "adminReplace":
                return "Replace Admin";
              default:
                return "";
            }
          })()}
          secondary={walletAddr}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#241F21",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#4B4749",
            textAlign: "left",
            maxWidth: "24ch",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
        <ListItemText
          primary={state ? "Approved" : "Pending Vote"}
          secondary={`${percent.toFixed(2)}%`}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: (() => {
              if (state) return "#36D233";
              else return "#FF2A00";
            })(),
            textAlign: "right",
          }}
          secondaryTypographyProps={{
            mt: 1,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "15px",
            color: (() => {
              if (percent > 50) return "#36D233";
              else return "#FF2A00";
            })(),
            textAlign: "right",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
      </ListItemButton>
      <Divider /> */}
    </>
  );
};

export default Proposals;
