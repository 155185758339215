import React, { useState, useEffect } from "react";

import { Button, Badge, Avatar, Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Person, Edit, AddAPhoto } from "@mui/icons-material";
import { Container } from "@mui/system";
import { Input, InputLabel } from "../Input";
import { PhotoEditorModal } from "../Dialog";
import { ERRORS } from "../../utils/errors";
import { useNotification } from "../Notification/hook";

const UserReview = ({
  name,
  photo,
  jobTitle,
  username,
  description,
  continueHandler
}) => {
  const [nm, setNm] = useState(name);
  const [jt, setJobTitle] = useState(jobTitle);
  const [usernm, setUsernm] = useState(username);
  const { displayError } = useNotification();
  const [editorOpen, setEditorOpen] = useState(false);
  const [image, setImage] = useState(undefined);
  const [photoUrl, setPhotoUrl] = useState(photo);
  const [ipfsUrl, setIpfsUrl] = useState(photo);
  
  const [inputStatus, setInputStatus] = useState({
    username: false,
    name: false,
    jobTitle: false
  });

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) return;
    setEditorOpen(true);
    setImage(fileObj);
  };

  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case "username":
        setUsernm(value);
        break;
      case "name":
        setNm(value);
        break;
      case "job_title":
        setJobTitle(value);
        break;
      default:
        break;
    }
  };

  const handleEdit = (key) => {
    setInputStatus((pre) => ({ ...pre, [key]: true }))
  }

  const editorClose = () => {
    setEditorOpen(false);
  };

  const editorOK = (ipfsURL, imageURL) => {
    setPhotoUrl(imageURL);
    setIpfsUrl(ipfsURL);
  };

  useEffect(() => {
    setInputStatus({
      username: false,
      name: false,
      jobTitle: false
    })
  }, [username, name, jobTitle])

  const update = (nm, usernm, ipfsUrl, jt) => {

    if (!inputStatus.username && !inputStatus.name && !inputStatus.jobTitle && ipfsUrl == photo) {
      displayError({
        message: ERRORS.PROFILE_NO_CHANGE,
        reason: "",
        timeout: 3000,
      });

      return
    }
    continueHandler(nm, usernm, ipfsUrl, jt)
  }
  return (
    <>
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: "0px 27px"
        }}
      >
        {description && <Typography
          variant="h2"
          sx={{
            marginTop: "24px",
            fontSize: "23px",
            lineHeight: 1,
            fontWeight: "700",
            display: { xs: "block", md: "none" }
          }}
        >
          {description}
        </Typography>}
        <Container
          component={"div"}
          sx={{
            mt: 3.25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            sx={{ p: 0 }}
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              value={""}
            />
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={
                <Avatar
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#28282B",
                  }}
                >
                  <AddAPhoto sx={{ width: "16px", color: "#FFFFFF", position: 'relative', top: -1 }} />
                </Avatar>
              }
            >
              <Avatar sx={{ width: "83px", height: "83px" }}>
                {photoUrl !== "" && photoUrl !== undefined ? (
                  <img alt="" src={photoUrl} style={{ width: "100%" }} />
                ) : (
                  <Person
                    sx={{ width: "70px", height: "70px" }}
                    htmlColor="#4B4749"
                  />
                )}
              </Avatar>
            </Badge>
          </IconButton>
        </Container>
        <Box sx={{ width: '100%', mt: 6.25 }}>
          {
            inputStatus.username ?
              <Box width="100%">
                <InputLabel shrink htmlFor="username">
                  Username
                </InputLabel>
                <Input
                  label='User name'
                  id="username"
                  value={usernm}
                  fullWidth
                  onChange={handleInputChange("username")}
                />
              </Box>
              :
              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }} >
                <Typography
                  variant="h3"
                  mt={2}
                  sx={{
                    mt: 0,
                    py: "12px",
                    fontWeight: 500,
                    minHeight: "21.5px",
                    opacity: username ? 1 : 0.7
                  }}
                >
                  {username ? username : 'User name'}
                </Typography>
                <IconButton onClick={() => handleEdit('username')} sx={{ p: 0 }}>
                  <Edit sx={{ color: '#6F7287' }} />
                </IconButton>
              </Stack>
          }
        </Box>
        <Box sx={{ width: '100%', mt: 5 }}>
          {
            inputStatus.name ?
              <Box width="100%">
                <InputLabel shrink htmlFor="name">
                  Name (Optional - Keep blank, if you prefer to stay anonymous to other users).
                </InputLabel>
                <Input
                  id="name"
                  value={nm}
                  fullWidth
                  onChange={handleInputChange("name")}
                />
              </Box>
              :
              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }} >
                <Typography
                  variant="h3"
                  mt={2}
                  sx={{
                    mt: 0,
                    py: "12px",
                    fontWeight: 500,
                    minHeight: "21.5px",
                    opacity: name ? 1 : 0.7
                  }}
                >
                  {name ? name : 'Name'}
                </Typography>
                <IconButton onClick={() => handleEdit('name')} sx={{ p: 0 }}>
                  <Edit sx={{ color: '#6F7287' }} />
                </IconButton>
              </Stack>
          }
        </Box>
        <Box sx={{ width: '100%', mt: 5 }}>
          {
            inputStatus.jobTitle ?
              <Box width="100%">
                <InputLabel shrink htmlFor="job_title">
                  Job Title
                </InputLabel>
                <Input
                  id="job_title"
                  value={jt}
                  fullWidth
                  onChange={handleInputChange("job_title")}
                />
              </Box>
              :
              <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ width: '100%' }} >
                <Typography
                  variant="h3"
                  mt={2}
                  sx={{
                    mt: 0,
                    py: "12px",
                    fontWeight: 500,
                    minHeight: "21.5px",
                    opacity: jobTitle ? 1 : 0.7
                  }}
                >
                  {jobTitle ? jobTitle : 'Job Title'}
                </Typography>
                <IconButton onClick={() => handleEdit('jobTitle')} sx={{ p: 0 }}>
                  <Edit sx={{ color: '#6F7287' }} />
                </IconButton>
              </Stack>
          }
        </Box>
        <Button
          sx={{
            mt: 6,
            mb: 5,
            width: '100%',
            borderRadius: 24,
            bgcolor: '#28282B',
            '&:hover': {
              bgcolor: '#28282B',
            }
          }}
          onClick={() => update(nm, usernm, ipfsUrl, jt)}
        >
          Update
        </Button>
      </Grid>
      <PhotoEditorModal
        open={editorOpen}
        onClose={editorClose}
        image={image}
        onOK={editorOK}
      />
    </>
  );
};

export default UserReview;
