import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { HomeAppBar, ModalHead } from "../../components/AppBar";
import { BsUpdateEdit, BsUpdatesView } from "../../components/BsUpdates";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";

const BusinessUpdates = () => {
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const updateableBusinessUpdateId = enterprises.tempEnterprise.updateableBusinessUpdateId;
  const navigator = useNavigate();

  useEffect(() => {
    if (updateableBusinessUpdateId === null || updateableBusinessUpdateId === undefined || updateableBusinessUpdateId === -1) {
      setStep(STEP.DASHBOARD_BSUPDATE);
    } else {
      setStep(STEP.DASHBOARD_BSUPDATE_EDIT)
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    switch (stepState) {
      case STEP.DASHBOARD_BSUPDATE:
        navigator(-1);
        break;
      case STEP.DASHBOARD_BSUPDATE_ADD:
        setStep(STEP.DASHBOARD_BSUPDATE)
        break;
      default:
        navigator(-1);
        break;
    }
  }

  return (
    <>
      <ScrollToTop />
      <Header pageTitle="Business Updates" />
      <HomeAppBar />
      <ModalHead color="#FFDB0B" title={enterprises.tempEnterprise.info.name} customClose={() => handleClose()} />
      {stepState === STEP.DASHBOARD_BSUPDATE && <BsUpdatesView />}
      {(stepState === STEP.DASHBOARD_BSUPDATE_ADD || stepState === STEP.DASHBOARD_BSUPDATE_EDIT) && <BsUpdateEdit />}
    </>
  );
};

export default BusinessUpdates;
