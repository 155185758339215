import React, { useState } from "react";

import {
  AccountCircle,
  ArrowBack,
  Menu as MenuIcon
} from "@mui/icons-material";

import {
  AppBar,
  Avatar,
  Box,
  IconButton, Toolbar,
  Typography
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import store from "../../constants/store";
import {
  PAGE_NAME,
  STORE_KEYS
} from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import SelectWalletModal from "../Dialog/SelectWalletModal";
import MainMenu from "./MainMenu";
import NavLink from "./NavLink";
import UserMenu from "./UserMenu";

const HomeAppBar = ({ position = "relative", back }) => {
  const { account, onDisconnect } = useAutoConnect()
  const navigate = useNavigate();
  const [anchorMainMenu, setanchorMainMenu] = useState();
  const [anchorUserMenu, setanchorUserMenu] = useState();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );

  const [isOpen, setIsOpen] = useState(false);

  const handleLogin = () => {
    if (account && enterprises.registeredUser) {
      navigate(`/user/${account}`);
    } else {
      setIsOpen(true);
    }
  };

  const Close = () => {
    setIsOpen(false);
  };

  const handleMainMenu = (event) => {
    setanchorMainMenu(event.currentTarget);
  };

  const handleMainClose = () => {
    setanchorMainMenu(null);
  };

  const handleUserMenu = (event) => {
    setanchorUserMenu(event.currentTarget);
  };

  const handleUserClose = () => {
    setanchorUserMenu(null);
  };

  const handleNav = (typee) => () => {
    switch (typee) {
      case "login":
        handleLogin();
        break;
      case "home":
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.home;
          return prev;
        });
        navigate("/");
        break;

      case "forum":
        window.location.href = 'https://discourse.worldenterprise.io/';
        break;

      case "setting":
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.setting;
          return prev;
        });
        navigate("/setting");
        break;

      case "about":
        updateEnterprises((prev) => {
          prev.pageName = PAGE_NAME.id.about;
          return prev;
        });
        navigate("/about");
        break;

      case "dashboard":
        updateEnterprises((prev) => {
          const index = prev.enterprises?.findIndex(
            (enterprise) =>
              enterprise.info.wepID === prev.tempEnterprise.info.wepID
          );

          if (index >= 0) {
            prev.enterprises[index] = prev.tempEnterprise;
            return prev;
          }
        });
        navigate(`/user/${account}`);
        break;

      case "disconnect":
        onDisconnect()
        break;

      case "faq":
        navigate("/faq");
        break;

      default:
        break;
    }
    handleMainClose()
    handleUserClose()
  };

  const handleLogo = () => {
    updateEnterprises((prev) => {
      prev.pageName = PAGE_NAME.id.home;
      return prev;
    });
    navigate("/");
  };

  return (
    <>
      <Box
        component={"header"}
        sx={{
          position: "absolute",
          width: "100%",
          m: 0,
          maxWidth: "5000px!important",
          backgroundColor: "#E5E5E5",
          top: "0px",
        }}
      >
        <AppBar
          component="div"
          position={"static"}
          sx={{
            boxShadow: "none",
            px: 2,
            py: 2,
            backgroundColor: "transparent"
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              px: "0!important",
              alignItems: "center",
              backgroundColor: "transparent",
              justifyContent: "space-between",
              minHeight: "unset !important",
              height: '34px'
            }}
          >
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="small"
                edge="start"
                color="primary"
                aria-label="menu"
                sx={{ p: 0 }}
                onClick={handleMainMenu}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
              <MainMenu
                anchorEl={anchorMainMenu}
                handleClose={handleMainClose}
                handleNav={handleNav}
              />
            </Box>
            {!back && (
              <Typography
                variant="h1"
                component="div"
                sx={{ px: "0!important", fontSize: "18px", cursor: 'pointer', color: '#28282B' }}
                onClick={handleLogo}
              >
                World Enterprise
              </Typography>
            )}
            {back && (
              <IconButton
                sx={{
                  ml: "-10px"
                }}
                onClick={() => {
                  back();
                }}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {/* {!account && (
                <NavLink page="Login" onClick={handleNav("login")} />
              )}
              <NavLink page="Home" onClick={handleNav("home")} /> */}
              {account && enterprises.registeredUser && (
                <NavLink page="Dashboard" onClick={handleNav("dashboard")} />
              )}
              {/* {account && enterprises.registeredUser && (
                <NavLink page="Setting" onClick={handleNav("setting")} />
              )} */}
              <NavLink page="About" onClick={handleNav("about")} />
              <NavLink page="FAQ" onClick={handleNav("faq")} />
              {/* {account && enterprises.registeredUser && (
                <NavLink page="Disconnect" onClick={handleNav("disconnect")} />
              )} */}
            </Box>
            <Box>
              <Avatar
                onClick={handleUserMenu}
                sx={{
                  width: "32px",
                  height: "32px"
                }}
              >
                {
                  account && enterprises.registeredUser ? (
                    <>
                      {
                        enterprises.photo !== "" && enterprises.photo !== undefined ? (
                          <img alt="" src={enterprises.photo} style={{ width: "100%" }} />
                        ) : (
                          <AccountCircle fontSize="medium" />
                        )
                      }
                    </>
                  ) : (
                    <AccountCircle sx={{
                      width: "32px",
                      height: "32px",
                    }} />
                  )
                }
              </Avatar>
            </Box>
            <UserMenu
              anchorEl={anchorUserMenu}
              handleClose={handleUserClose}
              handleNav={handleNav}
            />
          </Toolbar>
        </AppBar>
      </Box>
      <SelectWalletModal isOpen={isOpen} closeModal={Close} />
    </>
  );
};

export default HomeAppBar;
