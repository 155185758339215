import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdminAdd, AdminsView } from "../components/Admins";
import { Confirm, Final } from "../components/Create";
import { EnterpriseCreate } from "../components/Enterprise";
import Header from "../components/Header/index";
import { ShareholderAdd, ShareholdersView } from "../components/Shareholders";
import { PAGE_NAME, STEP, STORE_KEYS } from "../constants/store/constant";
import store from "../constants/store";
import HelpIcon from '@mui/icons-material/Help';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  HomeAppBar,
  ModalHead,
} from "../components/AppBar";
import { Box } from "@mui/system";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Icon, IconButton, Typography } from "@mui/material";
import { ToolTip } from "../components/Dialog";
import { AddCircleOutline } from "@mui/icons-material";
import { useAutoConnect } from "../utils/AutoConnect";

const CreatePage = () => {
  const navigation = useNavigate();
  const { account } = useAutoConnect()
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );

  useEffect(() => {
    setStep(STEP.CREATE_ENTERPRISE_INPUT);
    updateEnterprises((prev) => {
      prev.pageName = PAGE_NAME.id.home;
      return prev;
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const backHandler = () => {
    switch (step) {
      case STEP.CREATE_ENTERPRISE_INPUT:
        setStep(navigation("/createEnterpriseSplash"));
      // eslint-disable-next-line no-fallthrough
      case STEP.CREATE_SHAREHOLDERS_VIEW:
        setStep(STEP.CREATE_ENTERPRISE_INPUT);
        break;
      case STEP.CREATE_SHAREHOLDER_ADD:
        setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
        break;
      case STEP.CREATE_SHAREHOLDER_EDIT:
        setStep(STEP.CREATE_SHAREHOLDER_ADD);
        break;
      case STEP.CREATE_ADMINS_VIEW:
        setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
        break;
      case STEP.CREATE_ADMIN_ADD:
        setStep(STEP.CREATE_ADMINS_VIEW);
        break;
      case STEP.CREATE_PROCESSING:
        setStep(STEP.CREATE_ADMIN_ADD);
        break;
      case STEP.CREATE_SUCCESSED:
        setStep(navigation("/createEnterpriseSplash"));
        break;
      /*case STEP.DASHBOARD_MEMBERS_ADMIN_EDIT:
        setStep(STEP.DASHBOARD_MEMBERS);
        break;*/
      default:
        setStep(STEP.CREATE_ENTERPRISE_INPUT);
        break;
    }
  };
  console.log('step === STEP.CREATE_ENTERPRISE_INPUT', (step === STEP.CREATE_ENTERPRISE_INPUT))

  return (
    <>
      <Header pageTitle="Create" />
      <HomeAppBar />
      <ModalHead
        title="Create Enterprise"
        theme="dark"
        customClose={() => navigation(`/user/${account}`)}
        color="#28282B"
      />
      <Box
        display={"flex"}
        justifyContent="space-between"
        backgroundColor="#f4f4f4"
        alignItems="center" px={3}
      >
        <KeyboardBackspaceIcon onClick={() => backHandler()} />
        <Box sx={{
          width: "100%",
          display: "flex",
          marginRight: "20px",
        }}>
          <Typography sx={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "25px",
            textAlign: "left",
            py: 3,
            mx: "auto",
            maxWidth: { xs: "250px", md: "unset" }
          }}>
            {step === STEP.CREATE_SHAREHOLDERS_VIEW && "Shareholders"}
            {step === STEP.CREATE_ENTERPRISE_INPUT && "Enterprise Details"}
            {step === STEP.CREATE_SHAREHOLDER_ADD && "Add Shareholder"}
            {step === STEP.CREATE_ADMINS_VIEW && "Admins"}
            {step === STEP.CREATE_ADMIN_ADD && "Add Admin"}
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 10,
          }}  
        >
          {step === STEP.CREATE_SHAREHOLDERS_VIEW && (
            <IconButton
              onClick={() => {
                setStep(STEP.CREATE_SHAREHOLDER_ADD)
              }}
            >
              <AddCircleIcon color='primary' sx={{ fontSize: 30 }}/>
            </IconButton>
          )}
          {step === STEP.CREATE_ADMINS_VIEW && (
            <ToolTip title={'Admins can send money and provide business updates'}>
              <IconButton
              >
                {step === STEP.CREATE_ADMINS_VIEW && 
                  <HelpIcon color='primary' sx={{ fontSize: 30 }}/>
                }
              </IconButton>
            </ToolTip>
          )}
        </Box>
      </Box>
      <div style={{ background: "#F4F4F4", minHeight: "calc(100vh - 185px)" }}>
        {step === STEP.CREATE_ENTERPRISE_INPUT && <EnterpriseCreate />}
        {step === STEP.CREATE_SHAREHOLDERS_VIEW && <ShareholdersView />}
        {(step === STEP.CREATE_SHAREHOLDER_ADD ||
          step === STEP.CREATE_SHAREHOLDER_EDIT) && <ShareholderAdd />}
        {step === STEP.CREATE_ADMINS_VIEW && <AdminsView />}
        {(step === STEP.CREATE_ADMIN_ADD ||
          step === STEP.CREATE_ADMIN_EDIT) && <AdminAdd />}
        {step === STEP.CREATE_PROCESSING && <Confirm />}
        {step === STEP.CREATE_SUCCESSED && <Final />}
      </div>
    </>
  );
};

export default CreatePage;
