import { Divider, ListItemButton, ListItemText } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteBusinessUpdate } from "../../apis";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import BusinessUpdateModal from "../Dialog/BusinessUpdateModal";

const BsUpdatesList = ({ isUserDash = false }) => {
  const location = useLocation();
  const { account } = useAutoConnect()
  const navigate = useNavigate();
  const state = location.state;
  const { wepID, weBack } = state;
  const [enterprises, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const businessUpdates = enterprises.tempEnterprise.businessUpdates;
  const admins = enterprises.tempEnterprise.admins;
  const [bsUpdateDlgOpen, setBsUpdateDlgOpen] = useState(false);
  const [bsUpdateIdx, setBsUpdateIdx] = useState(-1);

  const onClickItem = (idx) => {
    if (isUserDash) {
      navigate(`/wepID/businessUpdates`, { state: { wepID, weBack } });
      return;
    }
    setBsUpdateIdx(idx);
    setBsUpdateDlgOpen(true);
  };

  const editHandler = (id) => {
    navigate(`/wepID/businessUpdates`, { state: { wepID, weBack } });
    updateEnterprises((prev) => {
      prev.tempEnterprise.updateableBusinessUpdateId = id;
      return prev;
    });
    setStep(STEP.DASHBOARD_BSUPDATE_EDIT);
  }

  const deleteHandler = (id, idx) => {
    deleteBusinessUpdate(id).then(res => {
      updateEnterprises((prev) => {
        prev.tempEnterprise.businessUpdates.splice(idx, 1)
        return prev;
      });
    }).catch(error =>
      console.log(`Failed to delete business update ${id} ${error}`)
    )
  }

  return (
    <>
      {businessUpdates.map((businessUpdate, i) => {
        return (
          <React.Fragment key={i} style={{ height: "95px", backgroundColor: "white" }}>
            <ListItemButton
              key={i}
              component="div"
              sx={{
                px: 0,
                pt: 1,
                pb: businessUpdate.length - 1 === i ? 0 : 1,
                width: "100%",
              }}
              onClick={() => onClickItem(i)}
            >
              <ListItemText
                primary={businessUpdate.title}
                secondary={businessUpdate.content}
                primaryTypographyProps={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "15px",
                  lineHeight: "18px",
                  color: "#241F21",
                  textAlign: "left",
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#4B4749",
                  textAlign: "left",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
              <ListItemText
                secondary={new Date(parseInt(businessUpdate.created_at) * 10 ** 3).toLocaleString(
                  "en-us",
                  {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  }
                )}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "10px",
                  lineHeight: "12px",
                  textAlign: "right",
                  color: "#4B4749",
                }}
              />
            </ListItemButton>
            {businessUpdates.length !== 1 &&
              <Divider key={`divider${i}`} />
            }
          </React.Fragment>
        );
      })}
      <BusinessUpdateModal
        onClose={() => {
          setBsUpdateDlgOpen(false);
        }}
        open={bsUpdateDlgOpen}
        editHandler={editHandler}
        deleteHandler={deleteHandler}
        isAdmin={admins.filter(admin => admin.walletAddr === account).length > 0}
        idx={bsUpdateIdx}
        id={
          (businessUpdates[bsUpdateIdx] &&
            businessUpdates[bsUpdateIdx].id) ||
          ""
        }
        title={
          (businessUpdates[bsUpdateIdx] &&
            businessUpdates[bsUpdateIdx].title) ||
          ""
        }
        content={
          (businessUpdates[bsUpdateIdx] &&
            businessUpdates[bsUpdateIdx].content) ||
          ""
        }
        createdAt={
          (businessUpdates[bsUpdateIdx] &&
            businessUpdates[bsUpdateIdx].created_at) ||
          ""
        }
        updatedAt={
          (businessUpdates[bsUpdateIdx] &&
            businessUpdates[bsUpdateIdx].updated_at) ||
          ""
        }
      />
    </>
  );
};

export default BsUpdatesList;
