import { QrCode } from "@mui/icons-material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import Box from '@mui/material/Box';
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Web3 from "web3";
import { HomeAppBar, ModalHead } from "../../components/AppBar";
import {WELoadingButton} from "../../components/Button";
import { QRScanModal } from "../../components/Dialog";
import Header from "../../components/Header/index";
import { InputLabel, Select } from "../../components/Input";
import { useNotification } from "../../components/Notification/hook";
import ScrollToTop from "../../components/ScrollToTop";
import QRReceiveCode from "../../components/Transfer/QRReceiveCode";
import EnterpriseABI from "../../constants/abi/enterprise.json";
import store from "../../constants/store";
import {
  EmptyTransaction,
  STORE_KEYS,
  TXTYPE,
  TxCategories,
  TxTypes,
  isTxCategory,
  isTxType
} from "../../constants/store/constant";
import { useWeb3 } from "../../hook/web3";
import { useAutoConnect } from "../../utils/AutoConnect";
import { getEtherFromWei, convertMaticToUSD } from "../../utils/unit";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

const Send = () => {
  const { account } = useAutoConnect()
  const web3 = useWeb3();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;
  const { displaySuccess, displayError } = useNotification();

  const location = useLocation();
  const state = location.state;
  const { wepID, weBack, pTab } = state;

  const navigation = useNavigate();
  const theme = useTheme();

  const [toAddr, setToAddr] = useState("");
  const [amount, setAmount] = useState("");
  // price in USD (token to USD)
  const [price, setPrice] = useState(0);
  const [balance, setBalance] = useState(
    getEtherFromWei(enterprise?.numOfShare)
  );
  const [totalShare] = useState(enterprise?.info?.totalShare);
  const [moneyUnit, setMoneyUnit] = useState("MATIC");
  const [moneyLst] = useState(["MATIC"]);
  const [type, setType] = useState(state.moneyFlag);
  const [categori, setCategory] = useState(TxCategories[0]);
  const [note, setNote] = useState("");
  const [qrScanOpen, setQrScanOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleInputChange = (typee) => (evt) => {
    let value = evt.currentTarget.value;
    switch (typee) {
      case "walletAddr":
        setToAddr(value);
        break;
      case "amount":
        let regExp = /[0-9]+/;
        switch (type) {
          case TXTYPE.MONEY_IN:
          case TXTYPE.MONEY_OUT:
            regExp = /[0-9]*([.])?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?/;
            break;
          case TXTYPE.SHARE_OUT:
            regExp = /[0-9]+/;
            break;
          default:
            break;
        }

        if (regExp.exec(value)?.[0] === value || value === "") {
          if (/[0][0-9]+[.]?[0-9]*/.exec(value)?.[0] === value)
            value = value.slice(1, value.length);
          if (/[.][0-9]*/.exec(value)?.[0] === value) value = `0${value}`;

          setAmount(value);
        }

        break;
      case "note":
        setNote(value);
        break;
      default:
        break;
    }
  };

  const back = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const getBalance = async (contract, addr) => {
    if (contract === ZERO_ADDRESS) {
      try {
        return parseInt(await web3.eth.getBalance(addr));
      } catch (e) {
        return 0;
      }
    }
  };

  React.useEffect(() => {
    convertMaticToUSD(1).then((res) => {
      setPrice(res);
    })
  }, [])

  //Check if the TXTYPE is send or receive
  React.useEffect(() => {
    if (state.moneyFlag === "receive") {
      setType(TXTYPE.MONEY_IN);
    }
  }, [state.moneyFlag]);

  React.useEffect(() => {
    (async () => {
      if (type === "Share Transfer") {
        setBalance(getEtherFromWei(enterprise?.numOfShare));
      } else {
        const _balance = await getBalance(
          ZERO_ADDRESS,
          enterprise?.info?.address.toLowerCase()
        );
        setBalance(getEtherFromWei(_balance));
      }
    })();
  }, [type, web3]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    enterprise.admins.forEach((ele) => {
      if (ele.walletAddr.toUpperCase() === account.toUpperCase()) {
        setIsAdmin(true)
      }
    })
  }, [account, enterprise.admins])

  const handleSelectChange = (type) => async (evt) => {
    const value = evt.target.value;
    switch (type) {
      case "type":
        if (isTxType(value)) {
          setType(value);
        }
        break;
      case "moneyType":
        break;

      case "category":
        if (isTxCategory(value)) {
          setCategory(value);
        }
        break;

      default:
        break;
    }
  };

  const handleClickShowQR = () => {
    setQrScanOpen(true);
  };

  const closeQRScaner = () => {
    setQrScanOpen(false);
  };

  const qrHandler = (addr) => {
    setToAddr(addr);
  };

  const closeHandler = () => {
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const transferHandler = async () => {
    setIsSending(true)
    let asset = "Money"
    let category = categori;
    let notee = note;
    if (!Web3.utils.isAddress(toAddr)) {
      setIsSending(false)
      return displayError({
        message: "To address is not valid",
        timeout: 5000,
      });
    }


    if (!(parseFloat(amount) > 0)) {
      setIsSending(false)
      return displayError({
        message: "Amount should be greater than the zero",
        timeout: 5000,
      });
    }

    if (parseFloat(amount) > parseFloat(balance)) {
      setIsSending(false)
      return displayError({
        message: "Amount exceed your share token balance",
        timeout: 5000,
      });
    }
    if (type === TXTYPE.MONEY_OUT) {
      if (note === "") {
        setIsSending(false)
        return displayError({
          message: "Note is empty. You have to fill it",
          timeout: 5000,
        });
      }
    } else {
      asset = "TOKEN";
      category = "None";
      notee = "";
    }

    const enterpriseHandler = new web3.eth.Contract(
      EnterpriseABI,
      enterprise.info.address
    );

    switch (type) {
      case TXTYPE.MONEY_IN:
      case TXTYPE.MONEY_OUT:
        asset = "ETH";
        category = categori;
        notee = note;
        try {
          await enterpriseHandler.methods
            .withdrawAdmin(toAddr.toLowerCase(), Web3.utils.toWei(amount))
            .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });

          const _balance = await getBalance(
            ZERO_ADDRESS,
            enterprise?.info?.address
          );
          setBalance(getEtherFromWei(_balance));

          displaySuccess({
            message: "Sent token successfully",
            timeout: 5000,
          });
        } catch (e) {
          console.error("withdraw native token error", e);
          setIsSending(false)
          return displayError({
            message: "Error is occurred to send native token",
            timeout: 5000,
          });
        }
        break;
      case TXTYPE.SHARE_OUT: // share transfer handler
        try {
          await enterpriseHandler.methods
            .transfer(toAddr.toLowerCase(), Web3.utils.toWei(amount))
            .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
          displaySuccess({
            message: "Sent token successfully",
            timeout: 5000,
          });

        } catch (e) {
          console.error("transfer share token error", e);
          setIsSending(false)
          return displayError({
            message: "Error is occurred to transfer share token",
            timeout: 5000,
          });
        }
        asset = enterprise.info.tokenName;
        category = "None";
        notee = "";
        break;
      default:
        break;
    }


    const transaction = EmptyTransaction()
    transaction.id = Date.now()
    transaction.amount = amount
    transaction.asset = asset
    transaction.category = category
    transaction.created_at = Date.now()
    transaction.enterprise_address = enterprise.info.address
    transaction.from = account
    transaction.note = notee
    transaction.to = toAddr
    transaction.token = ""
    transaction.type = type

    updateEnterprises((prev) => {
      if (type === TXTYPE.SHARE_OUT) {
        const index = prev.enterprises?.findIndex(
          (enterprise) =>
            enterprise.info.address === prev.tempEnterprise.info.address
        );
        if (index >= 0) {
          prev.enterprises[index].numOfShare -= Web3.utils.toWei(amount);
          prev.enterprises[index].transactions = [
            ...prev.enterprises[index].transactions,
            transaction,
          ];
        }
        prev.tempEnterprise.numOfShare -= Web3.utils.toWei(amount);
      }
      prev.tempEnterprise.transactions = [
        ...prev.tempEnterprise.transactions,
        transaction,
      ];
      return prev;
    });
    navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
    setIsSending(false)
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={"Send"} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: "#E5E5E5", minHeight: "calc(100vh - 66px)" }}>
        <ModalHead
          title={type}
          close={() => back()}
          color='#ff6142'
          theme='dark'
        />
        <Box sx={{ px: 4, pt: 3 }}>
          <Box sx={{ mb: 2 }}>
            <InputLabel sx={{ mb: .75 }}>
              Type
            </InputLabel>
            <Select
              id="category"
              value={type}
              onChange={handleSelectChange("type")}
              IconComponent={KeyboardArrowDownIcon}
              sx={{
                px: 1,
                py: .75,
                fontSize: 14,
                width: '100%',
                fontWeight: 500,
                color: '#FFFFFF',
                bgcolor: '#28282B',
                borderRadius: '6px',
                '& svg': {
                  color: '#ffffff'
                }
              }}
            >
              {
                (() => {
                  let types = []
                  if (isAdmin) types = [...TxTypes]
                  else types = [TXTYPE.SHARE_OUT]
                  return types.map((txtype, key) => {
                    if (!isAdmin && txtype !== TXTYPE.SHARE_OUT) return (<></>);
                    else return (
                      <MenuItem key={key} value={txtype}>
                        {txtype}
                      </MenuItem>
                    );
                  })
                })()
              }
            </Select>
          </Box>
          {type === "Money Transfer" && (
            <Stack>
              <Box>
                <InputLabel shrink htmlFor="moneyType">
                  Money Type
                </InputLabel>
                <Select
                  id="moneyType"
                  value={moneyUnit}
                  onChange={() => {
                    handleSelectChange("moneyType");
                  }}
                  sx={{
                    mt: 1,
                    backgroundColor: "#3D61B0",
                    color: "#FFFFFF",
                    "& .MuiSvgIcon-root": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  {moneyLst.map((moneyType, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        value={moneyType}
                        onClick={() => setMoneyUnit(moneyType)}
                      >
                        {moneyType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
            </Stack>
          )}
          {
            (type === TXTYPE.MONEY_OUT || type === TXTYPE.SHARE_OUT) && (
              <Stack>
                <Box>
                  <InputLabel sx={{ mb: 1.75 }}>
                    Send to
                  </InputLabel>
                  <TextField
                    id="sendTo"
                    value={toAddr}
                    fullWidth
                    sx={{
                      mt: 1,
                      width: '100%',
                      borderWidth: 0,
                      overflow: 'hidden',
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      },
                      '& input': {
                        borderWidth: '0px !important',
                        fontSize: 20,
                        py: 1.75,
                      }
                    }}
                    onChange={handleInputChange("walletAddr")}
                    InputProps={{
                      endAdornment: <IconButton onClick={handleClickShowQR} edge="end">
                        <QrCode htmlColor="#3D61B0" />
                      </IconButton>
                    }}
                  />
                </Box>
                <Typography sx={{ textAlign: 'center', fontSize: 14, fontWeight: 500, color: '#28282B', mt: 2.6 }}>
                  {type === TXTYPE.MONEY_OUT ? "Treasury Balance" : "My Shares"}: {Number(balance - Number(amount)).toFixed(4)}{" "}
                  {type === TXTYPE.MONEY_OUT && (moneyUnit === "ETH" ? "Matic" : moneyUnit)}
                  {type === TXTYPE.SHARE_OUT && enterprise.info.tokenName}
                </Typography>
                <Box sx={{ mt: 2.6 }}>
                  <TextField
                    id="amount"
                    onChange={handleInputChange("amount")}
                    value={amount}
                    placeholder={type === "Share Transfer" ? "0" : "0.00"}
                    inputProps={{
                      inputMode: "numeric"
                    }}
                    fullWidth
                    sx={{
                      mt: 1,
                      width: '100%',
                      borderWidth: 0,
                      overflow: 'hidden',
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      },
                      '& input': {
                        borderWidth: '0px !important',
                        fontSize: 20,
                        py: 1.75,
                      }
                    }}
                  />
                </Box>
                <Stack alignItems='center' justifyContent='center' >
                  <CompareArrowsIcon sx={{ my: 1, transform: 'rotate(90deg)' }} />
                </Stack>
                <Box>
                  <TextField
                    id="amount1"
                    disabled
                    value={type === TXTYPE.MONEY_OUT ? (Number(amount) * price).toFixed(4) : (Number(amount) * Number(enterprise?.info?.price)).toFixed(4)}
                    inputProps={{
                      inputMode: "numeric"
                    }}
                    fullWidth
                    sx={{
                      mt: 1,
                      width: '100%',
                      borderWidth: 0,
                      overflow: 'hidden',
                      borderRadius: '6px',
                      '& fieldset': {
                        borderWidth: 0,
                      },
                      '& input': {
                        borderWidth: '0px !important',
                        fontSize: 20,
                        py: 1.75,
                      }
                    }}
                  />
                </Box>
                {type === TXTYPE.MONEY_OUT && (
                  <>
                    <Box sx={{ mt: 2.5 }}>
                      <InputLabel sx={{ mb: 1.75 }}>
                        Send to
                      </InputLabel>
                      <Select
                        id="category"
                        value={categori}
                        onChange={handleSelectChange("category")}
                        IconComponent={KeyboardArrowDownIcon}
                        sx={{
                          px: 1,
                          py: .75,
                          fontSize: 14,
                          width: '100%',
                          fontWeight: 500,
                          color: '#28282B',
                          bgcolor: '#ffffff',
                          borderWidth: '0px !important',
                          borderRadius: '6px',
                          '& svg': {
                            color: '#28282B'
                          },
                          '& fieldset': {
                            borderWidth: '0px !important',
                          }
                        }}
                      >
                        {TxCategories.map((cate, idx) => {
                          return (
                            <MenuItem key={idx} value={cate}>
                              {cate}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                    <Box sx={{ mt: 2.5 }}>
                      <InputLabel shrink htmlFor="sendTo">
                        Note
                      </InputLabel>
                      <TextField
                        value={note}
                        rows={4}
                        onChange={handleInputChange("note")}
                        fullWidth
                        multiline
                        sx={{
                          mt: 1,
                          width: '100%',
                          borderWidth: 0,
                          overflow: 'hidden',
                          borderRadius: '6px',
                          '& fieldset': {
                            borderWidth: 0,
                          },
                          '& textarea': {
                            borderWidth: '0px !important',
                            fontSize: 20,
                            py: 1.75,
                          }
                        }}
                      />
                    </Box>
                  </>
                )}
                {type === TXTYPE.SHARE_OUT && (
                  <Typography
                    sx={{
                      textAlign: 'center',
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#28282B',
                      mt: 2.6,
                      lineHeight: "25px",
                    }}
                  >
                    My Shares: {balance - Number(amount)}{" "}{enterprise.info.tokenName}
                    <br />

                    Total Enterprise Shares: {enterprise.info.totalShare}{enterprise.info.tokenName}
                  </Typography>
                )}
                <Box sx={{ mt: 6, mb: 5, textAlign:"center" }}>
                  <WELoadingButton
                     loading={isSending}
                     onClick={transferHandler}
                     width = {320}
                     rounded = "xl"
                     type = "primary"
                     loadingPosition="center"
                      loadingIndicator={
                        <CircularProgress color="default" size={20}/>
                      }
                  >
                    Transfer
                  </WELoadingButton>
                </Box>
              </Stack >
            )
          }
          {
            type === TXTYPE.MONEY_IN && (
              <QRReceiveCode />
            )
          }
        </Box >
      </Box >
      <QRScanModal
        open={qrScanOpen}
        onClose={closeQRScaner}
        qrHandler={qrHandler}
      />
    </>
  );
};

export default Send;