import {
  ArrowForward
  // Add,
} from "@mui/icons-material";
import {
  // Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminAdd, AdminsList } from "../../components/Admins";
import { HomeAppBar, ModalHead } from "../../components/AppBar";
import {
  BtnCancel,
  BtnOption,
  WEModal,
  Transition,
} from "../../components/Dialog";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import {
  ShareholderAdd,
  ShareholdersList,
} from "../../components/Shareholders";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";

const Members = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const { account } = useAutoConnect();
  const state = location.state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const enterprise = enterprises.tempEnterprise;
  const { wepID, weBack, pTab } = state;
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);

  const [category, setCategory] = useState("shareholders");
  const [newOpen, setNewOpen] = useState(false);
  const backHandler = () => {
    // const enterprise = enterprises.enterprises.find(
    //   (enterprise) => enterprise.info.wepID === wepID
    // );
    // if (enterprise && enterprise.joined) {
    //   navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
    // } else {
    //   updateEnterprises((prev) => {
    //     prev.pageName = PAGE_NAME.id.join;
    //     return prev;
    //   });
    //   // navigation(`/wepID/dashboard`, { state: { wepID } });
    //   navigation(`/start`);
    // }
    navigation(`/wepID/dashboard`, { state: { wepID, weBack, pTab } });
  };

  const isAdmin = useMemo(() => {
    const { admins } = enterprise;
    let isCurrentUserAdmin = false;

    admins.forEach(admin => {
      if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserAdmin = true;
      }
    });

    return isCurrentUserAdmin;
  }, [enterprise, account]);

  const switchHandler = () => {
    switch (category) {
      case "admins":
        setCategory("shareholders");
        break;
      case "shareholders":
        if (!isAdmin) break;
        setCategory("admins");
        break;
      default:
        break;
    }
  };
  const newOpenClose = () => {
    setNewOpen(false);
  };

  // const addHandler = () => {
  //   setNewOpen(true);
  // };
  const adminHandler = () => {
    setNewOpen(false);
    setStep(STEP.DASHBOARD_MEMBERS_ADMIN_EDIT);
  };

  const shareholderHandler = () => {
    setNewOpen(false);
    setStep(STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT);
  };

  useEffect(() => {
    setStep(STEP.DASHBOARD_MEMBERS);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={"Members"} />
      <HomeAppBar position="absolute" />
      <Box sx={{ bgcolor: "white", minHeight: "calc(100vh - 66px)" }}>
        <ModalHead
          title={enterprise.info.name}
          close={backHandler}
          theme="light"
          color="#FFDB0B"
        />
        {stepState === STEP.DASHBOARD_MEMBERS && (
          <>
            <Box sx={{ px: 4, py: 4.3 }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography sx={{ fontSize: '16px', fontWeight: "600", margin: "0 auto" }} >Members</Typography>
              </Stack>
            </Box>
            <Divider />
            <Container sx={{ display: "flex", position: "relative", py: 2 }}>
              <Grid
                component="h2"
                container
                onClick={switchHandler}
                sx={{
                  width: "100%",
                  lineHeight: "40px",
                  p: 0,
                  mr: 2,
                  my: 0,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "15px",
                  textAlign: "center",
                  borderRadius: "8px",
                  border: "1px solid #E3E8EB",
                  cursor: "pointer",
                }}
              >
                <Grid
                  item
                  xs={6}
                  component="div"
                  style={{
                    display: "inline-block",
                    borderRadius: "8px",
                    padding: "3px 0",
                    backgroundColor:
                      category === "shareholders" ? "#28282B" : "#ffffff",
                    color: category === "shareholders" ? "#ffffff" : "#28282B",
                  }}
                >
                  Shareholders
                </Grid>
                <Grid
                  item
                  xs={6}
                  component="div"
                  style={{
                    display: "inline-block",
                    borderRadius: "8px",
                    padding: "3px 0",
                    backgroundColor:
                      category === "admins" ? "#28282B" : "#ffffff",
                    color: category === "admins" ? "#ffffff" : "#28282B",
                    cursor: isAdmin ? "pointer" : "not-allowed"
                  }}
                >
                  Admins
                </Grid>
              </Grid>

            </Container>
            <Grid
              container
              component="main"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                px: 1,
                py: 0,
                m: 0,
                // mt: 9,
              }}
            >
              {category === "shareholders" && <ShareholdersList />}
              {category === "admins" && <AdminsList />}
            </Grid>
            <WEModal
              open={newOpen}
              onClose={newOpenClose}
              TransitionComponent={Transition}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth
              maxWidth="xl"
            >
              <DialogTitle id="alert-dialog-title">
                <span>Add Member</span>
              </DialogTitle>
              <Divider />
              <DialogContent>
                <BtnOption onClick={adminHandler}>
                  <span>Propose Admin</span>
                  <ArrowForward htmlColor="#BCC0C4" />
                </BtnOption>
                <BtnOption onClick={shareholderHandler}>
                  <span>Propose Shareholder</span>
                  <ArrowForward htmlColor="#BCC0C4" />
                </BtnOption>
              </DialogContent>
              <Divider />
              <DialogActions>
                <BtnCancel onClick={newOpenClose}>Cancel</BtnCancel>
              </DialogActions>
            </WEModal>
          </>
        )}
        {stepState === STEP.DASHBOARD_MEMBERS_SHAREHOLDER_EDIT && (
          <ShareholderAdd />
        )}
        {stepState === STEP.DASHBOARD_MEMBERS_ADMIN_EDIT && <AdminAdd />}
      </Box>
    </>
  );
};

export default Members;