import detectEthereumProvider from "@metamask/detect-provider";
import { Avatar, DialogContent, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import * as React from "react";
import { useAutoConnect } from "../../utils/AutoConnect";
import { ModalHead } from "../AppBar";
import {WEButton} from "../Button";
import BtnOption from "./BtnOption";
import {  WEModal } from "./DetailDialog";
import Transition from "./Transition";

import { ProviderManager, AvailableWriteProviders, EWalletId } from '@ankr.com/provider';


export default function SelectWalletModal({ isOpen, closeModal }) {
  const { connectors, onConnect, network } = useAutoConnect()
  const [detectedProvider, setDetectedProvider] = React.useState('detect')

  React.useEffect(() => {
    detectEthereumProvider({ silent: true })
      .then((provider) => {
        const pName = (!provider ? 'none'
          : (provider.isMetaMask ? 'metamask'
            : (provider.isCoinbaseWallet ? 'coinbase'
              : 'other')))
        setDetectedProvider(pName)
      })
      .catch((e) => {
        console.log('detectEthereumProvider error', e)
      })
  }, [])

  const connectWallet = async (e, connectorInfo) => {
    const { name, connector } = connectorInfo
    closeModal()

    const web3ModalTheme = {
      background: 'rgb(255,255,255)',
      main: 'rgb(137, 137, 137)',
      secondary: 'rgb(137, 137, 137)',
      border: 'rgba(195,195,195,0.14)',
      hover: 'rgb(239, 239, 239)',
    };

    
    if (detectedProvider !== name) {
      console.log(`connectWallet: please install ${name}`);
      return true; // no provider -> follow the link to install
    }

    const instance = new ProviderManager(web3ModalTheme);
    
    if(name === "metamask") {
      if(window?.ethereum) {
        window.ethereum.enable()
          .then(() => {
            connector
              .activate(network)
              .then(() => {
                onConnect({ ...connectorInfo, chainId: network.chainId })
              })
              .catch((err) => {
                console.log(`connectWallet activate ${name} for ${network.chainName} failed`, err)
              });
          })
          .catch((err) => {
            console.log(`connectWallet activate ${name} for ${network.chainName} failed`, err)
            return true; // no provider -> follow the link to install
          });
      } else {
        instance
          .getProvider(AvailableWriteProviders.ethCompatible, EWalletId.injected)
          .then(() => {
            connector
              .activate(network)
              .then(() => {
                onConnect({ ...connectorInfo, chainId: network.chainId })
              })
              .catch((err) => {
                console.log(`connectWallet activate ${name} for ${network.chainName} failed`, err)
              });
          })
          .catch((err) => {
            console.log(`connectWallet activate ${name} for ${network.chainName} failed`, err)
            return true; // no provider -> follow the link to install
          });
      }
      } else if(name === "coinbase") {
      instance
        .getProvider(AvailableWriteProviders.ethCompatible, EWalletId.coinbase)
        .then(() => {
          connector
            .activate(network)
            .then(() => {
              onConnect({ ...connectorInfo, chainId: network.chainId })
            })
            .catch((err) => {
              console.log(`connectWallet activate ${name} for ${network.chainName} failed`, err)
            });
        })
        .catch((err) => {
          console.log(`connectWallet activate ${name} for ${network.chainName} failed`, err)
          return true; // no provider -> follow the link to install
        });

    }

    e.preventDefault()
    return false
  }

  const schema = window.location.protocol;
  const host = window.location.host;

  return (
    <div>
      <WEModal
        open={isOpen}
        onClose={() => {
          closeModal();
        }}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle id="alert-dialog-title">
          <ModalHead
            title={"Select wallet"}
            customClose={() => closeModal()}
            color='transparent'
          />
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ py: "22px !important" }}>
          {!connectors?.coinbase ? '' :
            <a
              href={`https://go.cb-w.com/dapp?cb_url=${encodeURIComponent(schema)}%2F%2F${encodeURIComponent(host)}%2F`}
              onClick={(e) => connectWallet(e, connectors.coinbase)}
            >
              <BtnOption>
                <Avatar
                  variant="square"
                  src="/images/coinbase.svg"
                  sx={{ width: 35, height: 35, mr: 0, borderRadius: "8px" }}
                />
                Coinbase Wallet
                <img alt="arrowForward" src="/images/Arrow 3.svg"></img>
              </BtnOption>
            </a>
          }
          <Divider sx={{ my: "12px" }} />
          {!connectors?.metamask ? '' :
            <a
              href={`https://metamask.app.link/dapp/${encodeURIComponent(host)}/`}
              onClick={(e) => connectWallet(e, connectors.metamask)}
            >
              <BtnOption>
                <Avatar
                  variant="square"
                  src="/images/metamask.svg"
                  sx={{ width: 35, height: 35, mr: 0 }}
                />
                Metamask Wallet
                <img alt="arrowForward" src="/images/Arrow 3.svg"></img>
              </BtnOption>
            </a>
          }
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: "100%", padding: "0 18px" }}>
            <WEButton 
              type='primary' 
              variant="outlined" 
              width={280}
              rounded='xl'
              onClick={() => closeModal()}
            >
              Close
            </WEButton>
          </Box>
        </DialogActions>
      </WEModal>
    </div>
  );
}
