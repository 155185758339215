import { Button, Grid } from "@mui/material";
import React from "react";
import { upsertComment } from "../../apis";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import { AppBar } from "../AppBar";
import { Input, InputLabel } from "../Input";
import { useParams } from 'react-router-dom';

const CommentEdit = () => {
  const { id } = useParams()
  const [enterprises, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const { accountInfo } = useAutoConnect()
  const proposals = enterprises.tempEnterprise.proposals;

  const [comment, setComment] = React.useState({
    username: accountInfo.username,
    comment: "",
    created_at: Date.now() / 10 ** 3
  });

  // input handler
  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case "comment":
        setComment((prev) => {
          return {
            ...prev,
            comment: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const backHandler = () => {
    setStep(STEP.DASHBOARD_COMMENT);
  };

  const continueHandler = async () => {
    try {
      let res = await upsertComment(id, comment)
      if (res.data.success) {
        switch (step) {
          case STEP.DASHBOARD_COMMENT_ADD:
            updateEnterprises((prev) => {
              const index = proposals.findIndex(
                (proposal) =>
                  proposal.id === id
              );
              if ( prev.tempEnterprise.proposals[index].comments ) {
                prev.tempEnterprise.proposals[index].comments = [
                  comment,
                  ...prev.tempEnterprise.proposals[index].comments,
                ];
              } else {
                prev.tempEnterprise.proposals[index].comments = [
                  comment,
                ];
              }
              
              return prev;
            });
            break;
          
          default:
            break;
        }
        setStep(STEP.DASHBOARD_COMMENT);
      }
    } catch {
      console.log('Failed to Update or Add business update')
    }
  };

  return (
    <>
      <AppBar
        position="sticky"
        title={(() => {
          switch (step) {
            case STEP.DASHBOARD_COMMENT_ADD:
              return "Add a new comment";
            default:
              return "";
          }
        })()}
        back={backHandler}
        handle={() => {}}
        type="none"
        toolTipTitle={(() => {
          switch (step) {
            case STEP.DASHBOARD_COMMENT_ADD:
              return "Add a new comment";
            default:
              return "";
          }
        })()}
      />
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{
          px: 3,
          mt: 5,
        }}
      >
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="title">
            Username: {accountInfo.username}
          </InputLabel>
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="comment">
            Comment
          </InputLabel>
          <Input
            id="comment"
            value={comment.comment}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("comment")}
            multiline={true}
            rows={10}
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth sx={{ mt: 2, mb: 3 }} onClick={continueHandler}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CommentEdit;
