import { Container, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import OrderList from "./OrderList";

const OrderBook = ({ category = "BUY" }) => {
  // const navigation = useNavigate();
  // const location = useLocation();
  // const state = location.state;
  // const { wepID } = state;

  const [categori, setCategori] = useState("BUY");

  const switchHandler = () => {
    switch (categori) {
      case "BUY":
        setCategori("SELL");
        break;
      case "SELL":
        setCategori("BUY");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (category === "BUY" || category === "SELL") {
      setCategori(category)
    }
  }, [category])

  return (
    <>
      <Divider sx={{ mt: 3, mb: 1.75 }} />
      <Container sx={{ position: "relative", py: 2, px: 0 }}>
        <Grid
          component="h2"
          container
          onClick={switchHandler}
          sx={{
            width: "100%",
            lineHeight: "40px",
            py: 0,
            my: 0,
            mr: 2,
            ml: 0,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "15px",
            textAlign: "center",
            borderRadius: "6px",
            border: "1px solid #E3E8EB",
            bgcolor: "#DDDDDD",
            cursor: "pointer",
          }}
        >
          <Grid
            item
            xs={6}
            component="div"
            sx={{
              display: "inline-block",
              borderRadius: "6px",
              bgcolor: categori === "BUY" ? "#28282B" : "#DDDDDD",
              color: categori === "BUY" ? "#ffffff" : "#28282B",
            }}
          >
            Buy Orders
          </Grid>
          <Grid
            item
            xs={6}
            component="div"
            sx={{
              display: "inline-block",
              borderRadius: "6px",
              bgcolor: categori === "SELL" ? "#28282B" : "#DDDDDD",
              color: categori === "SELL" ? "#ffffff" : "#28282B",
            }}
          >
            Sell Orders
          </Grid>
        </Grid>
      </Container>
      <Divider sx={{ my: 2 }} />
      <Container sx={{ px: 0 }}>
        <OrderList type={categori} />
      </Container>
    </>
  );
};

export default OrderBook;
