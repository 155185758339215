import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import ScrollToTop from "../../components/ScrollToTop";
import { NewOrder } from "../../components/Trades";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";

const Trades = () => {
  const location = useLocation();
  const state = location.state;
  const { wepID, weBack } = state;

  const navigation = useNavigate();

  const [stepState] = store.useState(STORE_KEYS.id.step);

  React.useEffect(() => {
    if (stepState === STEP.INDEX) {
      navigation("/start");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ScrollToTop />
      <Header pageTitle={"Trades"} />
      {stepState === STEP.DASHBOARD_TRADE_ADD && <NewOrder wepID={wepID} weBack={weBack} />}
    </>
  );
};

export default Trades;
