import { Person, Star } from "@mui/icons-material";
import { Avatar, Button, Container, Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import store from "../../constants/store";
import { EmptyEnterprise, STORE_KEYS } from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import { CreateEnterpriseAppBar, HomeAppBar } from "../AppBar";
import { useTheme } from '@mui/material/styles';
import {WEButton} from "../Button";

const Final = () => {
  const theme = useTheme();
  const navigation = useNavigate();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );

  const [logoUrl, setLogoUrl] = useState("");
  const [name, setName] = useState("");
  const { account } = useAutoConnect();

  const continueHandler = () => {
    let enterprise = EmptyEnterprise();
    enterprise.info = enterprises.tempEnterprise.info;
    enterprise.shareholders = enterprises.tempEnterprise.shareholders;
    enterprise.admins = enterprises.tempEnterprise.admins;
    enterprise.numOfShare = enterprises.tempEnterprise.numOfShare;
    enterprise.mine = true;
    enterprise.isShareholder = true;
    enterprise.fetched = true;
    enterprise.joined = false;
    updateEnterprises((prev) => {
      prev.enterprises = [enterprise, ...prev.enterprises];
      prev.tempEnterprise = enterprise
      return prev;
    });

    // try {
    //   const _shareholders = enterprise.shareholders;
    //   let _isShareholder = false;
    //   let _shareholderAmount = 0;
    //   for (let i = 0; i < _shareholders.length; i++) {
    //     if (
    //       _shareholders[i].walletAddr.toUpperCase() === account.toUpperCase()
    //     ) {
    //       _isShareholder = true;
    //       _shareholderAmount = _shareholders[i].numOfShare;
    //     }
    //   }
    //   if (_isShareholder) {
    //     updateShares((prev) => {
    //       const res = [
    //         ...prev.data,
    //         {
    //           name: enterprise.info.name,
    //           img: enterprise.info.logo,
    //           price: enterprise.info.price,
    //           amount: _shareholderAmount,
    //           webID: 0,
    //         },
    //       ];
    //       prev.data = res;
    //     });
    //   }
    // } catch (e) {
    //   debugger;
    // }
    // navigation(`/user/${account}`);
    navigation(`/wepID/dashboard`, {
      state: { wepID: enterprise.info.address, weBack: `/user/${account}`, from: "final creation" },
    });

  };

  useEffect(() => {
    setLogoUrl(enterprises.tempEnterprise.info.logo);
    setName(enterprises.tempEnterprise.info.name);
  }, [setLogoUrl, setName, enterprises]);

  return (
    <>
      <Container
        sx={{
          // height: "100vh",
          padding: "0",
          px: 2
        }}
      >
        <Star
          style={{
            position: "absolute",
            color: "#FFDB0A",
            fontSize: "15px",
            left: "27.5%",
            top: "220px",
            animationName: "growing",
            animationDuration: "2s",
            animationIterationCount: "infinite",
          }}
        />
        <Star
          style={{
            position: "absolute",
            color: "#00C9F2",
            fontSize: "20px",
            transform: "rotate(45deg)",
            // opacity: "0.22",
            left: "18.6%",
            top: "270px",
            animationName: "growing",
            animationDuration: "6s",
            animationIterationCount: "infinite",
          }}
        />
        <Star
          style={{
            position: "absolute",
            color: "#FF6142",
            fontSize: "20px",
            transform: "rotate(45deg)",
            // opacity: "0.39",
            right: "26.6%",
            top: "290px",
            animationName: "growing",
            animationDuration: "3s",
            animationIterationCount: "infinite",
          }}
        />
        <Star
          style={{
            position: "absolute",
            color: "#00C9F2",
            fontSize: "12px",
            transform: "rotate(5deg)",
            right: "32.7%",
            top: "210px",
            animationName: "growing",
            animationDuration: "7s",
            animationIterationCount: "infinite",
          }}
        />
        <Star
          style={{
            position: "absolute",
            color: "#FFDB0A",
            fontSize: "25px",
            transform: "rotate(45deg)",
            // opacity: "0.19",
            right: "15.7%",
            top: "250px",
            animationName: "growing",
            animationDuration: "5s",
            animationIterationCount: "infinite",
          }}
        />
        <Grid
          container
          direction="row"
          component="main"
          spacing={2}
          sx={{
            px: 3,
            pt: 0,
            mt: 0
          }}
        >
          <Grid item container alignContent="center" justifyContent="center" sx={{pt: 5}}>
            <Avatar sx={{ width: "105px", height: "105px" }}>
              {logoUrl !== "" ? (
                <Box component="img" src={logoUrl} width="100%" />
              ) : (
                <Person
                  sx={{ width: "70px", height: "70px" }}
                  htmlColor="#4B4749"
                />
              )}
            </Avatar>
          </Grid>
          <Grid item container alignContent="center" justifyContent="center" sx={{pt: 5}}>
            <Typography variant="h4">Congratulations!</Typography>
          </Grid>
          <Grid item container alignContent="center" justifyContent="center">
            <Typography
              variant="body2"
              sx={{ width: "100%", textAlign: "center" }}
            >
              <i>World Enterprise</i> created for
            </Typography>
            <Typography
              variant="body2"
              sx={{ width: "100%", textAlign: "center" }}
            >
              {name}.
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            marginTop: 5,
            mx: "auto",
            width: {xs : "100%", sm: "370px"},
            pb: 4,
            textAlign: 'center'
          }}
        >
          <WEButton
            type='primary'
            rounded='xl'
            onClick={continueHandler}
          >
            Go to World Enterprise
          </WEButton>
        </Box>
        {/* <Grid sx={{
          position: "absolute",
          bottom: "0",
          width: "100%",
          px: 3,
          mb: 3,
        }}>
          <Button
            fullWidth
            onClick={continueHandler}
            sx={{
              position: "relative"
            }}
          >
            Go To World Enterprise
          </Button>
        </Grid> */}
      </Container>
    </>
  );
};

export default Final;
