import React, { useEffect } from "react";
import Header from "../../components/Header";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import store from "../../constants/store";
import { CommentEdit, CommentView } from "../../components/Comments";

const Comments = () => {
  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
 
  useEffect(() => {
      setStep(STEP.DASHBOARD_COMMENT);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header pageTitle="Comments" />
      {stepState === STEP.DASHBOARD_COMMENT && <CommentView />}
      {stepState === STEP.DASHBOARD_COMMENT_ADD && <CommentEdit />}
    </>
  );
};

export default Comments;
