import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Notification } from './components/Notification';
import { WorldCitizen } from './components/Open';
import store from './constants/store';
import { STORE_KEYS } from './constants/store/constant';
import { filterEnterprisesViewToMyShares, useEnterpriseServer } from './hook/enterprise';
import AboutPage from './pages/About';
import TotalChart from './pages/Chart';
import CreatePage from './pages/Create';
import CreateEnterpriseSplash from './pages/CreateEnterpriseSplash';
import FaqPage from './pages/Faq';
import IndexPage from './pages/index';
import JoinSplash from './pages/JoinSplash';
import ProfileInfoPage from './pages/ProfileInfo';
import SettingPage from './pages/Setting';
import AdminsPage from './pages/WepID/Admins';
import BusinessUpdates from './pages/WepID/BusinessUpdates';
import ChartPage from './pages/WepID/Chart';
import CommentsPage from './pages/WepID/Comments';
import DashboardPage from './pages/WepID/Dashboard';
import MembersPage from './pages/WepID/Members';
import ProposalsPage from './pages/WepID/Proposals';
import ReceivePage from './pages/WepID/Receive';
import SendPage from './pages/WepID/Send';
import CompanySettingPage from './pages/WepID/Setting';
import ShareHoldersPage from './pages/WepID/Shareholders';
import TradesPage from './pages/WepID/Trades';
import TransactionsPage from './pages/WepID/Transactions';
import { ProtectedRoute, useAutoConnect } from './utils/AutoConnect';

const StartPage = lazy(() => import('./pages/Start'));
const UserDashboardPage = lazy(() => import('./pages/UserDashboard'));
const UserProfilePage = lazy(() => import('./pages/profile/UserProfilePage'));

function App() {
  const { account } = useAutoConnect();
  useEnterpriseServer();
  const [notification] = store.useState(STORE_KEYS.id.notification);

  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const [, , updateShares] = store.useState(STORE_KEYS.id.shares);

  useEffect(() => {
    if (!enterprises.isLoading) {
      updateShares((prev) => {
        prev.isLoading = true
      })
      const shares = filterEnterprisesViewToMyShares(account, enterprises.enterprises)
      updateShares((prev) => {
        prev.isLoading = false
        prev.data = shares
      })
    }
  }, [enterprises.enterprises]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader color="#FFDB0A" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route
            path="/start"
            element={
              <ProtectedRoute>
                <StartPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/rotate"
            element={
              <ProtectedRoute>
                <WorldCitizen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/setting"
            element={
              <ProtectedRoute>
                <SettingPage />
              </ProtectedRoute>
            }
          />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route
            path="/create"
            element={
              <ProtectedRoute>
                <CreatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/createEnterpriseSplash"
            element={
              <ProtectedRoute>
                <CreateEnterpriseSplash />
              </ProtectedRoute>
            }
          />
          <Route path="/joinSplash" element={<JoinSplash />} />
          <Route
            path="/profileInfo"
            element={
              <ProtectedRoute>
                <ProfileInfoPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/:walletAddress"
            element={
              <ProtectedRoute>
                <UserDashboardPage />
              </ProtectedRoute>
            }
          />
          <Route path="/profile" >
            <Route index element={<UserProfilePage />} />
            <Route path=":walletAddress" element={
              <UserProfilePage />
            } />
          </Route>
          <Route
            path="/chart"
            element={
              <ProtectedRoute>
                <TotalChart />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/admins"
            element={
              <ProtectedRoute>
                <AdminsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/chart"
            element={
              <ProtectedRoute>
                <ChartPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/members"
            element={
              <ProtectedRoute>
                <MembersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/proposals"
            element={
              <ProtectedRoute>
                <ProposalsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/proposals/:id"
            element={
              <ProtectedRoute>
                <CommentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/receive"
            element={
              <ProtectedRoute>
                <ReceivePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/send"
            element={
              <ProtectedRoute>
                <SendPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/setting"
            element={
              <ProtectedRoute>
                <CompanySettingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/sharedholders"
            element={
              <ProtectedRoute>
                <ShareHoldersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/trades"
            element={
              <ProtectedRoute>
                <TradesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/transactions"
            element={
              <ProtectedRoute>
                <TransactionsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/wepID/businessUpdates"
            element={
              <ProtectedRoute>
                <BusinessUpdates />
              </ProtectedRoute>
            }
          />
        </Routes>
        {notification.message != 'Logged in!' ? <Notification /> : null}
      </Suspense>
    </>
  );
}

export default App;
