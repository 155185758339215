import {
  Menu,
  MenuItem
} from "@mui/material";
import React from "react";
import store from "../../constants/store";
import {
  STORE_KEYS
} from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";

const UserMenu = ({anchorEl, handleClose, handleNav}) => {
  const { account } = useAutoConnect()
  const [enterprises, ,] = store.useState(
    STORE_KEYS.id.enterprises
  );
  
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {!account && (
        <MenuItem onClick={handleNav("login")}>Login</MenuItem>
      )}
      {account && enterprises.registeredUser && (
        <MenuItem onClick={handleNav("dashboard")}>
          Dashboard
        </MenuItem>
      )}
      {account && enterprises.registeredUser && (
        <MenuItem onClick={handleNav("setting")}>Setting</MenuItem>
      )}
      {account && enterprises.registeredUser && (
        <MenuItem onClick={handleNav("disconnect")}>
          Disconnect
        </MenuItem>
      )}
    </Menu>
  )
}

export default UserMenu