import {
  DialogContent,
  DialogTitle,
  Box,
  CircularProgress
} from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import EnterpriseABI from "../../constants/abi/enterprise.json";
import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";
import { useWeb3 } from "../../hook/web3";
import { getAddress } from "../../utils/address";
import { simpleErrorMessage } from "../../utils/errorMessage";
import { ERRORS } from "../../utils/errors";
import { shortAddress } from "../../utils/shortAddress";
import { ModalHead } from "../AppBar";
import { CustomButton } from "../Button";
import { useNotification } from "../Notification/hook";
import { WEModal } from "./DetailDialog";
import Info from "./Info";
import InfoAddr from "./InfoAddr";
import Transition from "./Transition";
import { WEButton, WELoadingButton} from "../Button";
import { useAutoConnect } from "../../utils/AutoConnect";

const OrderDetailModal = ({ open, onClose, order }) => {
  const web3 = useWeb3();
  const { account } = useAutoConnect()
  const { displaySuccess, displayError } = useNotification();

  const [enterprises, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  const [orderDetail, setOrder] = useState({
    id: -1,
    type: "BUY",
    amount: 0,
    price: 0,
    date: new Date(),
    owner_address: "",
    taker: "",
  });

  const [loadingStatus, setLoadingStatus] = useState("");

  useEffect(() => {
    if (order !== undefined) {
      setOrder(order);
    }
  }, [order]);

  const acceptHandler = async () => {
    if (orderDetail.status !== "ACTIVE") {
      return displayError({
        message: ERRORS.ORDER_INACTIVE,
        timeout: 5000,
      });
    }

    const enterpriseHandler = new web3.eth.Contract(
      EnterpriseABI,
      getAddress(orderDetail.enterprise_address)
    );

    let nativePrice =
      parseFloat(orderDetail.amount) * parseFloat(orderDetail.price);
    nativePrice = Web3.utils.toWei(nativePrice.toString());

    const _amount = Web3.utils.toWei(orderDetail.amount.toString());

    try {
      if (orderDetail.type === "BUY") {
        if (orderDetail.amount > enterprise.numOfShare) {
          return displayError({
            message: ERRORS.SHARE_NOT_ENOUGH,
            reason: `${orderDetail.amount} is needed, but you have ${parseFloat(enterprise.numOfShare)}.`,
            timeout: 5000,
          });
        }

        setLoadingStatus("ACCEPT");
        await enterpriseHandler.methods
          .approve(getAddress(orderDetail.enterprise_address), _amount)
          .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });

        await enterpriseHandler.methods
          .closeOrder(orderDetail.order_id)
          .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
        setLoadingStatus("");
        displaySuccess({ message: "Trade completed", timeout: 5000 });

        updateEnterprises((prev) => {
          prev.tempEnterprise.numOfShare -= orderDetail.amount
          return prev;
        });
      } else {
        let _balance = await web3.eth.getBalance(account);
        if (parseFloat(nativePrice) > parseFloat(_balance)) {
          return displayError({
            message: ERRORS.NATIVE_TOKEN_NOT_ENOUGH,
            reason: `${parseFloat(nativePrice)} is needed, but you have ${parseFloat(_balance)}.`,
            timeout: 5000,
          });
        }
        setLoadingStatus("ACCEPT");
        await enterpriseHandler.methods
          .closeOrder(orderDetail.order_id)
          .send({ from: account, value: nativePrice, gasLimit: web3.eth.getBlock("latest").gasLimit });
        setLoadingStatus("");
        displaySuccess({ message: "Trade completed", timeout: 5000 });

        updateEnterprises((prev) => {
          prev.tempEnterprise.numOfShare = prev.tempEnterprise.numOfShare + parseInt(Web3.utils.toWei(orderDetail.amount.toString()))
          return prev;
        });
      }
    } catch (e) {
      setLoadingStatus("");
      console.log(e);
      return displayError({
        message: ERRORS.TRADE,
        timeout: 5000,
      });
    }

    updateEnterprises((prev) => {
      prev.tempEnterprise.proposalFetched = false;
      prev.tempEnterprise.orders = prev.tempEnterprise.orders.filter(
        (ele) => orderDetail.id !== ele.id
      );
      return prev;
    });
  };

  const cancelHandler = async () => {
    if (orderDetail.status !== "ACTIVE") {
      return displayError({
        message: ERRORS.ORDER_INACTIVE,
        timeout: 5000,
      });
    }

    const enterpriseHandler = new web3.eth.Contract(
      EnterpriseABI,
      getAddress(orderDetail.enterprise_address)
    );

    setLoadingStatus("CANCEL");
    try {
      await enterpriseHandler.methods
        .cancelOrder(orderDetail.order_id)
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });

      displaySuccess({ message: "Order is cancelled", timeout: 5000 });
    } catch (e) {
      setLoadingStatus("");
      displayError({
        message: ERRORS.CANCEL,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
  };

  return (
    <WEModal
      open={open}
      // onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle sx={{ padding: "0 !important" }} id="alert-dialog-title">
        <ModalHead
          title={"Order Detail"}
          customClose={() => onClose()}
          color='#FF6142'
          theme='dark'
        />
      </DialogTitle>
      <DialogContent sx={{ p: "0px 16px !important" }}>
        <InfoAddr
          label="Maker"
          value={shortAddress(getAddress(orderDetail.owner_address))}
        />
        <Info label="Shares" value={`${orderDetail.amount}`} />
        <Info label="Price" value={`${orderDetail.price} Matic`} />
        <Info
          label="Total"
          value={`${orderDetail.price * orderDetail.amount} Matic`}
        />
        <Stack sx={{ pt: "30px", textAlign : "center" }} direction="column" gap={2}>
          <Box sx ={{width:"100%", textAlign:"center"}}>
          <WELoadingButton
            type="primary"
            width = {320}
            rounded = "xl"
            loadingPosition="center"
            loading = {loadingStatus === "ACCEPT"}
            loadingIndicator={
              <CircularProgress color="default" size={20}/>
            }
            onClick={async () => {
              await acceptHandler();
              onClose();
            }}
          >
            Accept
          </WELoadingButton>
          </Box>
          <Box sx ={{width:"100%", textAlign:"center"}}>
          <WEButton
            type="primary"
            variant = "outlined"
            width = {320}
            rounded = "xl"
            onClick={async () => {
              await acceptHandler();
              onClose();
            }}
          >
            Cancel
          </WEButton>
          </Box>
          {/* <BtnOK
            onClick={async () => {
              await acceptHandler();
              onClose();
            }}
            sx={{ width: "100%", borderRadius: "24px", bgcolor: "#28282B" }}
            disable={(
              orderDetail.status !== "ACTIVE" || loadingStatus !== ""
            ).toString()}
          >
            {loadingStatus === "ACCEPT" ? (
              <CircularProgress size={20} />
            ) : (
              "Accept"
            )}
          </BtnOK>
          <BtnCancel
            onClick={async () => {
              await cancelHandler();
              onClose();
            }}
            disable={(
              orderDetail.status !== "ACTIVE" || loadingStatus !== ""
            ).toString()}
            sx={{ width: "100%", borderRadius: "24px" }}
          >
            {loadingStatus === "CANCEL" ? (
              <CircularProgress size={20} color="primary" />
            ) : (
              "Cancel"
            )}
          </BtnCancel> */}
        </Stack>
      </DialogContent>

    </WEModal>
  );
};

export default OrderDetailModal;
