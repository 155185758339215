import { Close } from "@mui/icons-material";
import { Button, DialogContent, DialogTitle, Divider, IconButton, Box } from "@mui/material";
import React from "react";
import DetailDialog, { WEModal } from "./DetailDialog";
import Info from "./Info";
import { Input } from "../Input";
import Transition from "./Transition";
import { ModalHead } from "../AppBar";


const BusinessUpdateModal = ({
  idx,
  id,
  isAdmin,
  open,
  onClose, 
  editHandler,
  deleteHandler,
  title,
  content,
  createdAt,
  updatedAt,
}) => {
  return (  
    <WEModal
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">
        {/*<span>{"Business Update"}</span>
        <IconButton
          size="small"
          sx={{ position: "absolute", right: "12px" }}
          onClick={() => onClose()}
        >
          <Close htmlColor="#99A7C7" />
        </IconButton>
  */}
        <ModalHead title={"Business Update"} color={"#FFDB0B"} customClose={onClose}/>
      </DialogTitle>
      <DialogContent >
          <Info label="Title" value={title} textAlign="right" multiline={false} />
          <Info
            sx={{display:"flex"}}
            label="Content"
            value={content}
            textAlign="left"
            multiline={true}
          />
        {
          isAdmin &&
            <Button 
              sx={{float: 'right', width: '120px', mr: "10px"}}
              onClick={() => { 
                deleteHandler(id, idx)
                onClose()
              }}
            >
              Delete
            </Button>
          
        }
       {
         isAdmin &&
          <Button
            sx={{float: 'right', width: '120px', mr: "10px"}}
            onClick={() => editHandler(idx)}
          >
            Edit
          </Button>
         
       }
      </DialogContent>
      <Divider />
    </WEModal>
  );
};

export default BusinessUpdateModal;
