import { List } from "@mui/material";
import React, { useEffect, useState } from "react";
import store from "../../constants/store";
import { STORE_KEYS } from "../../constants/store/constant";
import { OrderDetailModal } from "../Dialog";
import Item from "./Item";

const OrderList = ({ type }) => {
  // const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const orders = enterprises.tempEnterprise.orders;
  const [idx, setIdx] = useState(-1);
  const [dlgOpened, setDlgOpened] = useState(false);

  useEffect(() => {
  }, [orders]);

  const onClickItem = (id) => {
    setDlgOpened(true);
    setIdx(id);
  };
  // const replaceHandler = (idx) => () => {
  //   updateEnterprises((prev) => {
  //     prev.toEditAdmin = idx;
  //     return prev;
  //   });
  //   switch (stepState) {
  //     case STEP.CREATE_ADMINS_VIEW:
  //       setStep(STEP.CREATE_ADMIN_EDIT);
  //       break;

  //     case STEP.DASHBOARD_MEMBERS:
  //       setStep(STEP.DASHBOARD_MEMBERS_ADMIN_EDIT);
  //       break;

  //     default:
  //       setStep(STEP.ADMIN_EDIT);
  //       break;
  //   }
  //   setDlgOpened(false);
  // };

  // const removeHandler = (idx) => () => {
  //   switch (stepState) {
  //     case STEP.CREATE_ADMINS_VIEW:
  //       dispatch(removeAdminState(idx))
  //       break;

  //     default:
  //       dispatch(removeAdminState(idx))
  //       break;
  //   }
  //   setDlgOpened(false)
  // }

  return (
    <>
      <List dense={true} sx={{ width: "100%", pt: 2, px: 0 }}>
        {orders
          .filter((order) => order.type === type && order.status === "ACTIVE")
          .sort((a, b) =>
            type === "BUY"
              ? a.price >= b.price
                ? -1
                : 1
              : a.price <= b.price
                ? -1
                : 1
          )
          .map((order, idx) => {
            return (
              <Item
                key={idx}
                status={order.status}
                price={order.price}
                type={order.type}
                amount={order.amount}
                onClick={() => {
                  onClickItem(order.id);
                }}
              />
            );
          })}
      </List>
      <OrderDetailModal
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        order={orders.find((order) => {
          return order.id === idx;
        })}
      />
    </>
  );
};

export default OrderList;
