import React from "react";

import {
  Divider,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const Item = ({ price, type, amount, onClick }) => {
  return (
    <>
      <ListItemButton
        component="div"
        sx={{
          px: 0,
          pt: '5px',
          pb: '11px'
        }}
        onClick={onClick}
      >
        <ListItemText
          primary={`${price} Matic per share`}
          secondary={`${(() => {
            switch (type) {
              case "SELL":
                return "Sell";
              case "BUY":
                return "Buy";
              default:
                return "";
            }
          })()} Order`}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            color: "#241F21",
            textAlign: "left",
          }}
          secondaryTypographyProps={{
            mt: 1,
            mb: 0,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "15px",
            color: "#4B4749",
            textAlign: "left",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
        <ListItemText
          primary={`${amount} shares`}
          secondary={`${amount * price} Matic`}
          primaryTypographyProps={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "15px",
            lineHeight: "18px",
            textAlign: "right",
            color: "#4B4749",
          }}
          secondaryTypographyProps={{
            mt: 1,
            mb: 0,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "15px",
            textAlign: "right",
            color: "#4B4749",
          }}
          sx={{
            display: "block",
            width: "100%",
          }}
        />
      </ListItemButton>
      <Divider />
    </>
  );
};

export default Item;
