import { Box, Button, CircularProgress, Grid } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { upsertBusinessUpdate } from "../../apis";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useAutoConnect } from "../../utils/AutoConnect";
import { AppBar, ModalHead } from "../AppBar";
import HomeAppBar from "../AppBar/HomeAppBar";
import { Input, InputLabel } from "../Input";
import { WEButton,WELoadingButton} from "../Button";


const BsUpdateEdit = () => {
  const [enterprises, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const [isAdding, setIsAdding] = React.useState(false)
  const { account } = useAutoConnect()
  const enterprise = enterprises.tempEnterprise;
  const eBsUpdate = enterprise.businessUpdates[enterprise.updateableBusinessUpdateId]

  const [bsUpdate, setBsUpdate] = React.useState({
    id: eBsUpdate ? eBsUpdate.id : uuidv4(),
    title: eBsUpdate ? eBsUpdate.title : "",
    content: eBsUpdate ? eBsUpdate.content : "",
    enterprise_address: enterprise.info.address,
    creator: account,
    created_at: eBsUpdate ? eBsUpdate.created_at : Date.now() / 10 ** 3,
    updated_at: Date.now() / 10 ** 3
  });

  // input handler
  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case "title":
        setBsUpdate((prev) => {
          return {
            ...prev,
            title: value,
          };
        });
        break;
      case "content":
        setBsUpdate((prev) => {
          return {
            ...prev,
            content: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const closeHandler = () => {
    switch (step) {
      case STEP.DASHBOARD_BSUPDATE_EDIT:
      case STEP.DASHBOARD_BSUPDATE_ADD:

        setStep(STEP.DASHBOARD_BSUPDATE);
        break;

      default:
        break;
    }
  };

  const backHandler = () => {
    setStep(STEP.DASHBOARD_BSUPDATE);
  };

  const continueHandler = async () => {
    setIsAdding(true);
    try {
      let res = await upsertBusinessUpdate(bsUpdate)
      if (res.data.success) {
        switch (step) {
          case STEP.DASHBOARD_BSUPDATE_ADD:
            updateEnterprises((prev) => {
              prev.tempEnterprise.businessUpdates = [
                bsUpdate,
                ...prev.tempEnterprise.businessUpdates,
              ];
              return prev;
            });
            break;
          case STEP.DASHBOARD_BSUPDATE_EDIT:
            updateEnterprises((prev) => {
              const index = prev.tempEnterprise.businessUpdates.findIndex(
                (bs) => bs.id === bsUpdate.id
              );
              prev.tempEnterprise.businessUpdates[index] = bsUpdate
              prev.tempEnterprise.updateableBusinessUpdateId = -1
              return prev;
            });
            break;
          default:
            break;
        }
        setStep(STEP.DASHBOARD_BSUPDATE);
      }
    } catch {
      console.log('Failed to Update or Add business update')
    }
    setIsAdding(false);
  };

  return (
    <>
      {/* <HomeAppBar />
      <ModalHead title={enterprises.tempEnterprise.info.name} color={"#FFDB0B"} close={closeHandler} /> */}
      <AppBar
        position="sticky"
        title={(() => {
          switch (step) {
            case STEP.DASHBOARD_BSUPDATE_ADD:
              return "Add a new business update";
            case STEP.DASHBOARD_BSUPDATE_EDIT:
              return "Edit a business update";
            default:
              return "";
          }
        })()}
        back={backHandler}
        handle={() => { }}
        type="none"
        toolTipTitle={(() => {
          switch (step) {
            case STEP.DASHBOARD_BSUPDATE_ADD:
              return "Add a new business update";
            case STEP.DASHBOARD_BSUPDATE_EDIT:
              return "Edit a business update";
            default:
              return "";
          }
        })()}
      />
      <Grid
        container
        component="main"
        spacing={2}
        direction="row"
        sx={{
          px: 3,
          mt: 5,
        }}
      >
        <Grid item xs={12}>
          <InputLabel shrink htmlFor="title">
            Title
          </InputLabel>
          <Input
            id="title"
            value={bsUpdate.title}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("title")}
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <InputLabel shrink htmlFor="content">
            Content
          </InputLabel>
          <Input
            id="content"
            value={bsUpdate.content}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("content")}
            multiline={true}
            rows={10}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              mx: "auto",
              mt: 2,
              mb: 3,
              width: "90%",
              textAlign:"center"
            }}
          >
            <WELoadingButton
              loading={isAdding}
              onClick={continueHandler}
              type = "primary"
              width = {320}
              size="medium"
              rounded="xl"
              loadingPosition="center"
              loadingIndicator={
                <CircularProgress color="default" size={20}/>
              }
            >
              Submit
            </WELoadingButton>
          </Box>

        </Grid>
      </Grid>
    </>
  );
};

export default BsUpdateEdit;
