import { Divider, Grid, Unstable_Grid2 as Grid2, ListItemText } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";


const CommentList = ({ comments = [] }) => {
  const [cmts, setCmts] = React.useState([])
  
  useEffect(() => {
    let tmp = [...comments]
    setCmts(tmp.sort((a, b) => parseInt(b.created_at) - parseInt(a.created_at)))
  }, [comments])

  return (
    <Grid2>
      <Container sx={{ m: 0, p: 0, height: '200px' ,maxHeight: "300px", overflow: "auto" }}>
        <>
        {cmts?.map((comment, i) => {
          return (
            <React.Fragment key={i}>
              <Grid
                key={i}
                sx={{
                  px: 0,
                  my: 0,
                  display: 'flex'
                }}
              >
                <ListItemText
                  primary={comment.username}
                  secondary={comment.comment}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "15px",
                    lineHeight: "18px",
                    color: "#241F21",
                    textAlign: "left",
                  }}
                  secondaryTypographyProps={{
                    mt: 1,
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#4B4749",
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  sx={{
                    display: "block",
                    width: "100%",
                  }}
                />
                <ListItemText
                  primary={new Date(parseInt(comment.created_at) * 10 ** 3).toLocaleString(
                    "en-us",
                    {
                      day: "numeric",
                      month: "short",
                      year: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                    }
                  )}
                  primaryTypographyProps={{
                    fontFamily: "Montserrat",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "17px",
                    textAlign: "right",
                    color: "#4B4749",
                  }}
                  sx={{
                    display: "block",
                    width: "100px",
                  }}
                />
              </Grid>
              {comments.length - 1 !== i && (
                <Divider key={`divider${i}`} />
              )}
            </React.Fragment>
          );
        })}
        </>
      </Container>
    </Grid2>

  );
};

export default CommentList;
