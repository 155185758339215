import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import store from '../../constants/store';
import { STORE_KEYS } from '../../constants/store/constant';
import { useAutoConnect } from '../../utils/AutoConnect';
import { JoinProcess } from '../Diagram';
import SelectWalletModal from '../Dialog/SelectWalletModal';
import Footer from '../Footer';
import SideItem from './SideItem';
import { WEButton } from '../Button';

const landingTheme = {
  primary: '#FFDB0A',
  secondary: '#3D61B0',
  black: '#0D141E',
  fontSize: { xs: '14px', md: '18px' },
  lineHeight: { xs: '17px', md: '180.5%' },
};

const landingHeading = {
  fontSize: { xs: '28px', md: '44px' },
  lineHeight: { xs: '32px', md: '50px' },
  fontWeight: { xs: '600', md: '600' },
};
const landingText = {
  fontSize: { xs: '14px', md: '16px' },
  lineHeight: { xs: '20px', md: '24px' },
};
const landingButton = {
  fontSize: { xs: '14px', md: '16px' },
  fontWeight: { xs: '600', md: '600' },
};

const slideSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
};

const Landing = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { account } = useAutoConnect();
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);

  const connectHandler = () => {
    if (account && enterprises.registeredUser) {
      navigate(`/user/${account}`);
    } else {
      setIsOpen(true);
    }
  };

  const Close = () => {
    setIsOpen(false);
  };

  return (
    <Box
      sx={{
        bgcolor: landingTheme.primary,
        width: 1,
        pt: '50px',
      }}
    >
      <Box
        sx={{
          backgroundImage: { xs: 'none', md: 'url(/images/earth.svg)' },
          backgroundSize: '50% 100%',
          backgroundPosition: 'right top',
          backgroundRepeat: 'no-repeat',
          mt: 10,
        }}
      >
        <Container
          sx={{
            textAlign: { xs: 'center' },
            py: { xs: '40px', md: '80px' },
          }}
        >
          <Box>
            <Box
              component="img"
              src="/images/World_Enterprise_TwoLines.svg"
              alt=""
              sx={{
                mb: { xs: "35px", md: "45px" },
                mx: { xs: "auto" },
                maxWidth: { xs: "90%", md: "80%" },
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: { xs: '18px', md: '24px' },
              lineHeight: { xs: '32px', md: '36px' },
              fontWeight: '600',
              color: landingTheme.black,
              mx: 'auto',
              mb: { xs: '20px', md: '40px' },
              width: { xs: 'auto', md: '605px' },
              maxWidth: '800px',
            }}
          >
            <i>World Enterprise</i> is a platform for entrepreneurs and
            companies to launch and manage enterprises on the blockchain.
          </Typography>
          <WEButton type='primary' onClick={connectHandler} width={200} rounded='xl'>Get Started</WEButton>
        </Container>
        <Box
          component="img"
          src="/images/earth.svg"
          alt=""
          sx={{
            display: { xs: 'block', md: 'none' },
            width: '95%',
            ml: '5%',
          }}
        />
      </Box>

      {/* what is world enterprises? */}
      <Box
        sx={{
          position: 'relative',
          pt: { xs: '45px', md: '137px' },
          background: 'white',
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Box
          component="img"
          src="/images/home_section2_top.svg"
          alt=""
          sx={{
            position: 'absolute',
            top: { xs: '159px', md: '84px' },
            left: { xs: '80%', md: '50%' },
            width: { xs: '28px', md: 'auto' },
          }}
        />
        <Box
          component="img"
          src="/images/home_section2_right.svg"
          alt=""
          sx={{
            position: 'absolute',
            right: '0',
            top: { xs: '386px', md: '172px' },
            height: { xs: '54px', md: 'auto' },
          }}
        />
        <Box
          component="img"
          src="/images/home_section2_bottom.svg"
          alt=""
          sx={{
            position: 'absolute',
            right: '0',
            top: { xs: '386px', md: '564px' },
            left: { xs: '19px', md: '70%' },
            width: { xs: '48px', md: 'auto' },
          }}
        />
        <Container
          sx={{
            position: 'relative',
            zIndex: '2',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography
                sx={{
                  display: { xs: 'block', md: 'none' },
                  ...landingHeading,
                  mb: '50px',
                }}
              >
                What is <i>World Enterprise</i>?
              </Typography>
              <Box
                component="img"
                src="/images/home_section2_left.png"
                alt=""
                sx={{
                  width: { xs: '80%', md: '100%' },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                mt: { xs: '35px', md: '145px' },
              }}
            >
              <Typography
                sx={{
                  display: { xs: 'none', md: 'block' },
                  ...landingHeading,
                  mb: '30px',
                }}
              >
                What is <i>World Enterprise</i>?
              </Typography>
              <Typography
                sx={{
                  ...landingText,
                  mb: { xs: '20px', md: '39px' },
                  maxWidth: '800px',
                }}
              >
                <i>World Enterprise</i> provides you the tools to create,
                manage, and participate in decentralized organizations (
                <i>World Enterprises</i>) that are governed by smart contracts
                on the blockchain.
              </Typography>
              <WEButton onClick={connectHandler} width={200} rounded='sm'>Launch App</WEButton>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* How it works */}
      <Box
        sx={{
          pt: { xs: '90px', md: '11px' },
          pb: { xs: '30px', md: '130px' },
          background: 'white',
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <Container>
          <Typography
            sx={{
              fontSize: { xs: '30px', md: '50px' },
              lineHeight: { xs: '37px', md: '61px' },
              mb: { xs: '35px', md: '78px' },
              fontWeight: '700',
              textAlign: 'center',
            }}
          >
            How it works
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={7}
              sx={
                {
                  // mt: { xs: "35px", md: "78px" },
                }
              }
            >
              <Typography
                sx={{
                  ...landingHeading,
                  mb: { xs: '20px', md: '30px' },
                }}
              >
                Create a <i>World Enterprise</i>
              </Typography>
              <Typography
                sx={{
                  ...landingText,
                  mb: { xs: '35px', md: '25px' },
                  maxWidth: '800px',
                }}
              >
                Whether you have a new company or want to migrate your existing
                company to the blockchain, <i>World Enterprise</i> gives you the
                ability to create your own decentralized enterprise. Every{' '}
                <i>World Enterprise</i> has its own ERC20 token to represent
                shares and membership in the <i>World Enterprise</i>. During the
                setup process, you add the initial token supply for the{' '}
                <i>World Enterprise</i>, who the initial shareholders will be,
                and any Admins that you'd like to manage the day-to-day
                operations of the enterprise. After the
                <i>World Enterprise</i> is deployed on the blockchain,
                shareholders can begin creating and voting on proposals, and
                Admins can begin collecting revenue and paying expenses.
              </Typography>

              {/* Create process: */}
              <Box
                sx={{
                  display: { xs: 'none', md: 'block' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "23px",
                    lineHeight: "180.5%",
                    mb: "30px",
                    fontWeight: "700",
                  }}
                >
                  Create process:
                </Typography>

                <Box
                  sx={{
                    width: { xs: '100%', md: '80%' },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: { xs: '20px', md: '25px' },
                          }}
                        >
                          Enter <i>World Enterprise</i> details
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: { xs: '20px', md: '25px' },
                          }}
                        >
                          Add shareholders and initial token supply
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: { xs: '20px', md: '25px' },
                          }}
                        >
                          Add admins
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: { xs: '20px', md: '25px' },
                          }}
                        >
                          Create on the blockchain!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                component="img"
                src="/images/how_it_works_right.png"
                alt=""
                sx={{
                  width: '100%',
                }}
              />

              {/* Create process: */}
              <Box
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "23px",
                    lineHeight: "180.5%",
                    mb: { xs: "32px", md: "16px" },
                    fontWeight: "700",
                  }}
                >
                  Create process:
                </Typography>

                <Box
                  sx={{
                    width: '100%',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: '0 !important',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: '15px',
                          }}
                        >
                          Enter <i>World Enterprise</i> details
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: '0 !important',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: '15px',
                          }}
                        >
                          Add shareholders and initial token supply
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: '0 !important',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: '15px',
                          }}
                        >
                          Add admins
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        pt: '0 !important',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <Box
                          component="img"
                          src="/images/create_process.svg"
                          alt=""
                          sx={{
                            mr: '15px',
                          }}
                        />
                        <Typography
                          sx={{
                            ...landingText,
                            mb: '15px',
                          }}
                        >
                          Create on the blockchain!
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Join WE */}
      <Box
        sx={{
          pt: { xs: '36px', md: '55px' },
          pb: { xs: '36px', md: '56px' },
          background: '#F4F8FF',
          textAlign: { xs: 'center', md: 'left' },
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src="/images/home_section3_left2.svg"
          alt=""
          sx={{
            position: 'absolute',
            bottom: { xs: '450px', md: '0' },
            left: 0,
            width: { xs: '60%', md: 'auto' },
          }}
        />
        <Container
          sx={{
            position: 'relative',
            zIndex: '1',
          }}
        >
          <Typography
            sx={{
              ...landingHeading,
              mb: { xs: '20px', md: '27px' },
              textAlign: 'center',
            }}
          >
            Join <i>World Enterprise</i>
          </Typography>
          <Typography
            sx={{
              ...landingText,
              mb: { xs: '70px', md: '42px' },
              mx: 'auto',
              textAlign: 'center',
              color: landingTheme.black,
              maxWidth: '800px',
            }}
          >
            If you’re not already a member of a <i>World Enterprise</i>, you can
            browse all existing <i>World Enterprises</i> and request to join any
            that you’d like to be a member of. You can request to join any{' '}
            <i>World Enterprise</i> for free or offer to buy shares from the
            treasury of the <i>World Enterprise</i>.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <Box
                component="img"
                src="/images/home_section3_left.svg"
                alt=""
                sx={{
                  width: '80%',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                mt: { xs: '65.5px', md: '0' },
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '20px', md: '32px' },
                  lineHeight: { xs: '24px', md: '39px' },
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
                Join Process
              </Typography>
              <Box
                sx={{
                  mt: '50px',
                  mx: '12px',
                }}
              >
                <JoinProcess />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* WE Components what makes a WE? */}
      <Box
        sx={{
          pt: { xs: '36px', md: '90px' },
          pb: { xs: '100px', md: '200px' },
          background: 'white',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <Box
          component="img"
          src="/images/what_make_we_6.svg"
          alt=""
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: 'absolute',
            top: { xs: '0', md: '266px' },
            right: { xs: '0', md: '57px' },
            width: { xs: '53px', md: 'auto' },
            zIndex: '2',
          }}
        />
        <Box
          component="img"
          src="/images/what_make_we_7.svg"
          alt=""
          sx={{
            display: { xs: 'block', sm: 'none' },
            position: 'absolute',
            bottom: { xs: '32px', md: '62px' },
            width: { xs: '38px', md: 'auto' },
            left: '0',
          }}
        />
        <Container
          sx={{
            position: 'relative',
            zIndex: '1',
          }}
        >
          <Typography
            sx={{
              ...landingHeading,
              textAlign: 'center',
            }}
          >
            <i>World Enterprise</i> Components
          </Typography>
          <Typography variant="h4" mt={1}>
            What makes a <i>World Enterprise</i>?
          </Typography>
          <Box
            sx={{
              display: { xs: 'none', md: 'grid' },
              gridTemplateColumns: '2fr 1fr',
              gridAutoRows: '1fr',
              gridColumnGap: '70px',
              mt: { xs: '20px', md: '118px' },
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  gridTemplateRows: 'repeat(2, 1fr)',
                  gridAutoRows: '1fr',
                  gridColumnGap: '53px',
                  gridRowGap: '24px',
                }}
              >
                <Box
                  sx={{
                    p: { xs: '', md: '40px 35px 35px' },
                    borderRadius: '8px',
                    background:
                      'linear-gradient(153.81deg, #FBFF23 -78.74%, rgba(253, 255, 147, 0) 117.76%);',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      mb: { xs: '35px', md: '30px' },
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_1.png"
                      alt=""
                      sx={{
                        mr: '15px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: '17px', md: '22px' },
                        fontWeight: '600',
                      }}
                    >
                      <i>World Enterprise</i> ERC20 tokens
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...landingText,
                      maxWidth: '800px',
                    }}
                  >
                    Every <i>World Enterprise</i> has its own ERC20 token that
                    represents shares and membership in the enterprise. Anyone
                    who owns the ERC20 token of the <i>World Enterprise</i> is
                    considered a member and can create new proposals and vote on
                    proposals from other members. Token holders can come
                    together and decide to issue new tokens and change the
                    supply and tokenomics over time
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: { xs: '', md: '40px 35px 35px' },
                    borderRadius: '8px',
                    background: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25);',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      mb: { xs: '35px', md: '30px' },
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_3.svg"
                      alt=""
                      sx={{
                        mr: '15px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: '17px', md: '22px' },
                        fontWeight: '600',
                      }}
                    >
                      Proposals
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...landingText,
                      maxWidth: '800px',
                    }}
                  >
                    Any idea suggested to improve the <i>World Enterprise</i> is
                    a proposal. Shareholders and Admins can submit proposals,
                    but only shareholders can approve the proposal. All
                    proposals are open for voting, and a proposal needs majority
                    approval before it can be automatically executed on the
                    blockchain.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: { xs: '', md: '40px 35px 35px' },
                    borderRadius: '8px',
                    background: 'white',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25);',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      mb: { xs: '35px', md: '30px' },
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_2.svg"
                      alt=""
                      sx={{
                        mr: '15px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: '17px', md: '22px' },
                        fontWeight: '600',
                      }}
                    >
                      Transactions
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...landingText,
                      maxWidth: '800px',
                    }}
                  >
                    Every <i>World Enterprise</i> has a public and transparent
                    ledger for incoming and outgoing transactions of all digital
                    assets on the blockchain, including all revenues and
                    expenses, account tags, and transaction notes. Admins are
                    responsible for executing transactions on behalf of the{' '}
                    <i>World Enterprise</i>, and anyone can export transactions
                    for accounting purposes.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    p: { xs: '', md: '40px 35px 35px' },
                    borderRadius: '8px',
                    background:
                      'linear-gradient(229.1deg, #3F69FF -67.11%, rgba(255, 255, 255, 0) 143.43%);',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      mb: { xs: '35px', md: '30px' },
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      component="img"
                      src="/images/what_make_we_4.svg"
                      alt=""
                      sx={{
                        mr: '15px',
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: landingTheme.fontSize,
                        lineHeight: { xs: '17px', md: '22px' },
                        fontWeight: '600',
                      }}
                    >
                      Admins
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      ...landingText,
                      maxWidth: '800px',
                    }}
                  >
                    {' '}
                    An Admin is anyone approved by shareholders to manage the
                    daily operations of the <i>World Enterprise</i>. Admins have
                    access to the treasury and can send money and issue invoices
                    on behalf of the enterprise.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                p: { xs: '', md: '40px 35px 35px' },
                borderRadius: '8px',
                background:
                  'linear-gradient(153.81deg, #FBFF23 -78.74%, rgba(253, 255, 147, 0) 117.76%);',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  mb: { xs: '35px', md: '30px' },
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Box
                  component="img"
                  src="/images/what_make_we_5.svg"
                  alt=""
                  sx={{
                    mr: '15px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: landingTheme.fontSize,
                    lineHeight: { xs: '17px', md: '22px' },
                    fontWeight: '600',
                  }}
                >
                  Shareholders
                </Typography>
              </Box>
              <Typography
                sx={{
                  ...landingText,
                  maxWidth: '800px',
                }}
              >
                {' '}
                A Shareholder is anyone who owns a <i>World Enterprise</i> ERC20
                token is considered a shareholder of that{' '}
                <i>World Enterprise</i>. By owning the <i>World Enterprise</i>’s
                shares (native token) and being a member, you can influence and
                help determine the future of any <i>World Enterprise</i>.
                Shareholders can create proposals and add new shareholders,
                create more ERC20 tokens, and add/remove/replace Admins.
                Shareholders can also approve and reject any new members who
                want to join the <i>World Enterprise</i>. Over time the variety
                of proposals will be expanded to include additional important
                corporate governance items including giving shareholders the
                right to vote on company strategy, company budgets, company
                investments, etc.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            <Slider {...slideSettings}>
              <SideItem
                iconSrc="/images/what_make_we_1.png"
                title={(() => (
                  <>
                    <i>World Enterprise</i> ERC20 tokens
                  </>
                ))()}
                content={(() => (
                  <>
                    Every <i>World Enterprise</i> has its own ERC20 token that
                    represents shares and membership in the enterprise. Anyone
                    who owns the ERC20 token of the <i>World Enterprise</i> is
                    considered a member and can create new proposals and vote on
                    proposals from other members. Token holders can come
                    together and decide to issue new tokens and change the
                    supply and tokenomics over time
                  </>
                ))()}
              />
              <SideItem
                iconSrc="/images/what_make_we_3.svg"
                title="Proposals"
                content={(() => (
                  <>
                    Any idea suggested to improve the <i>World Enterprise</i> is
                    a proposal. Shareholders and Admins can submit proposals,
                    but only shareholders can approve the proposal. All
                    proposals are open for voting, and a proposal needs majority
                    approval before it can be automatically executed on the
                    blockchain.
                  </>
                ))()}
              />
              <SideItem
                iconSrc="/images/what_make_we_2.svg"
                title="Transactions"
                content={(() => (
                  <>
                    Every <i>World Enterprise</i> has a public and transparent
                    ledger for incoming and outgoing transactions of all digital
                    assets on the blockchain including account tags and notes on
                    what each transaction was for. Admins are responsible for
                    executing transactions on behalf of the{' '}
                    <i>World Enterprise</i>, and anyone can export transactions
                    for accounting purposes.
                  </>
                ))()}
              />
              <SideItem
                iconSrc="/images/what_make_we_4.svg"
                title="Admins"
                content={(() => (
                  <>
                    An Admin is anyone approved by shareholders to manage the
                    daily operations of the <i>World Enterprise</i>. Admins have
                    access to the treasury and can send money and issue invoices
                    on behalf of the enterprise.
                  </>
                ))()}
                bgColor="#3F69FF"
              />
              <SideItem
                iconSrc="/images/what_make_we_5.svg"
                title="Shareholders"
                content={(() => (
                  <>
                    A Shareholder is anyone who owns a <i>World Enterprise</i>{' '}
                    ERC20 token is considered a shareholder of that{' '}
                    <i>World Enterprise</i>. By owning the{' '}
                    <i>World Enterprise</i>’s shares (native token) and being a
                    member, you can influence and help determine the future of
                    any <i>World Enterprise</i>. Shareholders can create
                    proposals and add new shareholders, create more ERC20
                    tokens, and add/remove/replace Admins. Shareholders can also
                    approve and reject any new members who want to join the{' '}
                    <i>World Enterprise</i>. Over time the variety of proposals
                    will be expanded to include additional important corporate
                    governance items including giving shareholders the right to
                    vote on company strategy, company budgets, company
                    investments, etc.
                  </>
                ))()}
              />
            </Slider>
          </Box>
        </Container>
      </Box>
      <Footer />
      <SelectWalletModal isOpen={isOpen} closeModal={Close} />
    </Box>
  );
};

export default Landing;
