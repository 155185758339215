import { CameraAlt, Person } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useMemo, useState } from 'react';
import { upsertEnterprise } from '../../apis';
import store from '../../constants/store';
import { STEP, STORE_KEYS } from '../../constants/store/constant';
import { useEnterprise } from '../../hook/weEnterprise';
import { useWeb3 } from '../../hook/web3';
import { useAutoConnect } from '../../utils/AutoConnect';
import { simpleErrorMessage } from '../../utils/errorMessage';
import { ERRORS } from '../../utils/errors';
import { generateEnterpriseInfo } from '../../utils/generateEnterpriseInfo';
import { CreateAppBar, ModalHead } from '../AppBar';
import HomeAppBar from '../AppBar/HomeAppBar';
import { ImageEditorModal, PhotoEditorModal } from '../Dialog';
import { EditableInput, InputLabel, Select } from '../Input';
import { useNotification } from '../Notification/hook';
import { WELoadingButton } from '../Button';

const initLineState = {
  name: false,
  token: false,
  description: false,
  registeredEnterprise: false,
  companyType: false,
  website: false,
  address1: false,
  address2: false,
  country: false,
  zip: false,
  state: false,
  city: false,
};
const DetailCreate = ({ isSetting = false }) => {
  const [loading, setLoading] = useState(false);
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );

  const enterprise = enterprises.tempEnterprise;

  const web3 = useWeb3();
  const { displaySuccess, displayWarning, displayError } = useNotification();
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const enterpriseHandler = useEnterprise(enterprise.info.address);
  const [enterpriseInfo, setEnterpriseInfo] = useState({
    ...enterprises.tempEnterprise.info,
  });
  const [weName, setWeName] = useState(enterpriseInfo.name);
  const [editorOpen, setEditorOpen] = useState(false);
  const [image, setImage] = useState(undefined);
  const [backgroundImage, setBackgroundImage] = useState(undefined);
  const [imageEditorOpen, setImageEditorOpen] = useState(false);
  const theme = useTheme();
  const { account } = useAutoConnect();
  let [isLineEditable, setIsLineEditable] = useState(initLineState);

  const isAdmin = useMemo(() => {
    if (account && enterprise && enterprise.admins) {
      const isThere = enterprise.admins.findIndex(
        (e) => e.walletAddr.toLocaleLowerCase() === account.toLocaleLowerCase()
      );
      return isThere !== -1;
    }
    return false;
  }, [enterprise, account]);

  const closeHandler = () => {
    switch (step) {
      case STEP.SETTING_COMPANY_REVIEW:
      case STEP.SETTING_COMPANY_EDIT:
        setStep(STEP.INDEX);
        break;

      default:
        break;
    }
  };
  const editorClose = () => {
    setEditorOpen(false);
    setImageEditorOpen(false);
  };

  const imageEditorOK = (ipfsURL, imageURL) => {
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        backgroundUrl: ipfsURL,
      };
    });
  };

  const editorOK = (ipfsURL, imageURL) => {
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        logo: ipfsURL,
      };
    });
  };
  const handleFileChange = async (event, type) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) return;
    if (type === 'background') {
      setImageEditorOpen(true);
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setBackgroundImage(reader.result?.toString() || '')
      );
      reader.readAsDataURL(fileObj);
    } else {
      setEditorOpen(true);
      setImage(fileObj);
    }
  };

  const handleSelectChange = (type) => (evt) => {
    const value = evt.target.value;
    switch (type) {
      case 'isRegisterd':
        let isRegistered = false;
        if (value === 'Yes') isRegistered = true;
        else isRegistered = false;
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            isRegisterd: isRegistered,
          };
        });
        break;
      case 'type':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            type: value,
          };
        });
        break;
      case 'country':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            country: value,
          };
        });
        break;
      case 'state':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            state: value,
          };
        });
        break;
      case 'city':
        setEnterpriseInfo((prev) => {
          return {
            ...prev,
            city: value,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleInputChange = (evt, type) => {
    const value = type === 'address1' ? evt.label : evt.currentTarget.value;
    let tempData = {};
    tempData[type] = value;
    setEnterpriseInfo((prev) => {
      return {
        ...prev,
        ...tempData,
      };
    });
  };

  const continueHandler = async (info) => {
    if (!info?.logo) {
      return displayWarning({
        message: 'Please upload your enterprise profile picture',
        timeout: 5000,
      });
    }
    if (!info?.name) {
      return displayWarning({
        message: 'Please input enterprise name',
        timeout: 5000,
      });
    }
    if (!info?.tokenName) {
      return displayWarning({
        message: 'Please input token name',
        timeout: 5000,
      });
    }
    if (!info?.description) {
      return displayWarning({
        message: 'Please input enterprise description',
        timeout: 5000,
      });
    }

    updateEnterprises((prev) => {
      prev.tempEnterprise.info = info;
      return prev;
    });
    let tenterprise = { ...enterprise, info };
    setLoading(true);
    let ENTERPRISE_INFO = await generateEnterpriseInfo(tenterprise);
    try {
      await enterpriseHandler.methods
        .updateInfo(ENTERPRISE_INFO)
        .send({ from: account });
      await upsertEnterprise(
        tenterprise.info.address,
        ENTERPRISE_INFO.ipfs,
        tenterprise.info.name,
        tenterprise.info.tokenName
      );
      setStep(STEP.SETTING_COMPANY_REVIEW);
      displaySuccess({ message: 'Company updated', timeout: 5000 });
      setWeName(tenterprise.info.name);
      setIsLineEditable(initLineState);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      displayError({
        message: ERRORS.TO_REJECT,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
      return;
    }
  };

  const editHandler = (e) => {
    let tempData = {};
    tempData[e] = !isLineEditable[e];
    setIsLineEditable({ ...isLineEditable, ...tempData });
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: {
              sm: 'flex',
              xs: 'flex',
            },
          }}
        >
          <HomeAppBar />
        </Box>
        <ModalHead
          title={weName}
          theme="light"
          close={() => closeHandler()}
          color="#FFDB0B"
        />
        <Box
          sx={{
            display: {
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <CreateAppBar
            title="Review Enterprise Details"
            close={closeHandler}
            helpEnabled={false}
          />
        </Box>
        <Grid
          container
          component="main"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            px: 3,
            py: '40px',
            backgroundColor: '#EFEFEF',
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
            position="relative"
            sx={{
              height: {
                sm: '203px',
                xs: '100%',
              },
            }}
          >
            <Box
              sx={{
                display: {
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              {enterpriseInfo.backgroundUrl ? (
                <img
                  alt="logo"
                  src={enterpriseInfo.backgroundUrl}
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                  }}
                />
              ) : null}
            </Box>

            <Box
              sx={{
                display: {
                  sm: 'flex',
                  xs: 'flex',
                },
                width: {
                  xs: '100%',
                },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: {
                    xs: '15px',
                    sm: '20px',
                  },
                  borderBottom: '2px solid #E3E8EB',
                  paddingBottom: '25px',
                  width: '100%',
                }}
              >
                Review Enterprise Details
              </Typography>
            </Box>
            <IconButton
              // color="primary"
              aria-label="upload picture"
              component="label"
              sx={{
                width: '100%',
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                  handleFileChange(e, 'background');
                }}
                value={''}
              />
              <Badge
                overlap="circular"
                sx={{
                  width: '100%',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: theme.palette.primary.dark,
                    }}
                  >
                    <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
                  </Avatar>
                }
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  position="relative"
                  sx={{
                    height: '203px',
                  }}
                >
                  {enterpriseInfo.backgroundUrl ? (
                    <img
                      alt="logo"
                      src={enterpriseInfo.backgroundUrl}
                      style={{
                        width: '100%',
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                      }}
                    />
                  ) : null}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e, 'logo');
                      }}
                      value={''}
                    />
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          sx={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: theme.palette.primary.dark,
                          }}
                        >
                          <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
                        </Avatar>
                      }
                    >
                      <Avatar sx={{ width: '111px', height: '111px' }}>
                        {enterpriseInfo.logo !== '' ? (
                          <img
                            alt="logo"
                            src={enterpriseInfo.logo}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <Person
                            sx={{ width: '70px', height: '70px' }}
                            htmlColor="#4B4749"
                          />
                        )}
                      </Avatar>
                    </Badge>
                  </IconButton>
                </Box>
              </Badge>
            </IconButton>
            {/* <IconButton
              aria-label="upload picture"
              component="label"
              sx={{
                width: '100%',
                padding: '0',
                '&:active': { background: 'none' },
                '& .MuiTouchRipple-child': { background: 'none' },
              }}
            >
              <input
                hidden
                accept="image/*"
                type="file"
                onChange={(e) => {
                  handleFileChange(e, 'background');
                }}
                value={''}
              />
              <Badge
                sx={{
                  width: '100%',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                badgeContent={
                  <Avatar
                    sx={{
                      width: '24px',
                      height: '24px',
                      backgroundColor: theme.palette.primary.dark,
                    }}
                  >
                    <CameraAlt
                      sx={{
                        width: '16px',
                        color: '#FFFFFF',
                        '&:active': { background: 'none' },
                      }}
                    />
                  </Avatar>
                }
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  position="relative"
                  sx={{
                    height: '203px',
                  }}
                >
                  {enterpriseInfo.backgroundUrl ? (
                    <img
                      alt="logo"
                      src={enterpriseInfo.backgroundUrl}
                      style={{
                        width: '100%',
                        position: 'absolute',
                        height: '100%',
                        top: 0,
                        left: 0,
                        objectFit: 'cover',
                      }}
                    />
                  ) : null}

                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="label"
                  >
                    <input
                      hidden
                      accept="image/*"
                      type="file"
                      onChange={(e) => {
                        handleFileChange(e, 'logo');
                      }}
                      value={''}
                    />
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                      badgeContent={
                        <Avatar
                          sx={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: theme.palette.primary.dark,
                            '& .MuiTouchRipple-child': { background: 'none' },
                          }}
                        >
                          <CameraAlt sx={{ width: '16px', color: '#FFFFFF' }} />
                        </Avatar>
                      }
                    >
                      <Avatar sx={{ width: '111px', height: '111px' }}>
                        {enterpriseInfo.logo !== '' ? (
                          <img
                            alt="logo"
                            src={enterpriseInfo.logo}
                            style={{ width: '100%' }}
                          />
                        ) : (
                          <Person
                            sx={{ width: '70px', height: '70px' }}
                            htmlColor="#4B4749"
                          />
                        )}
                      </Avatar>
                    </Badge>
                  </IconButton>
                </Box>
              </Badge>
            </IconButton> */}
          </Box>

          <Link
            sx={{
              alignSelf: 'end',
              width: 'auto',
              color: '#3D61B0',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '15px',
              lineHeight: '18px',
              textAlign: 'right',
              cursor: 'pointer',
              right: '0',
              textDecoration: 'underline',
              display: {
                xs: 'none',
                sm: 'none',
              },
            }}
            onClick={editHandler}
            mt={3}
          >
            Edit
            <EditIcon
              style={{ marginLeft: '6px', position: 'relative', top: '5px' }}
            />
          </Link>
          <Box
            width="100%"
            mt={6}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Enterprise Name'}
              value={enterpriseInfo.name}
              field="name"
              onEdit={editHandler}
              required={true}
              editable={isLineEditable.name}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Token Name'}
              value={enterpriseInfo.tokenName}
              field="token"
              onEdit={editHandler}
              required={true}
              editable={isLineEditable.token}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Enterprise Description'}
              value={enterpriseInfo.description}
              field="description"
              onEdit={editHandler}
              required={true}
              editable={isLineEditable.description}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            {!isLineEditable.registeredEnterprise ? (
              <>
                <Box>
                  <Typography variant="body2">
                    Is this an existing enterprise registered with a government?
                  </Typography>
                  <Typography
                    variant="h3"
                    mt={2}
                    sx={{
                      fontWeight: 500,
                      minHeight: '20px',
                      maxWidth: '100%',
                      wordBreak: 'break-all',
                    }}
                  >
                    {enterpriseInfo.isRegisterd ? 'Yes' : 'No'}
                  </Typography>
                </Box>
                <Box>
                  <Link
                    onClick={() => {
                      editHandler('registeredEnterprise');
                    }}
                    sx={{ display: { xs: 'flex', sm: 'flex' } }}
                  >
                    {isAdmin && (
                      <EditIcon
                        style={{
                          marginLeft: '6px',
                          position: 'relative',
                          top: '5px',
                          color: '#6F7287',
                        }}
                      />
                    )}
                  </Link>
                </Box>
              </>
            ) : (
              <Box width="100%" mt={0}>
                <InputLabel shrink htmlFor="isRegisterd">
                  Is this an existing enterprise registered with a government?
                </InputLabel>
                <Select
                  id="isRegisterd"
                  value={enterpriseInfo.isRegisterd ? 'Yes' : 'No'}
                  fullWidth
                  sx={{ mt: 1 }}
                  itemType="select"
                  onChange={handleSelectChange('isRegisterd')}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </Box>
            )}
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            {!isLineEditable.companyType ? (
              <>
                <Box>
                  <Typography variant="body2">Type of Company</Typography>
                  <Typography
                    variant="h3"
                    mt={2}
                    sx={{
                      fontWeight: 500,
                      minHeight: '20px',
                      maxWidth: '100%',
                      wordBreak: 'break-all',
                    }}
                  >
                    {enterpriseInfo.type}
                  </Typography>
                </Box>
                <Box>
                  <Link
                    onClick={() => {
                      editHandler('companyType');
                    }}
                    sx={{ display: { xs: 'flex', sm: 'flex' } }}
                  >
                    {isAdmin && (
                      <EditIcon
                        style={{
                          marginLeft: '6px',
                          position: 'relative',
                          top: '5px',
                          color: '#6F7287',
                        }}
                      />
                    )}
                  </Link>
                </Box>
              </>
            ) : (
              <Box width="100%" mt={0}>
                <InputLabel shrink htmlFor="type">
                  Type of Company
                </InputLabel>
                <Select
                  id="type"
                  value={enterpriseInfo.type}
                  fullWidth
                  sx={{ mt: 1 }}
                  onChange={handleSelectChange('type')}
                >
                  <MenuItem value="LLC">LLC</MenuItem>
                  <MenuItem value="C-Corp">C-Corp</MenuItem>
                  <MenuItem value="S-Corp">S-Corp</MenuItem>
                  <MenuItem value="Non-Profit">Non-Profit</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </Box>
            )}
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Website'}
              value={enterpriseInfo.website}
              field="website"
              onEdit={editHandler}
              editable={isLineEditable.website}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              type="address"
              label={'Address Line 1'}
              value={enterpriseInfo.address1}
              field="address1"
              onEdit={editHandler}
              editable={isLineEditable.address1}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Address Line 2'}
              value={enterpriseInfo.address2}
              field="address2"
              onEdit={editHandler}
              editable={isLineEditable.address2}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'Country'}
              value={enterpriseInfo.country}
              field="country"
              onEdit={editHandler}
              editable={isLineEditable.country}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'ZIP'}
              value={enterpriseInfo.zip}
              field="zip"
              onEdit={editHandler}
              editable={isLineEditable.zip}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'State'}
              value={enterpriseInfo.state}
              field="state"
              onEdit={editHandler}
              editable={isLineEditable.state}
              handleChange={handleInputChange}
            />
          </Box>
          <Box
            width="100%"
            mt={3}
            display="flex"
            justifyContent="space-between"
          >
            <EditableInput
              label={'City'}
              value={enterpriseInfo.city}
              field="city"
              onEdit={editHandler}
              editable={isLineEditable.city}
              handleChange={handleInputChange}
            />
          </Box>
          <WELoadingButton
            type='primary' 
            loading={loading}
            rounded='xl'
            loadingPosition="center"
            loadingIndicator={
              <CircularProgress color="default" size={20}/>
            }
            onClick={() => {
              continueHandler(enterpriseInfo);
            }}
            sx={{
              width: { sm: '25%', xs: '80%' }
            }}
          >
           {isSetting ? 'Update' : 'Continue'}
          </WELoadingButton>
        </Grid>
      </Box>
      <PhotoEditorModal
        open={editorOpen}
        onClose={editorClose}
        image={image}
        onOK={editorOK}
      />
      <ImageEditorModal
        open={imageEditorOpen}
        onClose={editorClose}
        image={backgroundImage}
        onOK={imageEditorOK}
      />
    </>
  );
};

export default DetailCreate;
