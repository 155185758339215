import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";

const MembersAnsTreasuresSection = ({ enterprise, totalShare, treasuryUSD }) => {
    return (
        <>
            <Grid2 display="flex" justifyContent="space-between">
                <Grid2 display="flex" flexDirection="column" justifyContent="space-between" sx={{ rowGap: "12px" }}>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                        <Typography variant="secondary">Members</Typography>
                        <Typography variant="primary">{enterprise?.info?.memNum}</Typography>
                    </Grid2>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                        <Typography variant="secondary">Price Per Share</Typography>
                        <Typography variant="primary" sx={{ color: "#5CDD5C" }}>
                            {"$" + (enterprise?.info?.price ? enterprise?.info?.price.toLocaleString(undefined, { minimumFractionDigits: 2, }) : "0.00")}
                        </Typography>
                    </Grid2>
                </Grid2>
                <Grid2 display="flex" flexDirection="column" justifyContent="space-between">
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                        <Typography variant="secondary">Total Shares</Typography>
                        <Typography variant="primary">{totalShare.toLocaleString(undefined, { minimumFractionDigits: 2, })}</Typography>
                    </Grid2>
                    <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                        <Typography variant="secondary">Treasury</Typography>
                        <Typography variant="primary">{"$" + treasuryUSD.toLocaleString(undefined, { minimumFractionDigits: 2, })}</Typography>
                    </Grid2>
                </Grid2>
            </Grid2>
        </>
    )
}

export default MembersAnsTreasuresSection;