import React from "react";
import FactoryABI from "../constants/abi/factory.json";
import { useWeb3 } from "./web3";


export function useWeFactory() {
  const web3 = useWeb3();
  const [weFactory, setWeFactory] = React.useState();

  React.useEffect(() => {
    try {
      setWeFactory(
        new web3.eth.Contract(
          FactoryABI,
          process.env.REACT_APP_CONTRACT_MUMBAI_FACTORY
        )
      );
    } catch (e) {}
  }, [web3]);
  return weFactory;
}
