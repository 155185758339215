import {
  Add,
  ChevronRight,
  CreditCard,
  FileUploadOutlined,
  Help,
  Settings
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Unstable_Grid2 as Grid2,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery
} from "@mui/material";
import { Container } from "@mui/system";
import { Promise } from "bluebird";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getBusinessUpdatesForEnterprise,
  getOrdersForEnterprise,
  getProposalsForEnterprise,
  getTransactionsForEnterprise,
} from "../../apis";
import { WEProfileAppBar } from "../../components/AppBar";
import { BsUpdatesList } from "../../components/BsUpdates";
import {WEButton} from "../../components/Button";
import MembersAnsTreasuresSection from "../../components/Dashboard/MembersAnsTreasuresSection";
import MyShares from "../../components/Dashboard/MyShares";
import {
  AdminDetailModal,
  JoinModal,
  ProposalDetailModal,
  ToolTip,
  TransactionDetailModal,
} from "../../components/Dialog";
import CommentModal from "../../components/Dialog/CommentModal";
import DashboardCard from "../../components/Dialog/DashboardCard";
import Header from "../../components/Header";
import { useNotification } from "../../components/Notification/hook";
import ProposalContent from "../../components/UserDashboard/ProposalContent";
import store from "../../constants/store";
import { STEP, STORE_KEYS, TXTYPE } from "../../constants/store/constant";
import { useEnterprise } from "../../hook/weEnterprise";
import { useWeb3 } from "../../hook/web3";
import { useAutoConnect } from "../../utils/AutoConnect";
import { convertMaticToUSD, getEtherFromWei } from "../../utils/unit";

const AssetType = {
  USD: "USD",
  MATIC: "MATIC",
};

const DashboardPage = () => {
  const { displaySuccess, displayError } = useNotification();
  const { account } = useAutoConnect();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { wepID, weBack, pTab } = state;
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [step, setStep] = store.useState(STORE_KEYS.id.step);
  const web3 = useWeb3();
  const enterprise = enterprises.tempEnterprise;
  console.log("temp", enterprise)

  const enterpriseHandler = useEnterprise(enterprise.info.address);

  const [logo, setLogo] = useState("");
  const [backgroundUrl, setBackgroundUrl] = useState("");
  const [name, setName] = useState("");
  const [ID, setID] = useState("");
  const [description, setDescription] = useState("");
  const [treasuryMatic, setTreasuryMatic] = useState(0);
  const [treasuryUSD, setTreasuryUSD] = useState(0);
  const [treasuryType, setTreasuryType] = useState(AssetType.USD);
  const [myShare, setMyShare] = useState(0);
  const [totalShare, setTotalShare] = useState(0);
  const [totalMembers, setTotalMembers] = useState(22);
  const [admins, setAdmins] = useState(enterprise.admins);
  const [category, setCategory] = useState(pTab ? pTab : "shareholders");
  const [proposals, setProposals] = useState(enterprise.proposals);

  const [dlgOpened, setDlgOpened] = useState(false);


  useEffect(() => {
    setStep(STEP.INDEX);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLogo(enterprise.info.logo);
    setBackgroundUrl(enterprise.info.backgroundUrl);
    setName(enterprise.info.name);
    setDescription(enterprise.info.description);
    setProposals(enterprise.proposals);
    if (enterprise.info.address) {
      setID(enterprise.info.address.toLowerCase());
    }
    setAdmins(enterprise.admins);
    setTotalMembers(
      enterprise.shareholders ? enterprise.shareholders.length : 0
    );
    setTotalShare(enterprise.info.totalShare);
    if (enterprise.isShareholder) {
      const _shareAmountEther = getEtherFromWei(enterprise?.numOfShare);
      setMyShare(_shareAmountEther);
    }
  }, [enterprise]); // eslint-disable-line react-hooks/exhaustive-deps

  const isMember = useMemo(() => {
    const { admins, shareholders } = enterprise;
    let isCurrentUserMeber = false;

    admins.forEach(admin => {
      if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserMeber = true;
      }
    });

    shareholders.forEach(shareholder => {
      if (shareholder.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserMeber = true;
      }
    });

    return isCurrentUserMeber;
  }, [enterprise, account]);

  const isAdmin = useMemo(() => {
    const { admins } = enterprise;
    let isCurrentUserAdmin = false;

    admins.forEach(admin => {
      if (admin.walletAddr.toUpperCase() === account.toUpperCase()) {
        isCurrentUserAdmin = true;
      }
    });

    return isCurrentUserAdmin;
  }, [enterprise, account]);

  // open enterprise modal
  const openModalJoin = () => {
    if (enterprise) {
      setDlgOpened(true);
    }
  };
  const joinWE = async (enterprise, isJoined = true, comment = "") => {

    updateEnterprises((prev) => {
      // prev.enterprises[idx] = temp;
      prev.tempEnterprise = enterprise;
      return prev;
    });
    navigate(`/wepID/dashboard`, {
      state: { wepID: enterprise.info.wepID, weBack: `/user/${account}` },
    });
  };
  const requestHandler =
    () =>
      ({ comment }) => {
        // create a new proposal and add it
        // let enterprise = { ...enterprises.enterprises[idx] };
        const joinProposal = {
          id: Date.now(),
          type: "join",
          votesYes: 0,
          votesNo: 0,
          walletAddr: account || "",
          name: "",
          isApproved: false,
          comment: comment,
        };
        enterprise.proposals = [...enterprise.proposals, joinProposal];
        // join
        setTimeout(() => {
          joinWE(enterprise, false);
        }, 3000);
      };
  // end enterprise modal

  useEffect(() => {
    if (!enterprise.info.address || enterprise?.proposalFetched) {
      return;
    }
    // proposals fetched from join-world-enterprise
    Promise.all([
      getProposalsForEnterprise(enterprise.info.address),
      getBusinessUpdatesForEnterprise(enterprise.info.address),
      getTransactionsForEnterprise(enterprise.info.address),
      getOrdersForEnterprise(enterprise.info.address),
    ])
      .then((res) => {
        let tProposals = [];
        let tBusinessUpdates = [];
        let ttransactions = [];
        let torders = [];
        if (res[0]?.data?.exists) {
          try {
            const data = res[0].data.data;
            for (let proposal of data) {
              let votesYes = 0;
              let votesNo = 0;
              let isApproved = false;
              if (proposal?.votes) {
                try {
                  const votes = JSON.parse(proposal?.votes);
                  const votesKey = Object.keys(votes);
                  for (let key of votesKey) {
                    if (key.toUpperCase() === account.toUpperCase()) {
                      isApproved = true;
                    }
                    if (votes[key]?.approve === "NO") {
                      votesNo++;
                    } else if (votes[key]?.approve === "YES") {
                      votesYes++;
                    }
                  }
                } catch (e) { }
              }
              tProposals.push({
                idx: proposal?.proposalIndex,
                id: proposal?.id,
                amount: proposal?.amount,
                name: "Join World Enterprise Request",
                type: "shareholder",
                votesYes: votesYes,
                votesNo: votesNo,
                walletAddr: proposal?.proposer,
                isApproved: isApproved,
                commentUrl: proposal?.commentUrl,
                status: proposal?.status,
                endTime: proposal?.endTime,
                comments: proposal?.comments,
                logoImg: proposal?.logoImg,
              });
            }
          } catch (e) { }
        }

        if (res[1]?.data?.exists) {
          try {
            const data = res[1].data.data;
            for (let businessUpdate of data) {
              tBusinessUpdates.push(businessUpdate);
            }
            tBusinessUpdates = tBusinessUpdates.reverse()
          } catch (e) { }
        }

        if (res[2]?.data?.exists) {
          try {
            const data = res[2].data.data;
            for (let transaction of data) {
              transaction.created_at = Number(transaction.created_at) * 10 ** 3;
              transaction.category = transaction.category
                ? transaction.category
                : "";
              transaction.asset = transaction.asset ? transaction.asset : "";
              transaction.note = transaction.note ? transaction.note : "";
              transaction.amount = getEtherFromWei(transaction.amount);
              if (
                transaction.token.toUpperCase() ===
                transaction.enterprise_address.toUpperCase()
              ) {
                transaction.type = "Share Transfer";
                transaction.asset = enterprise.info.tokenName;
              } else {
                if (
                  transaction.from.toUpperCase() ===
                  transaction.enterprise_address.toUpperCase()
                ) {
                  transaction.type = TXTYPE.MONEY_OUT;
                } else {
                  transaction.type = TXTYPE.MONEY_IN;
                }
              }
              ttransactions.push(transaction);
            }
          } catch (e) { }
        }

        if (res[3]?.data?.exists) {
          try {
            const data = res[3].data.data;
            for (let order of data) {
              let torder = { ...order };
              torder.amount = parseFloat(torder.amount);
              torder.price = parseFloat(getEtherFromWei(torder.price));
              torders.push(torder);
            }
          } catch (error) {
            console.log("Error", error);
          }
        }

        updateEnterprises((prev) => {
          const index = prev.enterprises.findIndex(
            (_enterprise) =>
              _enterprise.info.address === enterprise.info.address
          );
          prev.enterprises[index].proposals = tProposals;
          prev.tempEnterprise.proposals = tProposals;
          prev.enterprises[index].businessUpdates = tBusinessUpdates;
          prev.tempEnterprise.businessUpdates = tBusinessUpdates;
          prev.enterprises[index].transactions = ttransactions;
          prev.tempEnterprise.transactions = ttransactions;
          prev.enterprises[index].proposalFetched = true;
          prev.tempEnterprise.proposalFetched = true;
          prev.enterprises[index].orders = torders;
          prev.tempEnterprise.orders = torders;
          return prev;
        });
      })
      .catch((reason) => {
        console.error(
          `Failed to load proposals for account: ${enterprise.info.address}`,
          reason
        );
      });
  }, [enterprise.info.address, enterprise.orders]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!enterprise.info.address || !web3) {
      return;
    }
    (async () => {
      try {
        const res = await web3.eth.getBalance(
          enterprise.info.address.toLowerCase()
        );
        let treasuryMatic = getEtherFromWei(res);
        setTreasuryMatic(treasuryMatic);
        let treasuryUSD = await convertMaticToUSD(treasuryMatic);
        setTreasuryUSD(treasuryUSD);
      } catch (e) { }
    })();

  }, [enterprise.info.address, web3]);

  const backHandle = () => {
    if (state.from === "final creation") {
      if (account && enterprises.registeredUser) {
        navigate(`/user/${account}`);
      }
    }
    else navigate(-1);
  };

  const setting = () => {
    navigate(`/wepID/setting`, { state: { wepID, weBack } });
    setStep(STEP.SETTING_COMPANY_REVIEW);
  };

  const send = (val) => {
    navigate(`/wepID/send`, { state: { wepID, weBack, pTab: "admins", moneyFlag: TXTYPE.MONEY_OUT } });

  };

  const receive = () => {
    navigate(`/wepID/send`, { state: { wepID, weBack, pTab: "admins", moneyFlag: TXTYPE.MONEY_IN } });
  };

  const trade = (tabId) => {
    setStep(STEP.DASHBOARD_TRADE_ADD);
    navigate(`/wepID/trades`, { state: { wepID, weBack, tabId } });
  };

  const allMembers = (pTab) => {
    navigate(`/wepID/members`, { state: { wepID, weBack, pTab } });
  };

  const businessUpdatesAll = (pTab) => {
    navigate(`/wepID/businessUpdates`, { state: { wepID, weBack, pTab } });
  };

  const proposalsAll = () => {
    navigate(`/wepID/proposals`, { state: { wepID, weBack } });
  };

  const trxAll = (pTab) => {
    navigate(`/wepID/transactions`, { state: { wepID, weBack, pTab } });
  };

  // const adminAdd = () => {
  //   setStep(STEP.ADMIN_ADD)
  //   navigate(`/wepID/admins`, {state: {wepID}})
  // }

  const adminsAll = () => {
    navigate(`/wepID/admins`, { state: { wepID, weBack } });
  };

  const escapeHandle = () => {
    updateEnterprises((prev) => {
      const index = prev.enterprises.findIndex(
        (enterprise) => enterprise.info.wepID === prev.tempEnterprise.info.wepID
      );
      if (index >= 0) {
        prev.enterprises[index] = prev.tempEnterprise;
        return prev;
      }
    });
  };

  const adminClickHandler = (idx) => {
    setAdminIdx(idx);
    setAdminDlgOpen(true);
  };

  const proposalClickHandler = (idx) => {
    setProposalIdx(idx);
    setProposalDlgOpen(true);
  };

  const trxClickHandler = (idx) => {
    setTrxIdx(idx);
    setTrxDlgOpen(true);
  };

  // Admin View Dialog
  const [adminDlgOpen, setAdminDlgOpen] = useState(false);
  const [adminIdx, setAdminIdx] = useState(-1);
  const adminReplaceHandler = (idx) => {
    updateEnterprises((prev) => {
      prev.toEditAdmin = idx;
      return prev;
    });
    setStep(STEP.DASHBOARD_ADMIN_EDIT);
    navigate(`/wepID/admins`, { state: { wepID, weBack } });
    setAdminDlgOpen(false);
  };
  const adminRemoveHandler = (idx) => () => {
    updateEnterprises((prev) => {
      prev.tempEnterprise.admins.splice(idx, 1);
      return prev;
    });
    setAdminDlgOpen(false);
  };

  // Proposal View Dialog
  const [proposalDlgOpen, setProposalDlgOpen] = useState(false);
  const [commentDlgOpen, setCommentDlgOpen] = useState(false);
  const [proposalIdx, setProposalIdx] = useState(-1);

  const proposalReject = async () => {
    if (enterprise?.proposals[proposalIdx]?.isApproved) {
      return setProposalDlgOpen(false);
    }

    try {
      await enterpriseHandler.methods
        .vote(enterprise.proposals[proposalIdx].idx, false)
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
      setProposalDlgOpen(false);
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.info.address === enterprise.info.address
        );
        prev.enterprises[index].proposals[proposalIdx].isApproved = true;
        prev.enterprises[index].proposals[proposalIdx].votesNo += 1;
        prev.tempEnterprise.proposals[proposalIdx].isApproved = true;
        prev.tempEnterprise.proposals[proposalIdx].votesNo += 1;
        return prev;
      });
      displaySuccess({ message: "Proposal rejected", timeout: 5000 });
    } catch (e) {
      displayError({ message: "There is an error to reject", timeout: 5000 });
    }
  };

  const proposalAgree = async () => {
    if (enterprise?.proposals[proposalIdx]?.isApproved) {
      return;
    }
    try {
      await enterpriseHandler.methods
        .vote(enterprise.proposals[proposalIdx].idx, true)
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
      setProposalDlgOpen(false);
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.info.address === enterprise.info.address
        );
        prev.enterprises[index].proposals[proposalIdx].isApproved = true;
        prev.enterprises[index].proposals[proposalIdx].votesYes += 1;
        prev.tempEnterprise.proposals[proposalIdx].isApproved = true;
        prev.tempEnterprise.proposals[proposalIdx].votesYes += 1;
        return prev;
      });
      displaySuccess({ message: "Proposal accepted", timeout: 5000 });
    } catch (e) {
      displayError({ message: "There is an error to agree", timeout: 5000 });
    }
  };

  const proposalExecute = async () => {
    try {
      await enterpriseHandler.methods
        .execute(enterprise.proposals[proposalIdx].idx)
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit });
      setProposalDlgOpen(false);
      updateEnterprises((prev) => {
        const index = prev.enterprises.findIndex(
          (_enterprise) => _enterprise.info.address === enterprise.info.address
        );
        prev.enterprises[index].proposals[proposalIdx].status = "EXECUTED";
        return prev;
      });
      displaySuccess({ message: "Proposal executed", timeout: 5000 });
    } catch (e) {
      displayError({ message: "There is an error to execute", timeout: 5000 });
    }
  };

  const onChangeTreasuryType = (evt) => {
    const value = evt.target.value;
    setTreasuryType(value);
  };

  // Transaction View Dialog
  const [trxDlgOpen, setTrxDlgOpen] = useState(false);
  const [trxIdx, setTrxIdx] = useState(-1);
  const trxReject = () => {
    setTrxDlgOpen(false);
  };
  const trxAgree = () => {
    setTrxDlgOpen(false);
  };

  const Chart = () => {
    navigate(`/wepID/chart`, { state: { wepID, weBack } });
  };

  const switchHandler = (category) => {
    switch (category) {
      case "admins":
        setCategory("admins");
        break;
      case "shareholders":
        setCategory("shareholders");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Header pageTitle={wepID} />
      <WEProfileAppBar
        isAdmin={isAdmin}
        logo={logo}
        backgroundUrl={backgroundUrl}
        name={name}
        description={description}
        ID={ID || ""}
        back={backHandle}
        SecondIcon={Settings}
        secondAction={setting}
      />
      {isMember ? <Box component="main" sx={{ backgroundColor: "#F2F2F2" }}>
        <Grid2 p={2} component="main">
          <Grid2 display="flex" container sx={{ fontWeight: "600", fontSize: "16px", lineHeight: "20px", mb: 2, columnGap: "30px" }}>

            <Grid2
              onClick={() => switchHandler("shareholders")}
              sx={{ cursor: "pointer" }}>
              <Typography

                color={category === "shareholders" ? "#28282B" : "#FF6142"}

                sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", textDecoration: category !== "shareholders" ? "none" : "underline", textUnderlineOffset: "5px" }}>
                Shareholder
              </Typography>
            </Grid2>
            {isAdmin && (
              <Grid2
                onClick={() => switchHandler("admins")}
                sx={{ cursor: "pointer" }}>
                <Typography
                  color={category === "shareholders" ? "#FF6142" : "#28282B"}
                  sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px", textDecoration: category === "shareholders" ? "none" : "underline", textUnderlineOffset: "5px" }}>
                  Admin
                </Typography>
              </Grid2>
            )}
          </Grid2>
          {category === "admins" ?
            <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
              <DashboardCard
                title="Treasury"
                children={<TreasuryAdmin treasuryUSD={treasuryUSD} send={send} receive={receive} />}
              />
              <DashboardCard
                title="Recent Transactions"
                children={<Section
                  key="trx"
                  title="Recent Transactions"
                  seeAll={() => trxAll("admins")}
                  sx={{ overflow: "auto", py: 2, backgroundColor: "white" }}
                >
                  {enterprise.transactions && enterprise.transactions.length ?
                    <TransactionList
                      transactions={enterprise.transactions}
                      onClickItem={trxClickHandler}
                      limit={5}
                    /> : <Typography variant="secondary" color="#6F7287">There are no transaction yet! Check back soon.</Typography>}
                </Section>}
              />

              {/* <Grid2>
              <Section
                key="trx"
                title="Invoices"
                seeAll={() => { }}
                sx={{ overflow: "auto", py: 2, px: "11px" }}
              >
                <Typography variant="secondary" color="#6F7287">There are no invoice yet.</Typography>
              </Section>
            </Grid2> */}
              <DashboardCard
                title="Business Updates"
                seeAll={() => businessUpdatesAll("admins")}
                seeFlag={enterprises.tempEnterprise.businessUpdates && enterprises.tempEnterprise.businessUpdates.length > 1 ? true : false}
                children={
                  <Section
                    key="trx"
                    sx={{ overflow: "auto", py: 2, backgroundColor: "white" }}
                  >{enterprises.tempEnterprise.businessUpdates && enterprises.tempEnterprise.businessUpdates.length ?
                    <BsUpdatesList isUserDash={true} /> :
                    <Typography variant="secondary" color="#6F7287">No new updates. You are up to date.</Typography>}
                  </Section>}
              />
              {/* <MembersAnsTreasuresSection
                enterprise={enterprise}
                allMembers={() => allMembers("admins")}
                totalShare={totalShare}
                treasuryUSD={treasuryUSD}
              /> */}
            </Grid2> : null}
          {category === "shareholders" ?
            <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
              <DashboardCard
                title="My Shares"
                children={<MyShares myShare={myShare} enterprise={enterprise} trade={trade} />}
              />
              <DashboardCard
                title="Members & Treasury"
                children={
                  <MembersAnsTreasuresSection
                    enterprise={enterprise}
                    totalShare={totalShare}
                    treasuryUSD={treasuryUSD}
                  />}
                seeFlag
                seeAll={allMembers}
              />
              <DashboardCard
                title="Business Updates"
                children={<Section
                  key="trx"
                  sx={{ overflow: "auto", py: 2, backgroundColor: "white" }}
                >
                  {enterprises.tempEnterprise.businessUpdates && enterprises.tempEnterprise.businessUpdates.length ?
                    <BsUpdatesList isUserDash={true} /> :
                    <Typography variant="secondary" color="#6F7287">No new updates. You are up to date.</Typography>}
                </Section>}
                seeAll={businessUpdatesAll}
                seeFlag
              />
              <DashboardCard
                title={"Proposals"}
                tooltip="Proposals are created by shareholders and voted on by shareholders. Approved proposals will modify the Enterprise in some way."
                seeAll={proposalsAll}
                children={<Section
                  key="proposals"
                >
                  {enterprise?.proposals && enterprise?.proposals?.length ?
                    <ProposalContent proposals={proposals} totalMembers={totalMembers} />
                    : <Typography variant="secondary" color="#6F7287">There are no proposals yet.</Typography>}
                </Section>}
                seeFlag
              />
              <DashboardCard
                title="Recent Transactions"
                seeFlag={enterprise.transactions && enterprise.transactions.length ? true : false}
                seeAll={trxAll}
                children={
                  <Section
                    key="trx"
                    sx={{ overflow: "auto", py: 2, px: "11px" }}
                  >
                    {enterprise.transactions && enterprise.transactions.length ?
                      <TransactionList
                        transactions={enterprise.transactions}
                        onClickItem={trxClickHandler}
                        limit={5}
                      /> : <Typography variant="secondary" color="#6F7287">There are no transaction yet! Check back soon.</Typography>}
                  </Section>
                }
              />
            </Grid2> : null}
        </Grid2>
      </Box> :
        <Box component="main" display="flex" flexDirection="column" sx={{ px: 2, rowGap: "16px" }}>
          <Grid2
            display="flex"
            flexDirection="column"
            sx={{ backgroundColor: "#FFDB0A", borderRadius: "8px", padding: "12px", rowGap: "12px" }}
          >
            <Typography variant="h6">Join this World Enterprise!</Typography>
            <Typography variant="secondary" sx={{ color: "#28282B", mb: "4px" }}>
              Help to shape the future of this World Enterprise.
              Get voting rights and profit from their shares.
            </Typography>
            <WEButton
              type='primary'
              rounded='xl'
              width={224}
              startIcon={
                <Box sx={{ width: "18px", height: "18px" }} component="img" src="/images/pie_chart_icon.svg" />
              }
              onClick={() => openModalJoin()}
            >
              Join & Get Shares
            </WEButton>
          </Grid2>
          <Grid2 sx={{ backgroundColor: "transparent", pb: "100px" }}>
            <Grid2 display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
              <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}>
                {"Members & Treasury"}
              </Typography>
              <Typography onClick={() => allMembers()} sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "17px", cursor: "pointer" }}>
                See All
              </Typography>
            </Grid2>
            <Grid2 display="flex" justifyContent="space-between" alignItems="center">
              <Grid2 display="flex" flexDirection="column" justifyContent="space-between" sx={{ rowGap: "12px" }}>
                <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                  <Typography variant="secondary">Members</Typography>
                  <Typography variant="primary">{enterprise?.info?.memNum}</Typography>
                </Grid2>
                <Divider />
                <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                  <Typography variant="secondary">Price Per Share</Typography>
                  <Typography variant="primary" sx={{ color: "#5CDD5C" }}>
                    {"$" + (enterprise?.info?.price ? enterprise?.info?.price.toLocaleString(undefined, { minimumFractionDigits: 2, }) : "0.00")}
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 sx={{ flexGrow: "1" }}>
                <Divider />
              </Grid2>
              <Grid2 display="flex" flexDirection="column" justifyContent="space-between" sx={{ rowGap: "12px" }}>
                <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                  <Typography variant="secondary">Total Shares</Typography>
                  <Typography variant="primary">{totalShare.toLocaleString(undefined, { minimumFractionDigits: 2, })}</Typography>
                </Grid2>
                <Divider />
                <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "8px" }}>
                  <Typography variant="secondary">Treasury</Typography>
                  <Typography variant="primary">{"$" + treasuryUSD.toLocaleString(undefined, { minimumFractionDigits: 2, })}</Typography>
                </Grid2>
              </Grid2>
            </Grid2>
          </Grid2>
        </Box>
      }
      <AdminDetailModal
        open={adminDlgOpen}
        onClose={() => {
          setAdminDlgOpen(false);
        }}
        isAdmin={
          admins.filter(
            (admin) => admin.walletAddr.toUpperCase() === account.toUpperCase()
          ).length > 0
        }
        email={(admins[adminIdx] && admins[adminIdx].email) || ""}
        phone={(admins[adminIdx] && admins[adminIdx].phone) || ""}
        walletAddr={(admins[adminIdx] && admins[adminIdx].walletAddr) || ""}
        replace={() => {
          adminReplaceHandler(adminIdx);
        }}
        remove={adminRemoveHandler(adminIdx)}
      />
      <CommentModal
        open={commentDlgOpen}
        onClose={() => setCommentDlgOpen(false)}
        id={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].id.toString()) ||
          ""
        }
        idx={proposalIdx}
        comments={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].comments) ||
          []
        }
      />
      <ProposalDetailModal
        open={proposalDlgOpen}
        handleClose={() => setProposalDlgOpen(false)}
        handleCommentOpen={() => setCommentDlgOpen(true)}
        reject={proposalReject}
        agree={proposalAgree}
        execute={proposalExecute}
        idx={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].idx) ||
          0
        }
        name={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].name) ||
          ""
        }
        walletAddr={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].walletAddr) ||
          ""
        }
        id={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].id.toString()) ||
          ""
        }
        yesNum={
          enterprise.proposals[proposalIdx] &&
          enterprise.proposals[proposalIdx].votesYes
        }
        noNum={
          enterprise.proposals[proposalIdx] &&
          enterprise.proposals[proposalIdx].votesNo
        }
        commentUrl={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].commentUrl) ||
          ""
        }
        comments={
          (enterprise.proposals[proposalIdx] &&
            enterprise.proposals[proposalIdx].comments) ||
          []
        }
        isApproved={enterprise?.proposals[proposalIdx]?.isApproved || ""}
        status={enterprise?.proposals[proposalIdx]?.status || ""}
        endTime={enterprise?.proposals[proposalIdx]?.endTime || ""}
      />
      <TransactionDetailModal
        open={trxDlgOpen}
        handleClose={() => setTrxDlgOpen(false)}
        reject={trxReject}
        agree={trxAgree}
        value={enterprise.transactions[trxIdx]}
        type={
          enterprise.transactions[trxIdx] &&
          enterprise.transactions[trxIdx].type
        }
      />

      <JoinModal
        joinWE={joinWE}
        enterprise={enterprise}
        wepID={enterprise.info.wepID}
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        name={enterprise.info.name}
        memNum={enterprise.info.memNum}
        website={enterprise.info.website}
        description={enterprise.info.description}
        enterpriseAddress={enterprise.info.address}
        shareToBuy={150}
        offerPrice={0}
        request={requestHandler}
      />
    </>
  );
};

const InfoCard = ({ LogoIcon, title, num, secNum }) => {
  return (
    <>
      <LogoIcon htmlColor="#4B4749" sx={{ width: "50px", height: "50px" }} />
      <Typography
        variant="body1"
        sx={{
          mt: 1,
          height: "18px",
          alignItems: "flex-end",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="#3D61B0"
        sx={{
          fontWeight: "600",
          fontSize: "16px",
          height: "20px",
          lineHeight: "20px",
          alignItems: "flex-end",
        }}
      >
        {num.toLocaleString(undefined)}
        {(secNum || secNum === 0) && (
          <span
            style={{
              marginLeft: "5px",
              color: "#42B03D",
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "10px",
              lineHeight: "12px",
              textAlign: "center",
            }}
          >
            {secNum.toFixed(2)}%
          </span>
        )}
      </Typography>
    </>
  );
};

const InfoButton = ({ BtnIcon, BtnText, onClick }) => {
  return (
    <Button
      onClick={() => {
        onClick();
      }}
      startIcon={<BtnIcon />}
      fullWidth
      sx={{
        backgroundColor: "#3D61B014",
        color: "#3D61B0",
        fontSize: "12px",
        py: 1,
        mt: 0,
        ":hover": { backgroundColor: "#3D61B024" },
      }}
    >
      {BtnText}
    </Button>
  );
};

const SeeAllBtn = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      endIcon={<ChevronRight htmlColor="#3D61B0" />}
      onClick={() => {
        onClick();
      }}
      sx={{
        backgroundColor: "#FFFFFFFF",
        border: "0",
        ":hover": {
          backgroundColor: "#FFFFFFFF",
          border: "0",
        },
        color: "#3D61B0",
        p: 0,
        float: "right",
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "17px",
      }}
    >
      See all
    </Button>
  );
};

const Section = ({ children, title, seeAll, addNew, tooltip = "", sx }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  }

  return (
    <>
      {tooltip !== "" && (
        <ToolTip title={tooltip} open={showTooltip}>
          <IconButton
            size="small"
            color="primary"
            onClick={handleClick}
          >
            <Help
              fontSize="small"
              htmlColor="#020433"
            />
          </IconButton>
        </ToolTip>
      )}
      {addNew && (
        <IconButton
          onClick={() => {
            addNew();
          }}
          sx={{
            backgroundColor: "#FFFFFFFF",
            border: "0",
            ":hover": {
              backgroundColor: "#FFFFFFFF",
              border: "0",
            },
            color: "#3D61B0",
            p: 0,
          }}
        >
          <Add htmlColor="#3D61B0" />
        </IconButton>
      )}
      <Container sx={{ m: 0, p: 0, background: "#fcfcfc", borderRadius: "8px", height: "content-fit", ...sx }}>{children}</Container>
    </>

  );
};

export const TransactionList = ({ transactions, onClickItem, limit }) => {
  let trxs = [...transactions];
  trxs.sort((a, b) => b.created_at - a.created_at);
  if (limit) {
    trxs = trxs.slice(0, Number(limit))
  }
  trxs = trxs.map((trx) => {
    let temp = { ...trx };
    switch (temp.type) {
      case TXTYPE.MONEY_IN:
        temp.text = `+ ${temp.type}`;
        temp.color = "#00ee00";
        break;
      case TXTYPE.MONEY_OUT:
        temp.text = `- ${temp.type}`;
        temp.color = "#ee0000";
        break;
      default:
        temp.text = temp.type;
        temp.color = "#241F21";
        break;
    }
    return temp;
  });
  const [enterprises] = store.useState(STORE_KEYS.id.enterprises);
  const enterprise = enterprises.tempEnterprise;

  const tokenNameFormart = (name) => {
    if (name.length > 25) {
      name = name.slice(0, 4) + '...' + name.slice(name.length - 4);
    }
    return name;
  }


  return (
    <>
      {trxs.map((trx, i) => {
        console.log(trx)
        return (
          <React.Fragment key={i}>
            <ListItemButton
              key={i}
              component="div"
              sx={{
                px: 0,
                pt: 1,
                pb: transactions.length - 1 === i ? 0 : 1,
                width: "100%",
                overflowX: 'auto'
              }}
              onClick={() => {
                onClickItem(transactions.length - (i + 1));

              }}
            >
              <ListItemAvatar>
                <Avatar sx={{ width: "40px", height: "40px", border: '2px solid #D9D9D9', bgcolor: '#ffffff00' }}>
                  <CreditCard htmlColor="#4B4749" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={`${trx.text}`}
                secondary={trx.type === TXTYPE.SHARE_OUT ? "" : trx.category}
                primaryTypographyProps={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: trx.color,
                  textAlign: "left",
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "15px",
                  color: "#4B4749",
                  textAlign: "left",
                }}
              />
              <ListItemText
                primary={
                  <span>
                    {
                      (() => {
                        const options = { minimumFractionDigits: 0, maximumFractionDigits: 18 };
                        if (trx.type === 'Send money') {
                          switch (trx.asset) {
                            case "":
                              return `${trx.amount.toLocaleString(undefined, options)} Matic`;
                            case "ETH":
                              return `${trx.amount.toLocaleString(undefined, options)} Matic`;
                            case "USDC":
                              return `${trx.amount.toLocaleString(undefined, options)} $`;
                            default:
                              return trx.amount.toLocaleString(undefined, options);
                          }
                        } else {
                          return <>
                            {`${trx.amount.toLocaleString(undefined)} ${trx.asset}`}
                          </>
                        }
                      })()
                    }
                  </span>
                }
                secondary={new Date(trx.created_at).toLocaleString("en-us", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })}
                primaryTypographyProps={{
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "12px",
                  lineHeight: "14.6px",
                  textAlign: "right",
                  color: "#28282B",
                }}
                secondaryTypographyProps={{
                  mt: 1,
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "10px",
                  lineHeight: "13.4px",
                  textAlign: "right",
                  color: "#6F7287",
                }}
              />
            </ListItemButton>
            {transactions.length - 1 !== i && <Divider key={`divider${i}`} />}
          </React.Fragment>
        );
      })}
    </>
  );
};

const TreasuryAdmin = ({ treasuryUSD, send, receive }) => {
  const isSmallMobile = useMediaQuery('(max-width:380px)');
  return (
    <>
      <Grid2 display="flex" flexDirection="column" sx={{ rowGap: "12px" }}>
        <Typography variant="secondary">Treasury</Typography>
        <Typography sx={{ fontWeight: "700", fontSize: "20px", lineHeight: "24px" }}>
          {"$" + treasuryUSD.toLocaleString(undefined, { minimumFractionDigits: 2, })}
        </Typography>
      </Grid2>
      <Grid2 display="flex" sx={{ columnGap: "9px" }}>
        <WEButton
          onClick={() => send()}
          type = "success"
          width ={370}
          startIcon={<FileUploadOutlined fontSize="small" sx={{ height: "15px", width: "15px" }} htmlColor="#ffffff" />}
          rounded = "xl"
          sx={{
            fontSize: {xs: '14px', sm: '16px'},
            px: {xs: 0, sm: 2}
          }}
        >
          Send Money
        </WEButton>
        <WEButton
          onClick={() => receive()}
          type = "error"
          width ={370}
          startIcon={<FileUploadOutlined fontSize="small" sx={{ height: "15px", width: "15px", transform: "rotate(180deg)" }} htmlColor="#ffffff" />}
          rounded = "xl"
          sx={{
            fontSize: {xs: '14px', sm: '16px'},
            px: {xs: 0, sm: 2}
          }}
        >
          Receive Money
        </WEButton>
      </Grid2>
    </>
  )
}

export default DashboardPage;