import { Box, CircularProgress, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AdminsList from "./AdminsList";

import { useTheme } from '@mui/material/styles';
import { upsertDraft } from "../../apis";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { useWeFactory } from "../../hook/weFactory";
import { useWeb3 } from "../../hook/web3";
import { simpleErrorMessage } from "../../utils/errorMessage";
import { ERRORS } from "../../utils/errors";
import { generateEnterpriseInfo } from "../../utils/generateEnterpriseInfo";
import { getEtherFromWei } from "../../utils/unit";
import { WELoadingButton} from "../Button";
import { useNotification } from "../Notification/hook";
import { useAutoConnect } from "../../utils/AutoConnect";

const AdminsView = () => {
  const theme = useTheme();
  const navigation = useNavigate();

  const { account } = useAutoConnect()
  const [enterprises, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const admins = enterprises.tempEnterprise.admins;
  const draftId = enterprises.tempEnterprise.draftId;
  const location = useLocation();
  const state = location.state;
  const weFactory = useWeFactory();
  const { displaySuccess, displayError } = useNotification();

  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);
  const [isCreating, setIsCreating] = React.useState(false)
  const web3 = useWeb3();

  const backHandler = () => {
    if (stepState === STEP.CREATE_ADMINS_VIEW) {
      setStep(STEP.CREATE_SHAREHOLDERS_VIEW);
    } else {
      const { wepID, weBack } = state;

      navigation(`/wepID/dashboard`, { state: { wepID, weBack } });
    }
  };

  // const getInitials = function (string) {
  //   var names = string.split(" "),
  //     initials = names[0].substring(0, 1).toUpperCase();

  //   if (names.length > 1) {
  //     initials += names[names.length - 1].substring(0, 1).toUpperCase();
  //   }
  //   return initials;
  // };

  const addHandler = () => {
    if (stepState === STEP.CREATE_ADMINS_VIEW) {
      setStep(STEP.CREATE_ADMIN_ADD);
    } else {
      setStep(STEP.ADMIN_ADD);
    }
  };

  const continueHandler = async () => {
    setIsCreating(true)
    try {
      const _enterprise = enterprises.tempEnterprise;
      const TOKEN_NAME = _enterprise.info.name;
      const TOKEN_SYMBOL = _enterprise.info.tokenName;

      let ENTERPRISE_INFO = await generateEnterpriseInfo(_enterprise);

      const admins = [];
      _enterprise.admins.map((admin) => {
        admins.push(admin.walletAddr);
      })

      const keys = Object.keys(ENTERPRISE_INFO);
      for (let i = 0; i < keys.length; i++) {
        if (
          ENTERPRISE_INFO[keys[i]] === undefined ||
          ENTERPRISE_INFO[keys[i]] === null
        ) {
          ENTERPRISE_INFO[keys[i]] = "";
        }
      }

      let SHARE_HOLDERS_ADDRESSES = [];
      let SHARE_HOLDERS_AMOUNTS = [];
      for (var i = 0; i < _enterprise.shareholders.length; i++) {
        SHARE_HOLDERS_ADDRESSES[i] = _enterprise.shareholders[i].walletAddr;
        SHARE_HOLDERS_AMOUNTS[i] = _enterprise.shareholders[i].numOfShare
      }

      console.log(
        "===create world enterprise===",
        _enterprise,
        ENTERPRISE_INFO
      );
      // displaySuccess({
      //   message: `step: ${stepState} _enterprise address: ${_enterprise.info} `,
      //   timeout: 5000,
      // });
      if (stepState === STEP.CREATE_ADMINS_VIEW) {
        setStep(STEP.CREATE_PROCESSING);
      }

      await weFactory.methods
        .createWorldEnterprise(
          admins,
          SHARE_HOLDERS_ADDRESSES,
          SHARE_HOLDERS_AMOUNTS,
          TOKEN_NAME,
          TOKEN_SYMBOL,
          ENTERPRISE_INFO
        )
        .send({ from: account, gasLimit: web3.eth.getBlock("latest").gasLimit }, (err, res) => {
          if (err) {
            console.error(err);
            setStep(STEP.CREATE_ENTERPRISE_INPUT);
            setIsCreating(false)
            return displayError({
              message: ERRORS.WE_CREATE,
              reason: simpleErrorMessage(err.message),
              timeout: 5000,
            });
          }
          if (stepState === STEP.CREATE_ADMINS_VIEW) {
            setStep(STEP.CREATE_PROCESSING);
          }
        });
      let eaddress = await weFactory.methods.worldEnterprises(enterprises.enterprises.length).call();
      displaySuccess({
        message: "Created a new world enterprise successfully",
        timeout: 5000,
      });
      // Format totalShare and myShare value
      let sh_addresses = Object.keys(_enterprise.shareholders)
      let totalShare = 0;
      let myShare = 0;
      sh_addresses.forEach((address) => { // eslint-disable-line no-loop-func
        totalShare = totalShare + getEtherFromWei(Number(_enterprise.shareholders[address].numOfShare));
        if (_enterprise.shareholders[address].walletAddr === account) {
          myShare = myShare + _enterprise.shareholders[address].numOfShare;
        }
      })
      updateEnterprises(prev => {
        prev.tempEnterprise.info.address = eaddress;
        prev.tempEnterprise.info.totalShare = totalShare;
        prev.tempEnterprise.numOfShare = myShare;
        prev.tempEnterprise.info.memNum = sh_addresses.length;
        return prev;
      })
      // End Format
      try {
        await upsertDraft(draftId, { admin_address: account, status: "FINISH", admins: JSON.stringify(admins) });
      } catch (error) {
        console.log('Draft Save ERROR')
      }

      setStep(STEP.CREATE_SUCCESSED);
      // displaySuccess({ message: "New company created", timeout: 5000 });
    } catch (e) {
      console.log(e);
      setStep(STEP.CREATE_ENTERPRISE_INPUT);
      displayError({
        message: ERRORS.WE_CREATE,
        reason: simpleErrorMessage(e.message),
        timeout: 5000,
      });
    }
    setIsCreating(false)
  };

  return (
    <>
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          mt: 0
        }}
      >
        {/* <CreateEnterpriseToolBar
          title="Admins"
          back={backHandler}
          // type={admins.length === 0 ? "none" : "add"}
          // handle={admins.length === 0 ? undefined : addHandler}
          toolTip="Admins can send money and provide business updates"
        /> */}
        <Divider flexItem />
        {admins.length === 0 ? (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              m: 0,
              px: 3,
            }}
          >
            <Box
              component="img"
              src="/images/we_admin.svg"
              alt={"splash"}
              sx={{
                right: "0",
                mt: 5,
                width: "min(28.23vh, 53.99vw)",
                height: "min(26.06vh, 49.83vw)",
              }}
            />
            {/* <Typography variant="h2" sx={{ mt: "min(6.63vh, 12.67vw)" }}>
              Add Admins
            </Typography> */}
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                maxWidth: "800px",
                mt: 5
              }}
            >
              The Admin is responsible for the day-to-day operations. Admins can
              send money from the treasury to pay expenses and send invoices to
              collect revenue. Admins can also post business updates and inform
              shareholders of important matters.
            </Typography>
            <Box
              sx={{
                marginTop: 5,
                mx: "auto",
                width: { xs: "100%", sm: "370px" },
                pb: 4,
                textAlign: 'center'
              }}
            >
              <WELoadingButton
                type='primary' 
                loading={isCreating}
                rounded='xl'
                loadingPosition="center"
                width={260}
                loadingIndicator={
                  <CircularProgress color="default" size={20}/>
                }
                onClick={addHandler}
              >
                Add Admins
              </WELoadingButton>
            </Box>
          </Container>
        ) : (
          <AdminsList />
        )}
      </Grid>
      {admins.length !== 0 && stepState === STEP.CREATE_ADMINS_VIEW && (
        <Box
          sx={{
            mt: 4,
            px: 2,
          }}
        >
          <Typography
            sx={{
              color: "#4B4749",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              mt: 3,
              textAlign: "center",
              maxWidth: "800px",
              mx: "auto",
            }}
          >
            Please confirm the transaction in next popup screen. This will
            launch your World Enterprise and your Tokens on the blockchain.
          </Typography>
          <Box
            sx={{
              marginTop: 5,
              mx: "auto",
              width: { xs: "100%", sm: "370px" },
              pb: 4,
              textAlign: 'center'
            }}
          >
            <WELoadingButton
              type='primary' 
              loading={isCreating}
              rounded='xl'
              loadingPosition="center"
              loadingIndicator={
                <CircularProgress color="default" size={20}/>
              }
              onClick={continueHandler}
            >
              Finish & Create World Enterprise
            </WELoadingButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AdminsView;
