import React from "react";
import BtnOK from "./BtnOK";

export const InfoInput = ({
  label,
  type = "default",
  value,
  textAlign = "right",
  multiline = false,
  lineNum = 1,
  placeholder,
  onChange,
  inputMode="text",
  onClick
}) => {
  return (
    <div
      style={{
        display: multiline ? "block" : "flex",
      }}
    >
      <span
        style={{
          textAlign: "left",
          width: "100%",
        }}
      >
        {label}
      </span>
      {!multiline ? (
        <input
          className="infoInput"
          value={value}
          onChange={(eve) => {
            onChange(eve);
          }}
          inputMode={inputMode}
          placeholder={placeholder}
          style={{
            textAlign: textAlign === "right" ? "right" : "left",
          }}
        />
      ) : (
        <div
          style={{display:'flex'}}
        >
            <textarea
              className="commentInput"  
              value={value}
              onChange={(eve) => {
                onChange(eve);
              }}
              inputMode={inputMode}
              placeholder={placeholder}
              rows={lineNum}
              style={{
                width: "100%",
                resize: "none",
                marginRight: "15px",
                textAlign: textAlign === "right" ? "right" : "left",
              }}
            />
            <BtnOK onClick={onClick}>Send</BtnOK>
        </div>
              
      )}
    </div>
  );
};

export default InfoInput;
