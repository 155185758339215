import { List } from "@mui/material";
import React, { useState } from "react";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import store from "../../constants/store";
import { AdminDetailModal } from "../Dialog";
import { Item } from "./Item";
import { useAutoConnect } from "../../utils/AutoConnect";

const AdminsList = () => {
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const { account } = useAutoConnect()
  const admins = enterprises.tempEnterprise.admins;

  const [stepState, setStep] = store.useState(STORE_KEYS.id.step);

  const [idx, setIdx] = useState(-1);

  const [dlgOpened, setDlgOpened] = useState(false);

  const replaceHandler = (idx) => () => {
    updateEnterprises((prev) => {
      prev.toEditAdmin = idx;
      return prev;
    });
    switch (stepState) {
      case STEP.CREATE_ADMINS_VIEW:
        setStep(STEP.CREATE_ADMIN_EDIT);
        break;

      case STEP.DASHBOARD_MEMBERS:
        setStep(STEP.DASHBOARD_MEMBERS_ADMIN_EDIT);
        break;

      default:
        setStep(STEP.ADMIN_EDIT);
        break;
    }
    setDlgOpened(false);
  };

  const removeHandler = (idx) => () => {
    switch (stepState) {
      case STEP.CREATE_ADMINS_VIEW:
        updateEnterprises((prev) => {
          prev.tempEnterprise.admins.splice(idx, 1);
          return prev;
        });
        break;

      default:
        updateEnterprises((prev) => {
          prev.tempEnterprise.admins.splice(idx, 1);
          return prev;
        });
        break;
    }
    setDlgOpened(false);
  };

  return (
    <>
      <List dense={true} sx={{ width: "100%", pt: 2, px: 0 }}>
        {admins.map((s, idx) => {
          return (
            <Item
              key={idx}
              name={s.fullName || ""}
              walletAddr={s.walletAddr}
              isActive={s.isActive}
              onClick={() => {
                setIdx(idx);
                setDlgOpened(true);
              }}
            />
          );
        })}
      </List>
      <AdminDetailModal
        open={dlgOpened}
        onClose={() => {
          setDlgOpened(false);
        }}
        isAdmin={admins.filter(admin => admin.walletAddr.toUpperCase() === account.toUpperCase()).length > 0}
        email={(admins[idx] && admins[idx].email) || ""}
        phone={(admins[idx] && admins[idx].phone) || ""}
        walletAddr={(admins[idx] && admins[idx].walletAddr) || ""}
        replace={replaceHandler(idx)}
        remove={removeHandler(idx)}
      />
    </>
  );
};

export default AdminsList;
