import React, { useState } from "react";

import {
  Box,
  Divider,
  List,
  Typography
} from "@mui/material";

import { Person } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import { upsertUserAccount } from "../apis";

import {
  HomeAppBar,
  ModalHead,
  UserProfileHead,
} from "../components/AppBar";

import Header from "../components/Header/index";

import { useNotification } from "../components/Notification/hook";
import { UserReview } from "../components/User";
import store from "../constants/store";
import { STEP, STORE_KEYS } from "../constants/store/constant";
import { ERRORS } from "../utils/errors";
import { Item } from "./WepID/Setting";
import { useAutoConnect } from "../utils/AutoConnect";

const SettingPage = () => {
  const navigate = useNavigate();
  const { account } = useAutoConnect()
  const { displaySuccess, displayError } = useNotification();
  const [enterprises, , updateEnterprises] = store.useState(
    STORE_KEYS.id.enterprises
  );
  const [step, setStep] = useState(STEP.SETTING_USER_REVIEW);

  const handleNav = (typee) => () => {
    switch (typee) {
      case "chart":
        navigate(`/chart`);
        break;
      case "index":
        navigate(`/user/${account}`);
        break;
      case "viewProfile":
        setStep(STEP.SETTING_USER_REVIEW);
        break;
      case "editProfile":
        setStep(STEP.SETTING_USER_EDIT);
        break;
      default:
        break;
    }
  };

  const profileEditHandler = (name, username, photo, jobTitle) => {
    upsertUserAccount({
      address: account,
      username: username,
      name: name,
      job_title: jobTitle,
      image_url: photo,
    })
      .then((res) => {
        if (res.data.success) {
          updateEnterprises((prev) => {
            prev.name = name;
            prev.username = username;
            prev.photo = photo;
            prev.jobTitle = jobTitle;
            return prev;
          });
          displaySuccess({
            message: "Profile updated successfully!",
            timeout: 3000,
          });
        } else {
          displayError({
            message: ERRORS.PROFILE_UPDATE,
            timeout: 3000,
          });
        }
      })
      .catch((reason) => {
        console.error(reason);
        displayError({
          message: ERRORS.UNKNOWN,
          reason: reason,
          timeout: 3000,
        });
      });
  };

  return (
    <>
      <Header pageTitle="Setting" />
      {(() => {
        switch (step) {
          case STEP.INDEX:
            return (
              <>
                <HomeAppBar position="absolute" />
                <UserProfileHead
                  photoUrl={enterprises.photo}
                  totalShareValue={50100}
                  goChart={handleNav("chart")}
                />
                <Box px={2} py={3} sx={{ backgroundColor: "#FFFFFF" }}>
                  <Typography variant="h5">User setting</Typography>
                  <Divider sx={{ mt: 1 }} />
                  <List dense={true} sx={{ width: "100%", mt: 0, p: 0 }}>
                    <Item
                      Icon={Person}
                      title="User Profile"
                      onClick={handleNav("viewProfile")}
                    />
                  </List>
                </Box>
              </>
            );
          case STEP.SETTING_USER_REVIEW:
            return (
              <>
                <HomeAppBar position="absolute" />
                <Box
                  component={"main"}
                  sx={{ bgcolor: '#E5E5E5', minHeight: "calc(100vh - 66px)" }}
                >
                  <ModalHead
                    title={"User Profile"}
                    close={handleNav("index")}
                    color='#FFDB0B'
                  />
                  <UserReview
                    title="User Profile"
                    description=""
                    username={enterprises.username}
                    name={enterprises.name}
                    jobTitle={enterprises.jobTitle}
                    photo={enterprises.photo}
                    close={handleNav("index")}
                    continueHandler={profileEditHandler}
                  />
                </Box>
              </>
            );
          default:
            break;
        }
      })()}
    </>
  );
};

export default SettingPage;
