import { CameraAlt, Person } from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { Container } from "@mui/system";
import md5 from 'md5';
import React from "react";
import { useNavigate } from "react-router-dom";
import { upsertUserAccount } from "../apis";
import { HomeAppBar } from "../components/AppBar";
import { PhotoEditorModal } from "../components/Dialog";
import Header from "../components/Header/index";
import { Input, InputLabel } from "../components/Input";
import { useNotification } from "../components/Notification/hook";
import store from "../constants/store";
import { PAGE_NAME, STORE_KEYS } from "../constants/store/constant";
import { useAutoConnect } from "../utils/AutoConnect";

const ProfileInfoPage = () => {
  const navigate = useNavigate();
  const { account } = useAutoConnect()
  const { displaySuccess, displayError } = useNotification();

  const [, , updateEnterprises] = store.useState(STORE_KEYS.id.enterprises);
  const [username, setUsername] = React.useState("");
  const [name, setName] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const [ipfsUrl, setIpfsUrl] = React.useState("");

  const [editorOpen, setEditorOpen] = React.useState(false);
  const [image, setImage] = React.useState(undefined);

  // Continue handler
  const continueeHandler = () => {
    upsertUserAccount({ address: account, username, name, image_url: ipfsUrl, job_title: jobTitle })
      .then((res) => {
        if (res.data.success) {
          const hash = md5(account.trim().toLowerCase());
          updateEnterprises((prev) => {
            prev.registeredUser = true;
            prev.name = name;
            prev.username = username;
            prev.jobTitle = jobTitle;
            prev.photo = photo || `https://www.gravatar.com/avatar/${hash}?s=${100}&d=identicon&r=PG`;
            prev.pageName = PAGE_NAME.id.join;
            return prev;
          });
          displaySuccess({ message: "Profile was updated!", timeout: 5000 });
          navigate(`/user/${account}`);
        } else {
          displayError({
            message: res.data.msg,
            timeout: 5000
          });
        }
      })
      .catch((reason) => {
        displayError({
          message: `${reason}`,
          timeout: 5000
        });
      });
  };

  // input handler
  const handleInputChange = (type) => (evt) => {
    const value = evt.currentTarget.value;
    switch (type) {
      case "username":
        setUsername(value);
        break;
      case "name":
        setName(value);
        break;
      case "job_title":
        setJobTitle(value);
        break;
      default:
        break;
    }
  };

  const handleFileChange = (event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) return;
    setEditorOpen(true);
    setImage(fileObj);
  };

  const editorClose = () => {
    setEditorOpen(false);
  };

  const editorOK = (ipfsURL, imageURL) => {
    setIpfsUrl(ipfsURL);
    setPhoto(imageURL);
  };

  return (
    <>
      <Header pageTitle="Profile Info" />
      <HomeAppBar />
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: "0px 27px",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            marginTop: "24px",
            fontSize: "23px",
            lineHeight: 1,
            fontWeight: "700",
            display: { xs: "block", md: "none" },
          }}
        >
          There is only one you. Make sure it stays that way.
        </Typography>
        <Container
          component={"div"}
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileChange}
              value={""}
            />
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={
                <Avatar
                  sx={{
                    width: "24px",
                    height: "24px",
                    backgroundColor: "#FF6142",
                  }}
                >
                  <CameraAlt sx={{ width: "16px", color: "#FFFFFF" }} />
                </Avatar>
              }
            >
              <Avatar sx={{ width: "111px", height: "111px" }}>
                {photo !== "" ? (
                  <img alt="logo" src={photo} style={{ width: "100%" }} />
                ) : (
                  <Person
                    sx={{ width: "70px", height: "70px" }}
                    htmlColor="#4B4749"
                  />
                )}
              </Avatar>
            </Badge>
          </IconButton>
        </Container>
        <Box width="100%" mt={4}>
          <InputLabel shrink htmlFor="username">
            Username
          </InputLabel>
          <Input
            id="username"
            value={username}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("username")}
          />
        </Box>
        <Box width="100%" mt={4}>
          <InputLabel shrink htmlFor="name">
            Name (Optional – Keep blank, if you prefer to stay anonymous to other users)
          </InputLabel>
          <Input
            id="name"
            value={name}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("name")}
          />
        </Box>
        <Box width="100%" mt={4}>
          <InputLabel shrink htmlFor="job_title">
            Job Title
          </InputLabel>
          <Input
            id="job_title"
            value={jobTitle}
            fullWidth
            sx={{ mt: 1 }}
            onChange={handleInputChange("job_title")}
          />
        </Box>
        <Button
          sx={{
            mt: 12,
            mb: 2,
            mx: "auto",
          }}
          onClick={continueeHandler}
        >
          Continue
        </Button>
      </Grid>
      <PhotoEditorModal
        open={editorOpen}
        onClose={editorClose}
        image={image}
        onOK={editorOK}
      />
    </>
  );
};

export default ProfileInfoPage;
