import { uploadFile } from "./couldfront";

export const generateEnterpriseInfo = async (_enterprise) => {
    let COMPANY_TYPE = 4;
    switch (_enterprise.info.type) {
        case "LLC":
            COMPANY_TYPE = 0;
            break;
        case "c-corp":
            COMPANY_TYPE = 1;
            break;
        case "s-corp":
            COMPANY_TYPE = 2;
            break;
        case "non-profit":
            COMPANY_TYPE = 3;
            break;
        case "other":
            COMPANY_TYPE = 4;
            break;
        default:
            COMPANY_TYPE = 4;
    }

    const _enterpriseWithoutAdmin = { ..._enterprise };
    delete _enterpriseWithoutAdmin.admins;
    const _enterpriseStr = JSON.stringify(_enterprise);

    const ipfsUrl = await uploadFile(_enterpriseStr);
    let ENTERPRISE_INFO = {
        logoImg: _enterprise.info.logo,
        enterpriseName: _enterprise.info.name,
        description: _enterprise.info.description,
        isRG: _enterprise.info.isRegistered,
        companyType: COMPANY_TYPE,
        admin: String(_enterprise.admins[0].walletAddr).toLowerCase(),
        ipfs: ipfsUrl,
    };

    return ENTERPRISE_INFO
}