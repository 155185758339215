import { Button, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CommentList } from ".";
import store from "../../constants/store";
import { STEP, STORE_KEYS } from "../../constants/store/constant";
import { AppBar } from "../AppBar";

const CommentView = () => {
  const navigation = useNavigate();
  const [, setStep] = store.useState(STORE_KEYS.id.step);
  const { state } = useLocation();
  const { wepID, weBack } = state;
  const [enterprises, ,] = store.useState(STORE_KEYS.id.enterprises);
  const proposals = enterprises.tempEnterprise.proposals;
  const { id } = useParams();
  const [comments, setComments ] = useState([])
  const isShareholder = enterprises.tempEnterprise.isShareholder;  
  const backHandler = () => {
    navigation(weBack,  { state: { wepID, weBack, proposalId: id} });
  };

  const addHandler = () => {
    setStep(STEP.DASHBOARD_COMMENT_ADD);
  };

  useEffect(() => {
    if (!proposals || !id ) return;
    
    const index = proposals.findIndex(
      (proposal) =>
        proposal.id === id
    );
    if ( proposals[index].comments ) {
      setComments(proposals[index].comments)
    }
    
  }, [proposals, id])

  return (
    <>
      <AppBar
        position="absolute"
        title="Comments"
        back={backHandler}
        type={ isShareholder ? "add" : "none"}
        handle={ isShareholder ? addHandler : undefined}
        toolTip="Comments"
      />
      <Grid
        container
        component="main"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          p: 0,
          pt: comments?.length === 0 ? 0 : 9, // remain for further update
        }}
      >
        { ( comments.length === 0 && isShareholder ) ? (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              m: 0,
              px: 3,
            }}
          >
            <Container
              sx={{
                position: "absolute",
                bottom: "49vh",
                px: 3,
              }}
            >
              <Button fullWidth onClick={addHandler}>
                Add a new comment
              </Button>
            </Container>
          </Container>
        ) : (
          <Container sx={{ mt: 2, px: 3 }}>
            <CommentList comments={comments} />
          </Container>
        )}
      </Grid>
    </>
  );
};

export default CommentView;
